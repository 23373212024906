import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	Link,
	ListItemIcon,
	MenuItem,
	Select,
	Snackbar,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	ICodeName,
	ISaleOrderDetail,
	IServices,
	PassengerTypeEnum,
	serviceTypes,
	TypeServiceEnum,
} from 'features/salesOrder/types';
import { Layout } from 'features/common/components/Layout';
import { ListMenu } from './components/ListMenu';
import { useParams } from 'react-router-dom';
import { getCurrencies, getSalesOrderId, patchEnableService } from 'features/salesOrder/services';
import { General } from './components/General';
import { Paxes } from './components/Paxes';
import { nanoidGenerator } from 'features/common/helpers';
// import { Customer } from './components/Customer';
import { ServiceForm } from './components/ServiceForm';
import { IValidatingCarrier } from 'features/transactions/types';
import { getValidatingCarriers } from 'features/transactions/services';
import { ShowAlertState } from 'features/common/types';
import CopyableId from './components/CopyableId';
import DealOrCaseOpenDetail from '../../../features/common/components/DealOrCaseOpenDetail';
import { createNewPassengerGroup } from '../../../features/salesOrder/components/SalesOrderServiceForm/helpers';

const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };

export const SalesOrderScreen = (): JSX.Element => {
	const { id: saleOrderId } = useParams<{ id: string }>();
	const [loading, setLoading] = useState<boolean>(false);
	const [salesOrderData, setSalesOrderData] = useState<ISaleOrderDetail>();
	const [selectedItemIndex, setSelectedItemIndex] = useState<number | string>(0);
	const [validatingCarriers, setValidatingCarriers] = useState<IValidatingCarrier[]>([]);
	const [currencyesList, setCurrencyesList] = useState<ICodeName[]>([]);
	const [serviceState, setServiceState] = useState<IServices[] | undefined>();
	const [alertMsg, setAlertMsg] = useState<ShowAlertState>(defaultAlertState);

	const loadValidatinCarries = useCallback(async () => {
		try {
			setLoading(true);
			setValidatingCarriers((await getValidatingCarriers()).data);
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
			console.log(error);
		} finally {
			setLoading(false);
		}
	}, []);

	const loadCurrencies = useCallback(async () => {
		try {
			setLoading(true);
			const currencyes = (await getCurrencies()).data;
			setCurrencyesList(currencyes);
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			setLoading(false);
		}
	}, []);

	const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number | string) => {
		setSelectedItemIndex(index);
	};

	const fetchOrder = useCallback(async () => {
		try {
			setLoading(true);
			const data = (await getSalesOrderId(saleOrderId)).data;
			setSalesOrderData({ ...data, services: data.services });
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			setLoading(false);
		}
	}, []);

	const handleAddNewService = () => {
		const newService = {
			keyId: nanoidGenerator(),
			reference: '',
			tariffProviderId: '',
			unit: [],
			cost: '',
			saleAmount: '',
			currency: '',
			markupPercentage: '',
			passengerIds: [],
			serviceProviderId: '',
			serviceType: TypeServiceEnum.Flight,
			passengersGroupByPassengerType: [createNewPassengerGroup(PassengerTypeEnum.A)],
		};
		// @ts-ignore
		setServiceState((prevState) => [...prevState, newService]);
	};

	const handleChangeServiceType = useCallback(
		(newValue: TypeServiceEnum, index: number) => {
			setServiceState((prevState) => {
				const newState = prevState ? [...prevState] : [];
				if (newState[index]) {
					newState[index].serviceType = newValue;
				}
				return newState;
			});
		},
		[setServiceState],
	);

	useEffect(() => {
		salesOrderData?.services && setServiceState(salesOrderData?.services);
	}, [salesOrderData?.services, setServiceState]);

	useEffect(() => {
		loadValidatinCarries();
		loadCurrencies();
		fetchOrder();
	}, [saleOrderId, fetchOrder, setLoading, loadValidatinCarries, loadCurrencies]);

	const serviceIndex = useMemo(
		() => serviceState?.findIndex((service) => service.keyId === selectedItemIndex),
		[serviceState, selectedItemIndex],
	);

	const isExistingService = useMemo(() => {
		if (serviceState && serviceIndex !== undefined && serviceState[serviceIndex]) {
			const result = Boolean(
				salesOrderData?.services?.find((service) => service.keyId === serviceState[serviceIndex].keyId),
			);
			return result;
		}
		return false;
	}, [serviceIndex, serviceState, salesOrderData]);

	const isEnabled = useMemo(
		// @ts-ignore
		() =>
			serviceIndex !== undefined &&
			salesOrderData !== undefined &&
			salesOrderData.services[serviceIndex] &&
			salesOrderData.services[serviceIndex].enable,
		[serviceIndex, salesOrderData],
	);

	const handleRemoveService = useCallback(async () => {
		if (serviceIndex !== undefined) {
			if (isExistingService) {
				try {
					const service = { ...salesOrderData?.services[serviceIndex] };

					if (service.keyId) {
						await patchEnableService({
							saleOrderId,
							serviceId: service.keyId,
							enable: !service.enable,
						});
					}

					fetchOrder();
				} catch (e) {
					// @ts-ignore
					alert(e.response.data.message);
				}
			} else {
				setServiceState((prevState) => {
					const newState = prevState && [...prevState];
					newState?.splice(serviceIndex, 1);
					return newState;
				});
			}
		}
	}, [isExistingService, serviceIndex, salesOrderData]);

	const returnSelectedServiceType = (): TypeServiceEnum | null => {
		if (serviceState && serviceIndex !== undefined && serviceState[serviceIndex]) {
			return serviceState[serviceIndex].serviceType as TypeServiceEnum;
		}
		return null;
	};

	const invoiceablePassengers = salesOrderData?.passengers.filter((passenger) => passenger.invoiceable) || [];
	const filteredPassengers = invoiceablePassengers.map((passenger) => ({
		name: passenger.name,
		lastName: passenger.lastName,
		isPhysicalPerson: passenger.isPhysicalPerson,
	}));

	return (
		<>
			{loading && (
				<Box
					sx={{
						background: 'rgba(0,0,0,0.5)',
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100vw',
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 999999999,
					}}
				>
					<CircularProgress color="primary" />
				</Box>
			)}
			<Layout>
				<>
					<Grid padding={2} container>
						<Grid item xs={12} sm={3}>
							<ListMenu
								onAddNewService={handleAddNewService}
								services={serviceState || []}
								onClick={handleListItemClick}
								selectedItemIndex={selectedItemIndex}
							/>
						</Grid>

						<Grid item xs={12} sm={9}>
							<Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
								<Grid item xs={6}>
									{filteredPassengers.length > 0 ? (
										filteredPassengers.map((passenger, index) => (
											<div key={index}>
												<Typography variant="h6">
													Factura a:{' '}
													<span>
														{passenger.name} {passenger.lastName}
													</span>{' '}
													| <span>{passenger.isPhysicalPerson ? 'Persona' : 'Empresa'}</span>
												</Typography>
											</div>
										))
									) : (
										<Typography variant="h6" color="error">
											No hay pasajero para facturar
										</Typography>
									)}
								</Grid>
								<Divider orientation="vertical" flexItem sx={{ my: 2, mx: 1, mb: 1 }} />
								<Grid item xs={2}>
									<Typography variant="h6">
										{salesOrderData?.isBitrixDeal ? 'Deal' : 'Caso'}{' '}
										{salesOrderData && (
											<DealOrCaseOpenDetail dealId={salesOrderData.dealId} isBitrixDeal={salesOrderData.isBitrixDeal} />
										)}
									</Typography>
								</Grid>
								<Divider orientation="vertical" flexItem sx={{ my: 2, mx: 1, mb: 1 }} />
								<Grid item xs={3}>
									<Typography variant="h6">
										Cotizacion:{' '}
										<span>
											<Link
												href={`https://power.mevuelo.com/quotations/${salesOrderData?.quotationId}/`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{salesOrderData?.quotationId}
											</Link>
										</span>
									</Typography>
								</Grid>
							</Grid>
							<Divider orientation="horizontal" flexItem sx={{ my: 2, mx: 1, mb: 1 }} />

							{selectedItemIndex === 0 && salesOrderData && (
								<General onUpdate={fetchOrder} onSetAlert={setAlertMsg} data={salesOrderData} setLoading={setLoading} />
							)}
							{selectedItemIndex === 1 && salesOrderData && (
								<Paxes
									passengers={salesOrderData?.passengers || []}
									onUpdatePassengers={fetchOrder}
									onSetAlert={setAlertMsg}
								/>
							)}
							{/* {selectedItemIndex === 2 && salesOrderData && (
								<Customer
									segmentType={salesOrderData.segmentType}
									customer={salesOrderData.customer}
									id={saleOrderId}
									customerToShow={salesOrderData.passengers.find((pax) => pax.mainPassenger === true)}
								/>
							)} */}

							{typeof selectedItemIndex === 'string' && serviceState && serviceIndex !== undefined && (
								<>
									<Box width="100%" pl={2} pr={2} display="flex" justifyContent="space-between">
										<FormControl variant="outlined" size="small">
											<Select
												required
												size="small"
												value={returnSelectedServiceType()}
												onChange={(e) => handleChangeServiceType(e.target.value as TypeServiceEnum, serviceIndex)}
											>
												{serviceTypes.map((serviceType) => (
													<MenuItem key={serviceType.id} value={serviceType.id}>
														<ListItemIcon sx={{ mt: 1 }}>
															<Box display="flex" gap={1}>
																{serviceType.icon}
																<Typography color={'black'}>{serviceType.name}</Typography>
															</Box>
														</ListItemIcon>
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<CopyableId copiableIdValue={selectedItemIndex} />

										<Box pr={4}>
											<Button
												onClick={handleRemoveService}
												size="large"
												variant="contained"
												color={isExistingService ? 'secondary' : 'error'}
											>
												<DeleteIcon />
												{isExistingService ? (isEnabled ? 'Desactivar' : 'Activar') : 'Eliminar'} servicio
											</Button>
										</Box>
									</Box>

									<ServiceForm
										onSetAlert={setAlertMsg}
										isExistingService={isExistingService}
										onUpdateService={fetchOrder}
										currencyesList={currencyesList}
										serviceIndex={serviceState?.findIndex((service) => service.keyId === selectedItemIndex)}
										validatingCarriers={validatingCarriers}
										passengers={salesOrderData?.passengers || []}
										service={serviceState?.find((service) => service.keyId === selectedItemIndex)}
										isEnabled
									/>
								</>
							)}
						</Grid>
					</Grid>
					<Snackbar open={alertMsg.show} onClose={() => setAlertMsg(defaultAlertState)}>
						<Alert variant="filled" severity={alertMsg.severity}>
							{alertMsg.message}
						</Alert>
					</Snackbar>
				</>
			</Layout>
		</>
	);
};
