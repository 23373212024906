import React, { useState, useEffect } from 'react';
import { Button, Card, Container, CssBaseline, Grid, TextField, Typography, Divider, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { DestinationSuggestion } from 'features/quotation/types';
import HelpTooltip from '../HelpContent/HelpTooltip';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface Destination {
	checkoutDate?: string;
	cityName: string;
	nights: number;
	checking: string;
}

interface DestinationToAdd {
	cityCode: string;
	nights: number;
	checking: string;
}

type SearchOption = DestinationSuggestion;

interface Props {
	destination: Destination[];
	destinationToAdd: DestinationToAdd;
	searchOptions: SearchOption[];
	onAutocompleteChange: (event: React.ChangeEvent<unknown>, value: SearchOption | null) => void;
	onChangeCity: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeNights: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCheckingChange: (date: Date | null) => void;
	onAddNew: () => void;
}

export default function DestinationConfig(props: Props): JSX.Element {
	const classes = useStyles();
	const [keySelect, setKeySelect] = useState('key-select-destination');
	const [checkOut, setCheckOut] = useState<Date | null>(null);

	// Convierte la fecha de checking a objeto Date
	const getDate = (): Date => {
		const momentObj = moment(props.destinationToAdd.checking, 'YYYY-MM-DD');
		return momentObj.toDate();
	};

	// Valida si se pueden agregar destinos
	const isValid = (): boolean => {
		return (
			props.destinationToAdd.cityCode !== '' &&
			props.destinationToAdd.nights > 0 &&
			props.destinationToAdd.checking !== ''
		);
	};

	const handleCheckInChange = (date: Date | null): void => {
		if (date) {
			const nights = props.destinationToAdd.nights || 0;
			const checkinDate = moment(date);
			const newCheckOut = checkinDate.add(nights, 'days').toDate();
			props.onCheckingChange(date);
			setCheckOut(newCheckOut);
		}
	};

	const handleCheckOutChange = (date: Date | null): void => {
		if (date) {
			const checkoutDate = moment.utc(date);
			setCheckOut(checkoutDate.toDate());
			if (props.destinationToAdd.checking) {
				const checkinDate = moment.utc(props.destinationToAdd.checking, 'YYYY-MM-DD');
				const nights = checkoutDate.diff(checkinDate, 'days');
				props.onChangeNights({
					target: { value: nights },
				} as unknown as React.ChangeEvent<HTMLInputElement>);
			}
		}
	};

	const handleNightsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const nights = parseInt(e.target.value, 10) || 0;
		props.onChangeNights(e);

		if (props.destinationToAdd.checking) {
			const checkinDate = moment(props.destinationToAdd.checking, 'YYYY-MM-DD');
			const newCheckOut = checkinDate.add(nights, 'days').toDate();
			setCheckOut(newCheckOut);
		}
	};

	const calculateInitialCheckOut = (): Date | null => {
		if (props.destinationToAdd.checking && props.destinationToAdd.nights > 0) {
			const checkinDate = moment(props.destinationToAdd.checking, 'YYYY-MM-DD');
			return checkinDate.add(props.destinationToAdd.nights, 'days').toDate();
		}
		return null;
	};

	const handlerOnAddNewDestination = (): void => {
		props.onAddNew();
		// Cambiamos el key para resetear el Autocomplete
		setKeySelect(keySelect + '-key');
	};

	useEffect(() => {
		const initialCheckOut = calculateInitialCheckOut();
		setCheckOut(initialCheckOut);
	}, [props.destinationToAdd.checking, props.destinationToAdd.nights]);

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />

			<Typography
				marginTop={2}
				component="h1"
				variant="h6"
				gutterBottom
				sx={{ backgroundColor: 'primary.light', px: 2, py: 1, borderRadius: 2, color: 'white' }}
			>
				Selección de destino:
				<HelpTooltip
					helpKey="destinationConfig"
					placement="bottom-end"
					iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
				/>
			</Typography>

			<Card sx={{ borderRadius: 2, padding: 2, marginTop: 1 }}>
				{props.destination.length > 0 && (
					<>
						<Typography variant="subtitle1" fontWeight="500" gutterBottom>
							Destinos de la coti:
						</Typography>

						{props.destination.map((item, index) => {
							const checkinDate = moment(item.checking, 'YYYY-MM-DD');
							const checkoutDate = checkinDate.clone().add(item.nights, 'days');

							return (
								<Box key={index} mb={2} mt={1}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={4}>
											<TextField
												name="city"
												variant="standard"
												label="Destino"
												size="small"
												fullWidth
												style={{ marginTop: -3 }}
												value={item.cityName}
												disabled
											/>
										</Grid>
										<Grid item xs={12} sm={3}>
											<TextField
												name="checking"
												variant="standard"
												label="CheckIn"
												value={checkinDate.format('DD/MM/YYYY')}
												size="small"
												fullWidth
												disabled
											/>
										</Grid>
										<Grid item xs={12} sm={3}>
											<TextField
												name="checkout"
												variant="standard"
												label="CheckOut"
												value={checkoutDate.format('DD/MM/YYYY')}
												size="small"
												fullWidth
												disabled
											/>
										</Grid>
										<Grid item xs={12} sm={2}>
											<TextField
												name="nights"
												variant="standard"
												label="Cantidad de noches"
												type="number"
												value={String(item.nights)}
												size="small"
												fullWidth
												disabled
											/>
										</Grid>
									</Grid>
								</Box>
							);
						})}

						{/* Divider para separar destinos existentes de la sección "Agregar nuevo" */}
						<Divider sx={{ marginY: 2 }} />
					</>
				)}
				{props.destination.length === 0 ? (
					<Typography variant="subtitle1" fontWeight="500" gutterBottom>
						Aún no hay destinos, ¡agrega uno para comenzar!
					</Typography>
				) : (
					<Typography variant="subtitle1" fontWeight="500" gutterBottom>
						Agregar nuevo destino a la coti:
					</Typography>
				)}
				<div className={classes.paper}>
					<form className={classes.form} noValidate>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4}>
									<Autocomplete
										id="debug"
										options={props.searchOptions}
										key={keySelect}
										getOptionLabel={(option) => option.name}
										onChange={props.onAutocompleteChange}
										renderInput={(params) => (
											<TextField
												{...params}
												name="city"
												variant="standard"
												required
												label="Destino"
												size="small"
												onChange={props.onChangeCity}
												fullWidth
												style={{ marginTop: -3 }}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<DatePicker
										inputFormat="dd/MM/yyyy"
										renderInput={(params) => (
											<TextField
												required
												{...params}
												variant="standard"
												id="checking"
												helperText={params?.inputProps?.placeholder}
											/>
										)}
										label="CheckIn"
										value={getDate() ?? ''}
										onChange={handleCheckInChange}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<DatePicker
										inputFormat="dd/MM/yyyy"
										minDate={moment(props.destinationToAdd.checking, 'YYYY-MM-DD').toDate()}
										renderInput={(params) => (
											<TextField
												required
												{...params}
												variant="standard"
												id="checkout"
												helperText={params?.inputProps?.placeholder}
											/>
										)}
										label="CheckOut"
										value={checkOut ?? ''}
										onChange={handleCheckOutChange}
									/>
								</Grid>
								<Grid item xs={12} sm={2}>
									<TextField
										name="nights"
										variant="standard"
										required
										label="Cantidad de noches"
										type="number"
										size="small"
										value={props.destinationToAdd.nights ?? ''}
										onChange={handleNightsChange}
										fullWidth
									/>
								</Grid>
							</Grid>

							<Grid container justifyContent="flex-end" mt={2}>
								<Button disabled={!isValid()} variant="contained" color="primary" onClick={handlerOnAddNewDestination}>
									Agregar destino
								</Button>
							</Grid>
						</LocalizationProvider>
					</form>
				</div>
			</Card>
		</Container>
	);
}
