import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
	useTheme,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import { checkShowErrorMessage } from '../../../common/helpers';
import {
	IAddReferenceToWireTransferInInfimia,
	IInfimiaWireTransferTransactionItem,
	WireTransferCommentMaxLength,
} from '../../types';
import makeStyles from '@mui/styles/makeStyles';
import { extractErrorMessage, isOnlyDigit } from '../../../quotation/helpers';
import CloseIcon from '@mui/icons-material/Close';
import { findContactDataByDeal } from '../../../../services';
import { addReferenceToWireTransferInInfimia } from '../../services';
import { setWireTransferCheckResult } from '../../slice';
import { useDispatch } from 'react-redux';
import BitrixDealOrCaseSwitch from '../../../common/components/BitrixDealOrCaseSwitch';
import BitrixDealOrCaseUserInfoConfirm from '../../../common/components/BitrixDealOrCaseUserInfoConfirm';
import { getNimbuxCaseSummary } from '../../../common/services';

export interface AddCommentToWireTransferModalProps {
	open: boolean;
	item: IInfimiaWireTransferTransactionItem;
	handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const AddReferenceToWireTransferModal = ({
	item,
	open,
	handleClose,
}: AddCommentToWireTransferModalProps): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState<boolean>(false);
	const {
		control,
		handleSubmit,
		trigger,
		formState: { errors, isValid },
		getValues,
		setValue,
	} = useForm({ mode: 'all', defaultValues: { id: item.id, comment: '' } });
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [crmTicket, setCrmTicket] = React.useState<number>(0);
	const [name, setName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [phone, setPhone] = React.useState<string>('');
	const [isRequestedDataCrmTicket, setIsRequestedDataCrmTicket] = React.useState<boolean>(false);
	const [crmTicketWithoutContact, setCrmTicketWithoutContact] = React.useState<boolean>(false);
	const [isConfirmedCrmTicket, setIsConfirmedCrmTicket] = React.useState<boolean>(false);
	const [isBitrixDeal, setIsBitrixDeal] = useState<boolean>(true);
	const startCommentPhrase = isBitrixDeal ? 'BITRIX_DEAL: ' : 'CASO: ';
	const handleConfirmAutoCompleteContactDataByDeal = (e: React.KeyboardEvent<HTMLInputElement>) => {
		setIsRequestedDataCrmTicket(false);
		setIsConfirmedCrmTicket(false);
		setCrmTicketWithoutContact(false);
		setValue('comment', '');
		clearValuesContact();
		if (e.key === 'Enter' && crmTicket > 0) {
			handleAutoCompleteNameEmailAndPhone();
		}
	};
	const clearValuesContact = () => {
		name && setName('');
		email && setEmail('');
		phone && setPhone('');
	};

	const handleAutoCompleteNameEmailAndPhone = async () => {
		try {
			setLoading(true);
			setIsRequestedDataCrmTicket(false);
			setIsConfirmedCrmTicket(false);
			setCrmTicketWithoutContact(false);
			setValue('comment', '');
			clearValuesContact();
			if (isBitrixDeal) {
				const response = await findContactDataByDeal(crmTicket);
				setName(response.data.name);
				setEmail(response.data.email || '');
				setPhone(response.data.phone || '');
			} else {
				const response = await getNimbuxCaseSummary(crmTicket);
				if (!response.data?.client) {
					setCrmTicketWithoutContact(true);
				} else {
					const client = response.data.client;
					setName(`${client.firstName ?? ''} ${client.lastName ?? ''}`.trim());
					setEmail(client.email || '');
					setPhone(client.completePhoneNumber || '');
				}
			}

			setLoading(false);
			setIsRequestedDataCrmTicket(true);
		} catch (error) {
			setLoading(false);
			if (error?.response?.status !== 404) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'No se pudo obtener los datos de contacto'),
				});
			} else {
				setIsRequestedDataCrmTicket(true);
				setCrmTicketWithoutContact(true);
			}
		}
	};

	const handleCrmTicketChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isOnlyDigit(e.target.value) && Number(e.target.value) > 0) setCrmTicket(Number(e.target.value));
	};

	const handlerConfirmDealData = () => {
		setValue('comment', `${startCommentPhrase}${crmTicket} | `);
		setIsConfirmedCrmTicket(true);
	};

	const regularExpressionComment = new RegExp('^' + startCommentPhrase + '(\\d+)( \\|+ )+(.*)$');

	useEffect(() => {
		if (isRequestedDataCrmTicket) {
			clearValuesContact();
			setCrmTicketWithoutContact(false);
			setIsRequestedDataCrmTicket(false);
			setIsConfirmedCrmTicket(false);
			setCrmTicket(0);
			setValue('comment', '');
			trigger('comment');
		}
	}, [isBitrixDeal]);

	const onSubmit = async () => {
		try {
			setLoading(true);
			await addReferenceToWireTransferInInfimia(
				Object.assign(
					{
						id: item.id,
						dealId: crmTicket,
						isBitrixDeal: isBitrixDeal,
						country: item.country,
						bank: item.bank,
						account: item.account,
						bankReference: item.bankReference,
						bankDate: item.bankDate,
						currency: item.currency,
						amount: Number(item.credit),
					},
					getValues(),
				) as IAddReferenceToWireTransferInInfimia,
			);

			setLoading(false);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Se ha adicionado el comentario a la transferencia satisfactoriamente',
			});
			setTimeout(() => {
				handleClose();
				dispatch(setWireTransferCheckResult(null));
			}, 1500);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al adicionar el comentario a la transferencia'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="body1" component={'span'} color="primary">
						Adicionar comentario
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<Grid container gap={2}>
						<Grid item xs={12}>
							<form>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={8}>
										<BitrixDealOrCaseSwitch
											isBitrixDeal={isBitrixDeal}
											onChange={() => setIsBitrixDeal(!isBitrixDeal)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											variant="outlined"
											required
											fullWidth
											type="number"
											id="crmTicket"
											label={isBitrixDeal ? 'Deal ID' : 'Caso'}
											name="crmTicket"
											autoComplete="crmTicket"
											value={crmTicket}
											autoFocus
											onKeyDown={handleConfirmAutoCompleteContactDataByDeal}
											onChange={handleCrmTicketChange}
										/>
										<BitrixDealOrCaseUserInfoConfirm isBitrixDeal={isBitrixDeal} />
										{crmTicketWithoutContact && (
											<Typography
												color={isBitrixDeal ? theme.palette.warning.main : theme.palette.error.main}
												component="span"
												variant="subtitle2"
											>
												{isBitrixDeal
													? 'El deal no tiene contacto asociado'
													: 'El caso no tiene cliente asociado, por favor asocie un cliente al caso'}
											</Typography>
										)}
									</Grid>
									<Grid item xs={12} sm={12}>
										<TextField
											autoComplete="name"
											name="adults"
											variant="outlined"
											fullWidth
											disabled={true}
											id="name"
											value={name}
											label="Nombre"
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											variant="outlined"
											fullWidth
											disabled={true}
											id="email"
											label="Email"
											value={email}
											name="email"
											autoComplete="email"
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											disabled={true}
											fullWidth
											name="phone"
											label="Telefono"
											value={phone}
											id="phone"
											autoComplete="phone"
										/>
									</Grid>
								</Grid>

								<Grid item xs={3} display={'flex'} gap={'10px'} justifyContent="flex-end" paddingTop={1}>
									<Button
										variant="contained"
										disabled={loading || !isRequestedDataCrmTicket || (crmTicketWithoutContact && !isBitrixDeal)}
										color="success"
										fullWidth
										onClick={handlerConfirmDealData}
									>
										Confirmar
									</Button>
								</Grid>
							</form>
						</Grid>
						<Grid item xs={12}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="comment"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										pattern: {
											value: regularExpressionComment,
											message: `El mensaje debe tener el formato ${startCommentPhrase}${
												isBitrixDeal ? 'dealId' : 'caso'
											} | tu comentario`,
										},
										maxLength: {
											value: WireTransferCommentMaxLength.MESSAGE,
											message:
												'El tamaño del mensaje excede la cantidad máxima de ' +
												WireTransferCommentMaxLength.MESSAGE +
												' caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											multiline
											focused
											rows={5}
											label="Comentario"
											helperText={checkShowErrorMessage(Boolean(errors.comment), errors.comment?.message)}
											error={Boolean(errors.comment)}
											{...field}
										/>
									)}
								/>
							</form>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onSubmit} disabled={!isValid || !isConfirmedCrmTicket || loading} type="submit">
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={2500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
