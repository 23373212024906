import React, { FC } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';

interface CopyTextFieldProps {
	text: string;
	title?: string;
}

const CopyTextField: FC<CopyTextFieldProps> = ({ text, title }) => {
	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(text);
			console.log('Texto copiado con éxito');
		} catch (err) {
			console.error('Error copiando el texto: ', err);
		}
	};

	return (
		<Box display="flex" alignItems="center" gap={2}>
			<TextField
				fullWidth
				sx={{ m: 1 }}
				value={text}
				label={title}
				variant="outlined"
				InputProps={{
					readOnly: true,
				}}
				multiline
			/>
			<IconButton onClick={handleCopy}>
				<ContentCopyIcon />
			</IconButton>
		</Box>
	);
};

CopyTextField.propTypes = {
	text: PropTypes.string.isRequired,
	title: PropTypes.string,
};

export default CopyTextField;
