import React from 'react';
import { Container, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OptionalService } from '../../types';
import { GetPAXTotalsResponse, getCityNameCleaned } from '../../helpers';
import { OptionalServiceCardDesktop } from '../OptionalServiceCardDesktop';
import { useDispatch } from 'react-redux';
import { changeShowOptionalServiceInDestination } from '../../slice';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { OptionalServiceCardMobile } from '../OptionalServiceCardMobile';
import HelpTooltip from '../HelpContent/HelpTooltip';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},

	table: {
		minWidth: 650,
	},
	optionalServiceCardContainer: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	destinationContainer: {
		marginTop: theme.spacing(2),
	},
	utility: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
	},
	showOptionals: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
	},
}));

interface DestinationOptionalService {
	optionalServices: OptionalService[];
	cityName: string;
}

interface Props {
	destinations: DestinationOptionalService[];
	paxTotals: GetPAXTotalsResponse;
	isPreview: boolean;
}

export const DestinationOptionalServiceList = (props: Props): JSX.Element => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { destinations, paxTotals, isPreview } = props;
	const hasDestinationWithService = !isPreview
		? destinations.some((x) => x.optionalServices.length > 0)
		: destinations.some((x) => x.optionalServices.some((x) => x.show));
	const handleChangeOptionalService = (destinationIndex: number, optionalServiceIndex: number) => {
		dispatch(changeShowOptionalServiceInDestination({ destinationIndex, optionalServiceIndex }));
	};
	const canEvaluateShowDestination = (destination: DestinationOptionalService) =>
		!isPreview ? destination.optionalServices.length > 0 : destination.optionalServices.some((x) => x.show);
	const isMediumDown = useBreakpoints().mediumDown;
	return (
		<>
			{hasDestinationWithService && (
				<Container component="main" maxWidth="md">
					<div className={classes.paper}>
						<Grid container spacing={2}>
							<Grid item xs={!isPreview ? 10 : 12} sm={!isPreview ? 10 : 12}>
								{isMediumDown && (
									<Typography
										component="h1"
										variant="h6"
										sx={{
											marginBottom: '8px',
											width: '100%',
											backgroundColor: '#31194B',
											paddingLeft: '16px',
											paddingRight: '16px',
											paddingTop: '8px',
											paddingBottom: '8px',
											borderRadius: '4px',
											fontSize: '1rem',
											color: 'white',
										}}
									>
										Servicios y actividades opcionales:
										{!isPreview && (
											<HelpTooltip
												helpKey="optionalServices"
												placement="bottom-end"
												iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
											/>
										)}
									</Typography>
								)}
								{!isMediumDown && (
									<Typography
										component="h1"
										variant="h6"
										sx={{
											marginBottom: '8px',
											width: '100%',
											backgroundColor: '#31194B',
											paddingLeft: '16px',
											paddingRight: '16px',
											paddingTop: '8px',
											paddingBottom: '8px',
											borderRadius: '4px',
											fontSize: '1rem',
											color: 'white',
										}}
									>
										Servicios y actividades opcionales:
										{!isPreview && (
											<HelpTooltip
												helpKey="optionalServices"
												placement="bottom-end"
												iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
											/>
										)}
									</Typography>
								)}
							</Grid>
							{!isPreview && (
								<Grid item xs={2} sm={2}>
									<Typography component="span" variant="body2" className={classes.showOptionals}>
										Mostrar adicional
									</Typography>
								</Grid>
							)}
						</Grid>
						{destinations.map(
							(destination, indexDestination) =>
								canEvaluateShowDestination(destination) &&
								destinations.findIndex((x) => x.cityName == destination.cityName) == indexDestination && (
									<div
										key={'destination-optional-service-container-' + indexDestination + '-' + destination.cityName}
										className={classes.destinationContainer}
									>
										{isMediumDown && (
											<Typography
												component="h1"
												variant="h6"
												sx={{
													marginBottom: '8px',
													width: '100%',
													backgroundColor: '#ED6602',
													paddingLeft: '16px',
													paddingRight: '16px',
													paddingTop: '2px',
													paddingBottom: '2px',
													borderRadius: '4px',
													fontSize: '1rem',
													color: 'white',
												}}
											>
												{getCityNameCleaned(destination.cityName)}:
											</Typography>
										)}
										{!isMediumDown && (
											<Typography component="h1" variant="h6">
												{getCityNameCleaned(destination.cityName)}:
											</Typography>
										)}

										{destination.optionalServices.map((optionalService, indexOptionalService) => (
											<>
												{isPreview && optionalService.show && (
													<div
														key={'optional-service-container-preview ' + destination.cityName + optionalService.id}
														className={classes.optionalServiceCardContainer}
													>
														{!isMediumDown && (
															<OptionalServiceCardDesktop
																key={
																	'optional-service-container-preview-desktop-' +
																	indexDestination +
																	'-' +
																	destination.cityName +
																	optionalService.id
																}
																optionalService={optionalService}
																paxTotals={paxTotals}
															/>
														)}
														{isMediumDown && (
															<OptionalServiceCardMobile
																key={
																	'optional-service-container-preview-mobile-' +
																	indexDestination +
																	'-' +
																	destination.cityName +
																	optionalService.id
																}
																optionalService={optionalService}
																paxTotals={paxTotals}
															/>
														)}
													</div>
												)}

												{!isPreview && (
													<Grid
														key={'optional-service-container-no-preview ' + destination.cityName + optionalService.id}
														className={classes.optionalServiceCardContainer}
														container
														spacing={2}
													>
														<Grid
															item
															xs={11}
															sm={11}
															key={
																'optional-service-container-no-preview-grid-' +
																indexDestination +
																'-' +
																destination.cityName +
																optionalService.id
															}
														>
															{!isMediumDown && (
																<OptionalServiceCardDesktop
																	key={
																		'optional-service-container-no-preview-desktop-' +
																		indexDestination +
																		'-' +
																		destination.cityName +
																		optionalService.id
																	}
																	optionalService={optionalService}
																	paxTotals={paxTotals}
																/>
															)}
															{isMediumDown && (
																<OptionalServiceCardMobile
																	key={
																		'optional-service-container-no-preview-mobile-' +
																		indexDestination +
																		'-' +
																		destination.cityName +
																		optionalService.id
																	}
																	optionalService={optionalService}
																	paxTotals={paxTotals}
																/>
															)}
														</Grid>
														<Grid
															item
															xs={1}
															sm={1}
															container
															direction="row"
															justifyContent="center"
															alignItems="center"
															key={
																'optional-service-container-no-preview-grid-switch-' +
																indexDestination +
																'-' +
																destination.cityName +
																optionalService.id
															}
														>
															<Switch
																name="showOptionals"
																checked={optionalService.show}
																onChange={() => handleChangeOptionalService(indexDestination, indexOptionalService)}
															/>
														</Grid>
													</Grid>
												)}
											</>
										))}
									</div>
								),
						)}
					</div>
				</Container>
			)}
		</>
	);
};
