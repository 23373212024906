// src/components/AirDisplay.tsx

import React, { useState } from 'react';
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Paper,
	Radio,
	Theme,
	Typography,
	IconButton,
	Tooltip,
	Link,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';

import makeStyles from '@mui/styles/makeStyles';

import { FlightsTableDesktop } from './FlightsTableDesktop';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { FlightMobile } from './FlightMobile';
import FlightImagePreviewList from './FileImagePreviewList';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { countryWAPMapping } from 'constants/constants';
import { Air, AirSimple, BaggageTypes } from '../../types';
import { TstDialog } from './TstDialog';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2),
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface AirDisplayProps {
	data: Air | AirSimple;
	previewMode: boolean;
	index: number;
	showSelectionControl?: boolean;
	indexSelected?: number | null;
	handleSelectionChange?: (index: number | null) => void;
	handleDeleteFlight?: (index: number) => void;
	onEdit?: () => void;
}

export const AirDisplay = ({
	data,
	previewMode,
	index,
	showSelectionControl,
	indexSelected,
	handleSelectionChange,
	handleDeleteFlight,
	onEdit,
}: AirDisplayProps): JSX.Element | null => {
	if (!data) return null;
	const classes = useStyles();
	const isMediumDown = useBreakpoints().mediumDown;
	const hasImages = data.images.length > 0;
	const hasFlightsInfo = Array.isArray(data?.data) && data?.data.length > 0;
	const handleClearAirConfig = () => {
		handleDeleteFlight && handleDeleteFlight(index);
	};

	const HeaderFlightSelection = () => (
		<Box
			bgcolor="secondary.main"
			color="primary.main"
			onClick={() => handleSelectionChange && handleSelectionChange(index)}
			sx={{
				display: 'flex',
				cursor: 'pointer',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
				paddingLeft: '16px',
				paddingRight: '16px',
				borderRadius: '4px',
				marginBottom: '8px',
				paddingTop: '4px',
				paddingBottom: '4px',
				opacity: index === indexSelected ? 0.9 : 0.7,
			}}
		>
			<Typography
				component="h1"
				variant="h6"
				sx={{
					fontSize: '1rem',
					color: 'white',
				}}
			>
				Opción {index + 1}
			</Typography>
			<Radio
				style={{ marginLeft: 'auto', padding: '0' }}
				checked={indexSelected == index}
				onChange={() => handleSelectionChange && handleSelectionChange(index)}
				sx={{
					backgroundColor: 'white',
					color: 'inherit',
					'&.Mui-checked': { color: 'inherit', backgroundColor: 'white' },
				}}
			/>
		</Box>
	);

	const { auth } = useSelector((state: RootState) => state);
	const countryWAP = countryWAPMapping[auth.country] || '59897683318';
	const baggageUrl = `https://aerolineas.mevuelo.info/${auth.country}/${data.validatingCarrier}/equipaje`;
	const baggageMessage = `https://wa.me/${countryWAP}?text=Hola%2C%20quisiera%20saber%20m%C3%A1s%20acerca%20de%20la%20pol%C3%ADtica%20de%20equipaje%20de%20la%20cotizaci%C3%B3n%20que%20me%20enviaste.`;

	const [isTstDialogOpen, setIsTstDialogOpen] = useState<boolean>(false);
	const handleOpenTstDialog = () => {
		setIsTstDialogOpen(true);
	};
	const handleCloseTstDialog = () => {
		setIsTstDialogOpen(false);
	};

	return (
		<Paper sx={{ marginBottom: '20px' }}>
			<div className={classes.content}>
				<div>
					{hasFlightsInfo && !isMediumDown && (
						<div>
							{showSelectionControl && <HeaderFlightSelection />}
							<FlightsTableDesktop flightInfo={data.data || []} />
						</div>
					)}
					{hasFlightsInfo && isMediumDown && (
						<div>
							{showSelectionControl && <HeaderFlightSelection />}
							<FlightMobile flightInfo={data.data || []} />
						</div>
					)}
					{hasImages && (
						<div>
							{showSelectionControl && <HeaderFlightSelection />}
							{<FlightImagePreviewList flightImages={data.images} />}
						</div>
					)}
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						{!previewMode && (
							<List dense>
								<ListSubheader component="div">Carrier</ListSubheader>
								<ListItem>
									<ListItemText primary={data.validatingCarrier} />
								</ListItem>
							</List>
						)}
						<List dense style={{ width: previewMode ? '100%' : 'auto', flex: previewMode ? 'none' : 1 }}>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<ListSubheader component="div">Equipajes incluídos:</ListSubheader>
								<Tooltip
									title={
										<React.Fragment>
											<div>
												<strong>Artículo personal:</strong> Mochila pequeña o bolso, dimensiones hasta 45 x 35 x 20 cm,
												para colocar debajo del asiento delantero.
											</div>
											<div>
												<strong>Equipaje de mano:</strong> Bolso, maleta o carry-on mediano; medidas típicas de hasta 56
												x 45 x 25 cm y 10 kg, diseñado para el compartimento superior.
											</div>
											<div>
												<strong>Equipaje para despachar:</strong> Maleta grande para facturación, generalmente hasta 158
												cm lineales (suma de dimensiones) y 23 kg, para bodega del avión.
											</div>
										</React.Fragment>
									}
									placement="right"
								>
									<IconButton>
										<InfoIcon color="info" />
									</IconButton>
								</Tooltip>
							</div>

							<ListItem>
								<CheckCircleIcon color="success" sx={{ marginRight: '4px' }} />
								<ListItemText primary="Artículo personal" />
							</ListItem>

							{data.carryOn ? (
								<ListItem>
									<CheckCircleIcon color="success" sx={{ marginRight: '4px' }} />
									<ListItemText primary="Equipaje de mano" />
								</ListItem>
							) : (
								<ListItem>
									<CancelIcon color="warning" sx={{ marginRight: '4px' }} />
									<ListItemText primary="No incluye equipaje de mano" />
								</ListItem>
							)}

							{data.baggage === BaggageTypes.NO_BAGGAGE && (
								<ListItem>
									<CancelIcon color="warning" sx={{ marginRight: '4px' }} />
									<ListItemText primary="No incluye equipaje para despachar" />
								</ListItem>
							)}

							{data.baggage === BaggageTypes.ONE_X_23K && (
								<ListItem>
									<CheckCircleIcon color="success" sx={{ marginRight: '4px' }} />
									<ListItemText primary="Equipaje en bodega 1 pieza hasta 23Kg" />
								</ListItem>
							)}

							{data.baggage === BaggageTypes.TWO_X_23K && (
								<ListItem>
									<CheckCircleIcon color="success" sx={{ marginRight: '4px' }} />
									<ListItemText primary="Equipaje en bodega 2 piezas hasta 23Kg" />
								</ListItem>
							)}

							{previewMode && (!data.carryOn || data.baggage === BaggageTypes.NO_BAGGAGE) && (
								<Typography variant="body2" fontSize={12} sx={{ padding: '12px' }}>
									*No te preocupes, en caso de que lo desees podemos agregar
									{!data.carryOn && <strong> equipaje de mano</strong>}
									{!data.carryOn && data.baggage === BaggageTypes.NO_BAGGAGE && ' y/o'}
									{data.baggage === BaggageTypes.NO_BAGGAGE && <strong> equipaje en bodega </strong>}
									con un costo adicional antes de confirmar la cotización, durante el proceso del web check-in o mismo
									en el aeropuerto antes de partir el viaje.
								</Typography>
							)}
							{previewMode && (
								<Typography variant="body2" fontSize={12} sx={{ padding: '12px' }}>
									**Puedes verificar las condiciones de equipaje de la aerolinea{' '}
									<Link href={baggageUrl} target="_blank" rel="noopener noreferrer">
										<strong> haciendo click aqui</strong>
									</Link>{' '}
									o consultar con tu asesor a través de{' '}
									<Link href={baggageMessage} target="_blank" rel="noopener noreferrer">
										<strong>whatsapp</strong>
									</Link>
									.
								</Typography>
							)}
						</List>
						{!previewMode && 'fareADT' in data && (
							<List dense>
								<ListSubheader component="div">Tarifas</ListSubheader>
								<div style={{ display: 'flex' }}>
									<ListItem>
										<ListItemText primary="Adultos" secondary={data.fareADT} />
									</ListItem>
									<ListItem>
										<ListItemText primary="CHD" secondary={data.fareCHD} />
									</ListItem>
									<ListItem>
										<ListItemText primary="INF" secondary={data.fareINF} />
									</ListItem>
									<ListItem>
										<ListItemText primary="Moneda" secondary="USD" />
									</ListItem>
								</div>
							</List>
						)}
					</div>
				</div>
			</div>

			{!previewMode && (
				<Box justifyContent="space-around" display="flex" pb={2}>
					<Button type="button" variant="outlined" color="secondary" onClick={handleClearAirConfig}>
						Eliminar opción
					</Button>
					<Button variant="outlined" color="secondary" onClick={onEdit} sx={{ mx: 1 }}>
						Editar opción
					</Button>
					{'tst' in data && data.tst && (
						<Button variant="outlined" color="secondary" onClick={handleOpenTstDialog}>
							Ver TST
						</Button>
					)}
				</Box>
			)}
			{'tst' in data && data.tst && <TstDialog open={isTstDialogOpen} tst={data.tst} onClose={handleCloseTstDialog} />}
		</Paper>
	);
};

export default AirDisplay;
