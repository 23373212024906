import React from 'react';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getBoardName, GetPAXTotalsResponse, separadorDeMiles } from '../../helpers';
import { HotelOptionsSimple } from '../../types';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { CountryCodeEnum } from 'features/common/types';

const useStyles = makeStyles((theme) => ({
	cardRoom: { marginTop: theme.spacing(1), paddingLeft: '20px', paddingRight: '20px' },
	roomTitle: {
		fontStyle: 'normal',
		fontWeight: 700,
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
	textInfo: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
	},
	regimenInfo: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		color: 'rgba(0,0,0,0.57)',
	},
	priceTitle: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		float: 'left',
	},
	signTitle: {
		fontSize: '12px',
		fontStyle: 'italic',
		fontWeight: 700,
		lineHeight: '5px',
		color: '#71BCED',
	},
	signValue: {
		fontSize: '12px',
		fontStyle: 'italic',
		fontWeight: 700,
		color: '#71BCED',
		float: 'right',
		lineHeight: '5px',
		textAlign: 'right',
	},
	priceValue: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 700,
		color: theme.palette.secondary.main,
		float: 'right',
		textAlign: 'right',
	},
	rowPriceContainer: {
		marginTop: theme.spacing(2),
	},
	rowFinalPriceContainer: {
		marginTop: theme.spacing(2),
	},
	localPrice: {
		display: 'flex',
		fontSize: '12px',
		width: '150px',
		justifyContent: 'end',
	},
}));
interface Props {
	hotelOption: HotelOptionsSimple;
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

const PriceRow = ({ title, priceUSD, localPrice, showLocalCurrency, localCurrency, classes }: any) => (
	<Grid container sx={{ paddingTop: '8px' }}>
		<Grid item xs={6}>
			<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
				{title}
			</Typography>
		</Grid>
		<Grid item xs={6}>
			<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
				USD {separadorDeMiles(priceUSD)}
				{showLocalCurrency && localPrice !== null && (
					<Typography className={classes.localPrice}>
						{localCurrency} {separadorDeMiles(localPrice)}
					</Typography>
				)}
			</Typography>
		</Grid>
	</Grid>
);

const SignRow = ({ title, signUSD, localSign, showLocalCurrency, localCurrency, classes }: any) => (
	<Grid container sx={{ paddingTop: '8px' }}>
		<Grid item xs={6}>
			<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
				{title}
			</Typography>
		</Grid>
		<Grid item xs={6}>
			<Typography className={classes.signValue} component={'p'} variant={'body2'}>
				USD {separadorDeMiles(signUSD)}
				{showLocalCurrency && localSign !== null && (
					<Typography className={classes.localPrice}>
						{localCurrency} {separadorDeMiles(localSign)}
					</Typography>
				)}
			</Typography>
		</Grid>
	</Grid>
);

const RoomCard = ({ optionItem, optionItemIndex, paxCountInRoom, props, classes, auth }: any) => {
	const { totalAdults, hasChild, totalChild, hasInfant, totalInfant } = paxCountInRoom;
	const priceLocal = Math.round(optionItem.adtPrice * props.exchangeRate);
	const priceLocalTotal = Math.round(optionItem.totalPrice * props.exchangeRate);

	return (
		<Card key={`room-${optionItemIndex}`} className={classes.cardRoom}>
			<CardContent>
				<Typography component="h1" variant="subtitle1" className={classes.roomTitle}>
					Habitación {optionItemIndex + 1}
				</Typography>
				<Typography className={classes.textInfo} component={'p'} variant={'body2'}>
					En esta habitación se hospedan: <span />
					{totalAdults} {totalAdults > 1 ? 'adultos' : 'adulto'}
					{hasChild && ` + ${totalChild} ${totalChild > 1 ? 'niños' : 'niño'}`}
					{hasInfant && ` + ${totalInfant} ${totalInfant > 1 ? 'bebés' : 'bebé'}`}.
				</Typography>
				<Typography className={classes.textInfo} component={'span'} variant={'body2'}>
					Régimen:
				</Typography>{' '}
				<Typography className={classes.regimenInfo} component={'span'} variant={'body2'}>
					{getBoardName(optionItem.board)}
				</Typography>
				{optionItem.description && (
					<Typography className={classes.textInfo} component={'p'} variant={'body2'}>
						Tipo de habitación: {optionItem.description}
					</Typography>
				)}
				<PriceRow
					title="Precio por adulto"
					priceUSD={optionItem.adtPrice}
					localPrice={priceLocal}
					showLocalCurrency={props.showLocalCurrency}
					localCurrency={props.localCurrency}
					classes={classes}
				/>
				{optionItem.sign && (
					<SignRow
						title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña por adulto'}
						signUSD={Math.round(optionItem.sign.adtSign)}
						localSign={Math.round(optionItem.sign.adtSign * props.exchangeRate)}
						showLocalCurrency={props.showLocalCurrency}
						localCurrency={props.localCurrency}
						classes={classes}
					/>
				)}
				{hasChild && (
					<>
						<PriceRow
							title="Precio por niño"
							priceUSD={optionItem.chdPrice}
							localPrice={Math.round(optionItem.chdPrice * props.exchangeRate)}
							showLocalCurrency={props.showLocalCurrency}
							localCurrency={props.localCurrency}
							classes={classes}
						/>
						{optionItem.sign && (
							<SignRow
								title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña por niño'}
								signUSD={Math.round(optionItem.sign.chdSign)}
								localSign={Math.round(optionItem.sign.chdSign * props.exchangeRate)}
								showLocalCurrency={props.showLocalCurrency}
								localCurrency={props.localCurrency}
								classes={classes}
							/>
						)}
					</>
				)}
				{hasInfant && (
					<>
						<PriceRow
							title="Precio por bebé"
							priceUSD={optionItem.infPrice}
							localPrice={Math.round(optionItem.infPrice * props.exchangeRate)}
							showLocalCurrency={props.showLocalCurrency}
							localCurrency={props.localCurrency}
							classes={classes}
						/>
						{optionItem.sign && (
							<SignRow
								title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña por bebé'}
								signUSD={Math.round(optionItem.sign.infSign)}
								localSign={Math.round(optionItem.sign.infSign * props.exchangeRate)}
								showLocalCurrency={props.showLocalCurrency}
								localCurrency={props.localCurrency}
								classes={classes}
							/>
						)}
					</>
				)}
				<Divider sx={{ paddingTop: '8px', marginBottom: '8px' }} />
				<PriceRow
					title="Precio total del paquete x la habitación:"
					priceUSD={optionItem.totalPrice}
					localPrice={priceLocalTotal}
					showLocalCurrency={props.showLocalCurrency}
					localCurrency={props.localCurrency}
					classes={classes}
				/>
				{optionItem.sign && (
					<SignRow
						title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña por la habitación'}
						signUSD={Math.round(optionItem.sign.totalSign)}
						localSign={Math.round(optionItem.sign.totalSign * props.exchangeRate)}
						showLocalCurrency={props.showLocalCurrency}
						localCurrency={props.localCurrency}
						classes={classes}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export const HotelOptionsPricePreviewMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { hotelOption, paxTotals } = props;
	const { auth } = useSelector((state: RootState) => state);

	return (
		<>
			{hotelOption.rooms.map((optionItem, index) => (
				<RoomCard
					key={`room-${index}`}
					optionItem={optionItem}
					optionItemIndex={index}
					paxCountInRoom={paxTotals.totalPaxPerRoom[index]}
					props={props}
					classes={classes}
					auth={auth}
				/>
			))}
		</>
	);
};
