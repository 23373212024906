import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LocationOn from '@mui/icons-material/LocationOn';
import Rating from '@mui/material/Rating';
import Chip from '@mui/material/Chip';
import PoolIcon from '@mui/icons-material/Pool';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WifiIcon from '@mui/icons-material/Wifi';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PoweredByGoogle from 'assets/poweredByGoogle.png';
import { HotelOptionDetailDestinationMultiple } from '../../types';
import { Box, Button, CardActions, Grid, Radio } from '@mui/material';
import { openInNewTabHotelUrl } from '../../helpers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			color: theme.palette.primary.main,
			fontWeight: 'bold',
			fontSize: '1rem',
		},
		address: {
			fontSize: '0.75rem',
		},
		media: {
			height: 0,
			paddingTop: '56.25%', // 16:9
		},
		opinions: {
			fontSize: '11px',
			fontStyle: 'italic',
			fontWeight: 400,
			letterSpacing: '0em',
		},
		facilitiesIcon: {
			fontWeight: 'bold',
			fontSize: '20px',
		},
		flex: {
			display: 'flex',
		},
		ratingMolecule: {
			display: 'flex',
			alignItems: 'center',
			paddingTop: theme.spacing(1),
		},
		evaluation: {
			color: '#71BCED',
			fontSize: '0.75rem',
		},
		ratingText: {
			paddingLeft: theme.spacing(2),
		},
		powered: {
			paddingLeft: theme.spacing(2),
			width: '115px',
		},
		hotelStars: {
			display: 'flex',
			paddingTop: theme.spacing(1),
		},
		webSiteContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
	}),
);
interface Props {
	hotelOption: HotelOptionDetailDestinationMultiple;
	isSelected: boolean;
	handleSelectHotel: () => void;
}

export const HotelDetailCardMultiDestination = ({ hotelOption, isSelected, handleSelectHotel }: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<Card
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				position: 'relative',
			}}
		>
			<Box
				bgcolor="primary.main"
				color="secondary.main"
				onClick={handleSelectHotel}
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					width: '100%',
					position: 'absolute',
					opacity: isSelected ? 0.9 : 0.5,
				}}
			>
				<Radio
					sx={{
						color: 'inherit',
						'&.Mui-checked': {
							color: 'inherit',
						},
					}}
					checked={isSelected}
					onChange={handleSelectHotel}
				/>
			</Box>
			<CardMedia className={classes.media} image={hotelOption.details?.mainImage} title={hotelOption.details?.name} />
			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<Typography component="h5" variant="h6" className={classes.title}>
							{hotelOption.details?.name}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.flex}>
							<LocationOn color="secondary" />
							<Typography variant="subtitle1" color="textSecondary" className={classes.address}>
								{hotelOption.details?.address}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.hotelStars}>
							<Rating name="read-only" value={hotelOption.details?.accommodationCategory} readOnly />
						</div>
					</Grid>
					<Grid item xs={12}>
						{hotelOption.details?.rating && (
							<div className={classes.ratingMolecule}>
								<Chip label={parseFloat((hotelOption.details?.rating).toFixed(2))} color="secondary" />
								<div className={classes.ratingText}>
									<Typography variant="subtitle1" className={classes.evaluation}>
										{hotelOption.details?.ratingText}
									</Typography>
									<Typography variant="subtitle2" className={classes.opinions}>
										{hotelOption.details?.amountReviews} opiniones
									</Typography>
								</div>
								<img alt={hotelOption.details?.name} className={classes.powered} src={PoweredByGoogle} />
							</div>
						)}
					</Grid>

					{hotelOption.details?.link != null && hotelOption.details?.link != '' && (
						<Grid item xs={12} className={classes.webSiteContainer}>
							<Button
								variant="contained"
								color="primary"
								size="small"
								style={{ fontSize: '12px', fontStyle: 'normal', textTransform: 'none' }}
								onClick={() => openInNewTabHotelUrl(hotelOption.details?.link || '')}
							>
								Sitio web
							</Button>
						</Grid>
					)}
				</Grid>
			</CardContent>
			<CardActions>
				<Grid width="100%" display="flex" justifyContent="space-around" container xs={12}>
					{hotelOption.details?.pool && <PoolIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.wifi && <WifiIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.airConditioning && <AcUnitIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.parking && <LocalParkingIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.gym && <FitnessCenterIcon color="secondary" className={classes.facilitiesIcon} />}
				</Grid>
			</CardActions>
		</Card>
	);
};
