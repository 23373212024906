// import React from 'react';
// import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
// import { getBoardName, GetPAXTotalsResponse } from '../../helpers';
// import { HotelOptionsSimple } from '../../types';
// import { Utility } from '../Utility';
// import { useSelector } from 'react-redux';
// import { CountryCodeEnum } from 'features/common/types';
// import { RootState } from 'store';

// const useStyles = makeStyles((theme) => ({
// 	paper: {
// 		marginTop: theme.spacing(8),
// 		display: 'flex',
// 		flexDirection: 'column',
// 		alignItems: 'left',
// 	},
// 	form: {
// 		width: '100%', // Fix IE 11 issue.
// 		marginTop: theme.spacing(3),
// 	},
// 	table: {
// 		minWidth: 650,
// 	},
// 	formControl: {
// 		marginTop: -3,
// 		width: '100%',
// 	},
// 	formGroup: {
// 		marginTop: 14,
// 	},
// 	optionTableHolder: {
// 		marginTop: theme.spacing(3),
// 		marginBottom: theme.spacing(3),
// 	},
// 	utility: {
// 		color: theme.palette.secondary.main,
// 		fontWeight: 'bold',
// 	},
// 	sign: {
// 		color: theme.palette.primary.main,
// 	},
// 	optionsTitle: {
// 		fontStyle: 'normal',
// 		fontWeight: 600,
// 		letterSpacing: '0em',
// 		textAlign: 'center',
// 		color: theme.palette.secondary.main,
// 		marginBottom: theme.spacing(1),
// 	},
// 	localPrice: {
// 		display: 'flex',
// 		width: '140px',
// 		justifyContent: 'center',
// 	},
// 	localPriceQuotation: {
// 		display: 'flex',
// 		width: '170px',
// 		justifyContent: 'center',
// 	},
// }));
// interface Props {
// 	hotelOption: HotelOptionsSimple;
// 	paxTotals: GetPAXTotalsResponse;
// 	showUtility: boolean;
// 	markup: number | null;
// 	exchangeRate: number;
// 	localCurrency: string;
// 	showLocalCurrency?: boolean;
// }

// export const HotelOptionsPricePreviewDesktop = (props: Props): JSX.Element => {
// 	const classes = useStyles();
// 	const { hotelOption, paxTotals } = props;
// 	const showLocalCurrencyQuotation = props.showLocalCurrency;
// 	const { auth } = useSelector((state: RootState) => state);

// 	return (
// 		<TableContainer component={Paper}>
// 			<Table className={classes.table} aria-label="simple table">
// 				<TableHead>
// 					<TableRow>
// 						<TableCell>Habitación</TableCell>

// 						<TableCell align="right">Pasajeros x habitación</TableCell>
// 						<TableCell align="right">Régimen</TableCell>
// 						<TableCell align="right">Descripción</TableCell>
// 						<TableCell align="right">Precio x Adulto</TableCell>
// 						<TableCell align="right">{paxTotals.child > 0 ? 'Precio x Niño' : ''}</TableCell>
// 						<TableCell align="right">{paxTotals.infants > 0 ? 'Precio x Bebé' : ''}</TableCell>
// 						<TableCell align="right">Precio Total x paquete</TableCell>
// 					</TableRow>
// 				</TableHead>
// 				<TableBody>
// 					{hotelOption.rooms.map((optionItem, optionItemIndex) => {
// 						const mealPlan = optionItem.board;
// 						const paxCountInRoom = paxTotals.totalPaxPerRoom[optionItemIndex];
// 						const totalAdults = paxCountInRoom.totalAdults;
// 						const CHDExists = paxCountInRoom.hasChild;
// 						const INFExists = paxCountInRoom.hasInfant;

// 						const priceFormat = (value: number, rate: number) =>
// 							Math.round(value * rate)
// 								.toLocaleString()
// 								.replace(/,/g, '.');

// 						const priceQuotationADT = priceFormat(optionItem.adtPrice, props.exchangeRate);
// 						const priceLocalTotalQuotation = priceFormat(optionItem.totalPrice, props.exchangeRate);
// 						const priceLocalQuotationCHD = priceFormat(optionItem.chdPrice, props.exchangeRate);
// 						const priceLocalQuotationINF = priceFormat(optionItem.infPrice, props.exchangeRate);
// 						return (
// 							<>
// 								<TableRow>
// 									<TableCell component="th" scope="row">
// 										{optionItemIndex + 1}
// 									</TableCell>

// 									<TableCell align="right">
// 										{totalAdults} {totalAdults > 1 ? 'adultos' : 'adulto'} {CHDExists && '+'}{' '}
// 										{CHDExists && paxCountInRoom.totalChild}{' '}
// 										{CHDExists ? (CHDExists && paxCountInRoom.totalChild > 1 ? 'niños' : 'niño') : ''}{' '}
// 										{INFExists && '+'} {INFExists && paxCountInRoom.totalInfant}{' '}
// 										{INFExists ? (INFExists && paxCountInRoom.totalInfant > 1 ? 'bebés' : 'bebé') : ''}
// 									</TableCell>
// 									<TableCell align="right">{getBoardName(mealPlan)}</TableCell>
// 									<TableCell align="right">
// 										{optionItem.description && optionItem.description !== '' ? optionItem.description : '-'}
// 									</TableCell>
// 									<TableCell align="center">
// 										USD {optionItem.adtPrice}
// 										<br />
// 										{showLocalCurrencyQuotation && (
// 											<TableCell className={classes.localPriceQuotation}>
// 												{props.localCurrency} {priceQuotationADT}
// 											</TableCell>
// 										)}
// 										<Utility price={optionItem.adtPrice} markup={props.markup} show={props.showUtility} />
// 									</TableCell>
// 									<TableCell align="center">
// 										{CHDExists && 'USD'} {CHDExists && optionItem.chdPrice}{' '}
// 										<Utility price={optionItem.chdPrice} markup={props.markup} show={CHDExists && props.showUtility} />
// 										<br />
// 										{showLocalCurrencyQuotation && CHDExists && (
// 											<TableCell className={classes.localPriceQuotation}>
// 												{props.localCurrency} {priceLocalQuotationCHD}
// 											</TableCell>
// 										)}
// 									</TableCell>
// 									<TableCell align="center">
// 										{INFExists && 'USD'} {INFExists && optionItem.infPrice}{' '}
// 										<Utility price={optionItem.infPrice} markup={props.markup} show={INFExists && props.showUtility} />
// 										{showLocalCurrencyQuotation && INFExists && (
// 											<TableCell className={classes.localPriceQuotation}>
// 												{props.localCurrency} {priceLocalQuotationINF}
// 											</TableCell>
// 										)}
// 									</TableCell>
// 									<TableCell align="center">
// 										USD {optionItem.totalPrice}
// 										<br />
// 										{showLocalCurrencyQuotation && (
// 											<TableCell className={classes.localPriceQuotation}>
// 												{props.localCurrency} {priceLocalTotalQuotation}
// 											</TableCell>
// 										)}{' '}
// 										<Utility price={optionItem.totalPrice} markup={props.markup} show={props.showUtility} />
// 									</TableCell>
// 								</TableRow>
// 								{optionItem.sign && (
// 									<TableRow>
// 										<TableCell />
// 										<TableCell align="right" colSpan={3} className={classes.sign}>
// 											{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
// 										</TableCell>
// 										<TableCell align="center" className={classes.sign}>
// 											USD {Math.round(optionItem.sign?.adtSign)}{' '}
// 											{showLocalCurrencyQuotation && (
// 												<TableCell className={classes.localPriceQuotation}>
// 													{props.localCurrency}{' '}
// 													{Math.round(optionItem.sign?.adtSign * props.exchangeRate)
// 														.toLocaleString()
// 														.replace(/,/g, '.')}
// 												</TableCell>
// 											)}
// 										</TableCell>
// 										<TableCell align="right" className={classes.sign}>
// 											{CHDExists && 'USD'} {CHDExists && Math.round(optionItem.sign?.chdSign)}
// 											{showLocalCurrencyQuotation && (
// 												<TableCell className={classes.localPriceQuotation}>
// 													{props.localCurrency}{' '}
// 													{Math.round(optionItem.sign?.chdSign * props.exchangeRate)
// 														.toLocaleString()
// 														.replace(/,/g, '.')}
// 												</TableCell>
// 											)}
// 										</TableCell>
// 										<TableCell align="right" className={classes.sign}>
// 											{INFExists && 'USD'} {INFExists && Math.round(optionItem.sign?.infSign)}
// 											{showLocalCurrencyQuotation && (
// 												<TableCell className={classes.localPriceQuotation}>
// 													{props.localCurrency}{' '}
// 													{Math.round(optionItem.sign?.infSign * props.exchangeRate)
// 														.toLocaleString()
// 														.replace(/,/g, '.')}
// 												</TableCell>
// 											)}
// 										</TableCell>
// 										<TableCell align="center" className={classes.sign}>
// 											USD {Math.round(optionItem.sign?.totalSign)}
// 											{showLocalCurrencyQuotation && (
// 												<TableCell className={classes.localPriceQuotation}>
// 													{props.localCurrency}{' '}
// 													{Math.round(optionItem.sign?.totalSign * props.exchangeRate)
// 														.toLocaleString()
// 														.replace(/,/g, '.')}
// 												</TableCell>
// 											)}
// 										</TableCell>
// 									</TableRow>
// 								)}
// 							</>
// 						);
// 					})}
// 				</TableBody>
// 			</Table>
// 		</TableContainer>
// 	);
// };

import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getBoardName, GetPAXTotalsResponse } from '../../helpers';
import { HotelOptionsSimple } from '../../types';
import { Utility } from '../Utility';
import { useSelector } from 'react-redux';
import { CountryCodeEnum } from 'features/common/types';
import { RootState } from 'store';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
	optionTableHolder: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	utility: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
	},
	sign: {
		color: theme.palette.primary.main,
	},
	optionsTitle: {
		fontStyle: 'normal',
		fontWeight: 600,
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
	localPrice: {
		display: 'flex',
		width: '140px',
		justifyContent: 'center',
	},
	localPriceQuotation: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

interface Props {
	hotelOption: HotelOptionsSimple;
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

export const HotelOptionsPricePreviewDesktop = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { hotelOption, paxTotals } = props;
	const showLocalCurrencyQuotation = props.showLocalCurrency;
	const { auth } = useSelector((state: RootState) => state);

	// Función para formatear precio en base a tasa de cambio.
	// Ej: priceFormat(100, 300) => "30.000"
	const priceFormat = (value: number, rate: number) =>
		Math.round(value * rate)
			.toLocaleString()
			.replace(/,/g, '.');

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="hotel-options-table">
				<TableHead>
					<TableRow>
						<TableCell>Habitación</TableCell>
						<TableCell align="right">Pasajeros x habitación</TableCell>
						<TableCell align="right">Régimen</TableCell>
						<TableCell align="right">Descripción</TableCell>
						<TableCell align="center">Precio x Adulto</TableCell>
						<TableCell align="center">{paxTotals.child > 0 ? 'Precio x Niño' : ''}</TableCell>
						<TableCell align="center">{paxTotals.infants > 0 ? 'Precio x Bebé' : ''}</TableCell>
						<TableCell align="center">Precio Total x paquete</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{hotelOption.rooms.map((optionItem, optionItemIndex) => {
						const mealPlan = optionItem.board;
						const paxCountInRoom = paxTotals.totalPaxPerRoom[optionItemIndex];
						const { totalAdults, hasChild: CHDExists, hasInfant: INFExists } = paxCountInRoom;

						// Precios equivalentes en la moneda local
						const priceQuotationADT = priceFormat(optionItem.adtPrice, props.exchangeRate);
						const priceQuotationCHD = priceFormat(optionItem.chdPrice, props.exchangeRate);
						const priceQuotationINF = priceFormat(optionItem.infPrice, props.exchangeRate);
						const priceQuotationTotal = priceFormat(optionItem.totalPrice, props.exchangeRate);

						// Sign (seña) equivalentes
						const adtSign = optionItem.sign?.adtSign ?? 0;
						const chdSign = optionItem.sign?.chdSign ?? 0;
						const infSign = optionItem.sign?.infSign ?? 0;
						const totalSign = optionItem.sign?.totalSign ?? 0;

						return (
							<React.Fragment key={optionItemIndex}>
								<TableRow>
									{/* # Habitación */}
									<TableCell component="th" scope="row">
										{optionItemIndex + 1}
									</TableCell>

									{/* Pasajeros por habitación */}
									<TableCell align="right">
										{totalAdults} {totalAdults > 1 ? 'adultos' : 'adulto'}
										{CHDExists && ` + ${paxCountInRoom.totalChild} `}
										{CHDExists ? (paxCountInRoom.totalChild > 1 ? 'niños' : 'niño') : ''}
										{INFExists && ` + ${paxCountInRoom.totalInfant} `}
										{INFExists ? (paxCountInRoom.totalInfant > 1 ? 'bebés' : 'bebé') : ''}
									</TableCell>

									{/* Régimen (board) */}
									<TableCell align="right">{getBoardName(mealPlan)}</TableCell>

									{/* Descripción */}
									<TableCell align="right">
										{optionItem.description && optionItem.description !== '' ? optionItem.description : '-'}
									</TableCell>

									{/* Precio Adulto */}
									<TableCell align="center">
										<div>
											USD {optionItem.adtPrice}
											{showLocalCurrencyQuotation && (
												<div className={classes.localPriceQuotation}>
													{props.localCurrency} {priceQuotationADT}
												</div>
											)}
										</div>
										<Utility price={optionItem.adtPrice} markup={props.markup} show={props.showUtility} />
									</TableCell>

									{/* Precio Niño */}
									<TableCell align="center">
										{CHDExists ? (
											<>
												<div>
													USD {optionItem.chdPrice}
													{showLocalCurrencyQuotation && (
														<div className={classes.localPriceQuotation}>
															{props.localCurrency} {priceQuotationCHD}
														</div>
													)}
												</div>
												<Utility price={optionItem.chdPrice} markup={props.markup} show={props.showUtility} />
											</>
										) : (
											// Si no hay niños, dejamos celda vacía
											<></>
										)}
									</TableCell>

									{/* Precio Bebé */}
									<TableCell align="center">
										{INFExists ? (
											<>
												<div>
													USD {optionItem.infPrice}
													{showLocalCurrencyQuotation && (
														<div className={classes.localPriceQuotation}>
															{props.localCurrency} {priceQuotationINF}
														</div>
													)}
												</div>
												<Utility price={optionItem.infPrice} markup={props.markup} show={props.showUtility} />
											</>
										) : (
											// Si no hay bebés, dejamos celda vacía
											<></>
										)}
									</TableCell>

									{/* Precio Total */}
									<TableCell align="center">
										<div>
											USD {optionItem.totalPrice}
											{showLocalCurrencyQuotation && (
												<div className={classes.localPriceQuotation}>
													{props.localCurrency} {priceQuotationTotal}
												</div>
											)}
										</div>
										<Utility price={optionItem.totalPrice} markup={props.markup} show={props.showUtility} />
									</TableCell>
								</TableRow>

								{/* Fila de la seña (opcional) */}
								{optionItem.sign && (
									<TableRow>
										<TableCell />
										<TableCell align="right" colSpan={3} className={classes.sign}>
											{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
										</TableCell>

										{/* Seña Adulto */}
										<TableCell align="center" className={classes.sign}>
											<div>
												USD {Math.round(adtSign)}
												{showLocalCurrencyQuotation && (
													<div className={classes.localPriceQuotation}>
														{props.localCurrency} {priceFormat(adtSign, props.exchangeRate)}
													</div>
												)}
											</div>
										</TableCell>

										{/* Seña Niño */}
										<TableCell align="center" className={classes.sign}>
											{CHDExists && (
												<div>
													USD {Math.round(chdSign)}
													{showLocalCurrencyQuotation && (
														<div className={classes.localPriceQuotation}>
															{props.localCurrency} {priceFormat(chdSign, props.exchangeRate)}
														</div>
													)}
												</div>
											)}
										</TableCell>

										{/* Seña Bebé */}
										<TableCell align="center" className={classes.sign}>
											{INFExists && (
												<div>
													USD {Math.round(infSign)}
													{showLocalCurrencyQuotation && (
														<div className={classes.localPriceQuotation}>
															{props.localCurrency} {priceFormat(infSign, props.exchangeRate)}
														</div>
													)}
												</div>
											)}
										</TableCell>

										{/* Seña Total */}
										<TableCell align="center" className={classes.sign}>
											<div>
												USD {Math.round(totalSign)}
												{showLocalCurrencyQuotation && (
													<div className={classes.localPriceQuotation}>
														{props.localCurrency} {priceFormat(totalSign, props.exchangeRate)}
													</div>
												)}
											</div>
										</TableCell>
									</TableRow>
								)}
							</React.Fragment>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
