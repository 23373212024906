// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
// 	Button,
// 	Container,
// 	Dialog,
// 	DialogActions,
// 	DialogContent,
// 	DialogContentText,
// 	DialogTitle,
// 	FormControl,
// 	Grid,
// 	IconButton,
// 	InputLabel,
// 	Paper,
// 	Select,
// 	Table,
// 	TableBody,
// 	TableCell,
// 	TableContainer,
// 	TableHead,
// 	TableRow,
// 	TextField,
// 	Typography,
// } from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
// import { RootState } from 'store';

// import { ManualService, serviceType } from '../types';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { addManualService, deleteManualService } from '../slice';
// import { calculateServicePrice } from '../helpers';
// import { getPAXTotals } from '../selectors';

// const useStyles = makeStyles((theme) => ({
// 	paper: {
// 		marginTop: theme.spacing(8),
// 		display: 'flex',
// 		flexDirection: 'column',
// 		alignItems: 'left',
// 	},
// 	form: {
// 		width: '100%', // Fix IE 11 issue.
// 		marginTop: theme.spacing(3),
// 	},
// 	table: {
// 		minWidth: 650,
// 	},
// 	formControl: {
// 		marginTop: -3,
// 		width: '100%',
// 	},
// 	formGroup: {
// 		marginTop: 14,
// 	},
// }));

// export const ManualServicesConfig = (): JSX.Element => {
// 	const classes = useStyles();
// 	const dispatch = useDispatch();
// 	const initialManualServicePayload: ManualService = {
// 		fareCost: 0,
// 		serviceType: 'perPassenger',
// 		name: '',
// 		description: '',
// 		chdCharged: 1,
// 		infCharged: 0,
// 	};
// 	const { manualServices } = useSelector((state: RootState) => state.quotation);
// 	const paxTotals = useSelector(getPAXTotals);
// 	const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = React.useState(false);
// 	const [deleteManualServicePayload, setDeleteManualServicePayload] = React.useState<number>(-1);
// 	const regexCHDOrINFValue = /^\d+(.\d{0,2})?$/;

// 	const handleClickOpenConfirmDelete = (index: number) => {
// 		setOpenDeleteConfirmDialog(true);
// 		setDeleteManualServicePayload(index);
// 	};
// 	const [manualServicePayload, setManualServicePayload] = React.useState<ManualService>(initialManualServicePayload);

// 	const handleCloseConfirmDelete = () => setOpenDeleteConfirmDialog(false);

// 	const handleAddManualService = () => {
// 		// @ts-ignore
// 		dispatch(addManualService(manualServicePayload));
// 		setManualServicePayload(initialManualServicePayload);
// 	};

// 	const handleDeleteManualService = () => {
// 		setOpenDeleteConfirmDialog(false);
// 		// @ts-ignore
// 		dispatch(deleteManualService(deleteManualServicePayload));
// 	};

// 	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
// 		setManualServicePayload((prevState) => ({ ...prevState, name: e.target.value }));
// 	};

// 	const handleChangeChildCharged = (value: number) => {
// 		setManualServicePayload((prevState) => ({ ...prevState, chdCharged: value }));
// 	};

// 	const handleChangeInfCharged = (value: number) => {
// 		setManualServicePayload((prevState) => ({ ...prevState, infCharged: value }));
// 	};

// 	const handleChangePrice = (value: number) => {
// 		setManualServicePayload((prevState) => ({ ...prevState, fareCost: value }));
// 	};

// 	const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
// 		setManualServicePayload((prevState) => ({ ...prevState, description: e.target.value }));
// 	};

// 	const handleChangeServiceType = (value: serviceType) => {
// 		setManualServicePayload((prevState) => ({ ...prevState, serviceType: value }));
// 	};

// 	const isValidName = () =>
// 		manualServicePayload.name &&
// 		manualServicePayload.name.length > 0 &&
// 		manualServicePayload.name.replace(/ /g, '').length > 0;

// 	const isValidManualServicePayload = () => isValidName() && manualServicePayload.fareCost > 0;

// 	return (
// 		<Container component="main" maxWidth="md">
// 			<div>
// 				<Dialog
// 					open={openDeleteConfirmDialog}
// 					onClose={handleCloseConfirmDelete}
// 					aria-labelledby="alert-dialog-title"
// 					aria-describedby="alert-dialog-description"
// 				>
// 					<DialogTitle id="alert-dialog-title">{'Eliminar el servicio manual'}</DialogTitle>
// 					<DialogContent>
// 						<DialogContentText id="alert-dialog-description">
// 							¿Confirma la eliminación del servicio manual?
// 						</DialogContentText>
// 					</DialogContent>
// 					<DialogActions>
// 						<Button variant="outlined" onClick={handleCloseConfirmDelete}>
// 							Cancelar
// 						</Button>
// 						<Button variant="contained" color="primary" onClick={handleDeleteManualService} autoFocus>
// 							Aceptar
// 						</Button>
// 					</DialogActions>
// 				</Dialog>
// 			</div>
// 			<div className={classes.paper}>
// 				<Typography component="h1" variant="h5">
// 					Servicios Manuales
// 				</Typography>
// 				<TableContainer component={Paper}>
// 					<Table className={classes.table} aria-label="simple table">
// 						<TableHead>
// 							<TableRow>
// 								<TableCell>Nombre</TableCell>
// 								<TableCell>Tipo</TableCell>
// 								<TableCell align="right">Tarifa</TableCell>
// 								<TableCell align="left">descripcion</TableCell>
// 								<TableCell align="right">Precio ADT</TableCell>
// 								<TableCell align="right">Precio CHD</TableCell>
// 								<TableCell align="right">Precio INF</TableCell>
// 								<TableCell align="right" />
// 							</TableRow>
// 						</TableHead>
// 						<TableBody>
// 							{manualServices.map((option, indexOption) => {
// 								const servicePrices = calculateServicePrice(
// 									paxTotals.adults,
// 									paxTotals.child,
// 									paxTotals.infants,
// 									option,
// 								);
// 								return (
// 									<TableRow key={indexOption}>
// 										<TableCell component="th" scope="row">
// 											{option.name}
// 										</TableCell>
// 										<TableCell>{option.serviceType}</TableCell>
// 										<TableCell align="right">{option.fareCost}</TableCell>
// 										<TableCell align="left">{option.description}</TableCell>
// 										<TableCell align="right">{servicePrices.adtPrice}</TableCell>
// 										<TableCell align="right">{servicePrices.chdPrice}</TableCell>
// 										<TableCell align="right">{servicePrices.infPrice}</TableCell>
// 										<TableCell align="right">
// 											<IconButton
// 												color="secondary"
// 												component="span"
// 												onClick={() => handleClickOpenConfirmDelete(indexOption)}
// 												size="large"
// 											>
// 												<DeleteIcon />
// 											</IconButton>
// 										</TableCell>
// 									</TableRow>
// 								);
// 							})}
// 						</TableBody>
// 					</Table>
// 				</TableContainer>
// 				<div>
// 					<form className={classes.form}>
// 						<Grid container spacing={2}>
// 							<Grid item xs={12}>
// 								<TextField
// 									value={manualServicePayload.name}
// 									variant="outlined"
// 									label="Ingrese el nombre"
// 									onChange={handleChangeName}
// 									fullWidth
// 								/>
// 							</Grid>
// 							<Grid item xs={12} sm={3}>
// 								<TextField
// 									variant="outlined"
// 									required
// 									fullWidth
// 									label="Precio de la tarifa"
// 									name="price"
// 									type="number"
// 									onChange={({ target: { value } }) => {
// 										if (regexCHDOrINFValue.test(value) && Number(value) > 0) {
// 											handleChangePrice(Number(value));
// 										} else if (value == '') handleChangePrice(0);
// 									}}
// 									value={manualServicePayload.fareCost}
// 								/>
// 							</Grid>
// 							<Grid item xs={12} sm={3}>
// 								<FormControl className={classes.formControl}>
// 									<InputLabel htmlFor="serviceType">Tipo de servicio</InputLabel>
// 									<Select
// 										label="Tipo de servicio"
// 										value={manualServicePayload.serviceType}
// 										native
// 										inputProps={{
// 											name: 'serviceType',
// 											id: 'serviceType',
// 										}}
// 										onChange={({ target: { value } }) => {
// 											handleChangeServiceType(value as serviceType);
// 										}}
// 									>
// 										<option aria-label="por pasajero" value="perPassenger">
// 											por pasajero
// 										</option>
// 										<option aria-label="por grupo" value="perGroup">
// 											por grupo
// 										</option>
// 									</Select>
// 								</FormControl>
// 							</Grid>
// 							<Grid item xs={12} sm={3}>
// 								<TextField
// 									variant="outlined"
// 									required
// 									fullWidth
// 									label="CHD Charged?"
// 									name="chdCharged"
// 									type="number"
// 									onChange={({ target: { value } }) => {
// 										if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
// 											handleChangeChildCharged(Number(value));
// 										} else if (value == '') handleChangeChildCharged(0);
// 									}}
// 									value={manualServicePayload.chdCharged}
// 								/>
// 							</Grid>
// 							<Grid item xs={12} sm={3}>
// 								<TextField
// 									variant="outlined"
// 									required
// 									fullWidth
// 									label="INF Charged?"
// 									name="infCharged"
// 									type="number"
// 									value={manualServicePayload.infCharged}
// 									onChange={({ target: { value } }) => {
// 										if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
// 											handleChangeInfCharged(Number(value));
// 										} else if (value == '') handleChangeInfCharged(0);
// 									}}
// 								/>
// 							</Grid>

// 							<Grid item xs={12}>
// 								<TextField
// 									id="outlined-multiline-static"
// 									label="Descripcion"
// 									multiline
// 									rows={8}
// 									variant="outlined"
// 									name="description"
// 									value={manualServicePayload.description}
// 									onChange={handleChangeDescription}
// 									fullWidth
// 								/>
// 							</Grid>
// 						</Grid>

// 						<Grid container direction="row-reverse">
// 							<Button
// 								variant="contained"
// 								color="primary"
// 								style={{ margin: 8 }}
// 								disabled={!isValidManualServicePayload()}
// 								onClick={handleAddManualService}
// 							>
// 								Agregar Servicio Manual
// 							</Button>
// 						</Grid>
// 					</form>
// 				</div>
// 			</div>
// 		</Container>
// 	);
// };

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Card,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RootState } from 'store';

import { ManualService, serviceType } from '../types';
import DeleteIcon from '@mui/icons-material/Delete';
import { addManualService, deleteManualService } from '../slice';
import { calculateServicePrice } from '../helpers';
import { getPAXTotals } from '../selectors';
import HelpTooltip from './HelpContent/HelpTooltip';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
}));

export const ManualServicesConfig = (): JSX.Element => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const initialManualServicePayload: ManualService = {
		fareCost: 0,
		serviceType: 'perPassenger',
		name: '',
		description: '',
		chdCharged: 1,
		infCharged: 0,
	};
	const { manualServices } = useSelector((state: RootState) => state.quotation);
	const paxTotals = useSelector(getPAXTotals);

	const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = React.useState(false);
	const [deleteManualServicePayload, setDeleteManualServicePayload] = React.useState<number>(-1);
	const [manualServicePayload, setManualServicePayload] = React.useState<ManualService>(initialManualServicePayload);

	// REGEX para validar los campos (solo números con hasta 2 decimales).
	const regexCHDOrINFValue = /^\d+(.\d{0,2})?$/;

	const handleClickOpenConfirmDelete = (index: number) => {
		setOpenDeleteConfirmDialog(true);
		setDeleteManualServicePayload(index);
	};

	const handleCloseConfirmDelete = () => setOpenDeleteConfirmDialog(false);

	const handleAddManualService = () => {
		// @ts-ignore
		dispatch(addManualService(manualServicePayload));
		setManualServicePayload(initialManualServicePayload);
	};

	const handleDeleteManualService = () => {
		setOpenDeleteConfirmDialog(false);
		// @ts-ignore
		dispatch(deleteManualService(deleteManualServicePayload));
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setManualServicePayload((prev) => ({ ...prev, name: e.target.value }));
	};

	const handleChangeChildCharged = (value: number) => {
		setManualServicePayload((prev) => ({ ...prev, chdCharged: value }));
	};

	const handleChangeInfCharged = (value: number) => {
		setManualServicePayload((prev) => ({ ...prev, infCharged: value }));
	};

	const handleChangePrice = (value: number) => {
		setManualServicePayload((prev) => ({ ...prev, fareCost: value }));
	};

	const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
		setManualServicePayload((prev) => ({ ...prev, description: e.target.value }));
	};

	const handleChangeServiceType = (value: serviceType) => {
		setManualServicePayload((prev) => ({ ...prev, serviceType: value }));
	};

	const isValidName = () => manualServicePayload.name && manualServicePayload.name.trim().length > 0;

	const isValidManualServicePayload = () => isValidName() && manualServicePayload.fareCost > 0;

	return (
		<Container component="main" maxWidth="md">
			{/* Diálogo para eliminar servicio manual */}
			<Dialog
				open={openDeleteConfirmDialog}
				onClose={handleCloseConfirmDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Eliminar el servicio manual'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						¿Confirma la eliminación del servicio manual?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleCloseConfirmDelete}>
						Cancelar
					</Button>
					<Button variant="contained" color="primary" onClick={handleDeleteManualService} autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>

			<div className={classes.paper}>
				{/* Título con estilo similar al componente ServicesConfig */}
				<Typography
					marginTop={2}
					component="h1"
					variant="h6"
					gutterBottom
					sx={{
						backgroundColor: 'primary.light',
						px: 2,
						py: 1,
						borderRadius: 2,
						color: 'white',
					}}
				>
					Servicios Manuales
					<HelpTooltip
						helpKey="manualServices"
						placement="bottom-end"
						iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
					/>
				</Typography>

				{/* Card principal que envuelve la sección */}
				<Card sx={{ borderRadius: 2, padding: 2, marginTop: 1 }}>
					{/* Si hay servicios manuales, mostramos la tabla */}
					{manualServices.length > 0 && (
						<>
							<Card sx={{ borderRadius: 2, px: 2, pb: 2, pt: 0, marginTop: 2 }} elevation={0} variant="outlined">
								<Typography variant="subtitle1" fontWeight="500" gutterBottom sx={{ mb: 2 }} marginTop={2}>
									Servicios en la cotización:
								</Typography>
								<TableContainer component={Paper}>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Nombre</TableCell>
												<TableCell>Tipo</TableCell>
												<TableCell align="right">Tarifa</TableCell>
												<TableCell align="left">Descripción</TableCell>
												<TableCell align="right">Precio ADT</TableCell>
												<TableCell align="right">Precio CHD</TableCell>
												<TableCell align="right">Precio INF</TableCell>
												<TableCell align="right" />
											</TableRow>
										</TableHead>
										<TableBody>
											{manualServices.map((option, indexOption) => {
												const servicePrices = calculateServicePrice(
													paxTotals.adults,
													paxTotals.child,
													paxTotals.infants,
													option,
												);
												return (
													<TableRow key={indexOption}>
														<TableCell component="th" scope="row">
															{option.name}
														</TableCell>
														<TableCell>{option.serviceType}</TableCell>
														<TableCell align="right">{option.fareCost}</TableCell>
														<TableCell align="left">{option.description}</TableCell>
														<TableCell align="right">{servicePrices.adtPrice}</TableCell>
														<TableCell align="right">{servicePrices.chdPrice}</TableCell>
														<TableCell align="right">{servicePrices.infPrice}</TableCell>
														<TableCell align="right">
															<IconButton
																color="secondary"
																component="span"
																onClick={() => handleClickOpenConfirmDelete(indexOption)}
																size="large"
															>
																<DeleteIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</Card>
						</>
					)}

					{/* Card adicional para el formulario de agregar servicio manual */}
					<Card sx={{ borderRadius: 2, px: 2, pb: 2, pt: 0, marginTop: 2 }} elevation={0} variant="outlined">
						<Typography variant="subtitle1" fontWeight="500" marginTop={2} gutterBottom>
							Agregar un nuevo servicio manual
						</Typography>
						<form className={classes.form}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										value={manualServicePayload.name}
										variant="outlined"
										label="Nombre del Servicio"
										onChange={handleChangeName}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Precio de la tarifa"
										name="price"
										type="number"
										onChange={({ target: { value } }) => {
											if (regexCHDOrINFValue.test(value) && Number(value) > 0) {
												handleChangePrice(Number(value));
											} else if (value === '') {
												handleChangePrice(0);
											}
										}}
										value={manualServicePayload.fareCost}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<FormControl className={classes.formControl}>
										<InputLabel htmlFor="serviceType">Tipo de servicio</InputLabel>
										<Select
											label="Tipo de servicio"
											value={manualServicePayload.serviceType}
											native
											inputProps={{
												name: 'serviceType',
												id: 'serviceType',
											}}
											onChange={({ target: { value } }) => {
												handleChangeServiceType(value as serviceType);
											}}
										>
											<option aria-label="por pasajero" value="perPassenger">
												por pasajero
											</option>
											<option aria-label="por grupo" value="perGroup">
												por grupo
											</option>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										variant="outlined"
										required
										fullWidth
										label="CHD Charged?"
										name="chdCharged"
										type="number"
										onChange={({ target: { value } }) => {
											if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
												handleChangeChildCharged(Number(value));
											} else if (value === '') {
												handleChangeChildCharged(0);
											}
										}}
										value={manualServicePayload.chdCharged}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										variant="outlined"
										required
										fullWidth
										label="INF Charged?"
										name="infCharged"
										type="number"
										value={manualServicePayload.infCharged}
										onChange={({ target: { value } }) => {
											if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
												handleChangeInfCharged(Number(value));
											} else if (value === '') {
												handleChangeInfCharged(0);
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="outlined-multiline-static"
										label="Descripción"
										multiline
										rows={4}
										variant="outlined"
										name="description"
										value={manualServicePayload.description}
										onChange={handleChangeDescription}
										fullWidth
									/>
								</Grid>
							</Grid>

							<Grid container direction="row-reverse">
								<Button
									variant="contained"
									color="primary"
									sx={{ margin: 1 }}
									disabled={!isValidManualServicePayload()}
									onClick={handleAddManualService}
								>
									Agregar Servicio Manual
								</Button>
							</Grid>
						</form>
					</Card>
				</Card>
			</div>
		</Container>
	);
};
