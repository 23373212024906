import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import qrUy from '../../../../assets/qrMasterbox.jpg';
import minturUy from '../../../../assets/uruguaynatural.png';
import whiteLogo from '../../../../assets/mevuelo-blanco.svg';
import Love from '../../../../assets/love.png';

import useBreakpoints from 'features/common/hooks/useBreakpoints';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

const WhiteTextTypography = withStyles({
	root: {
		color: '#FFFFFF',
	},
})(Typography);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
		},
		container: {
			backgroundColor: theme.palette.primary.main,
			// borderRadius: '12px',
			paddingTop: theme.spacing(4),
		},
		whiteIcon: {
			color: '#FFFFFF',
		},
		qr: {
			width: '120px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
		},
	}),
);

const MevueloUyFooter = (): JSX.Element => {
	const classes = useStyles();
	const isMediumDown = useBreakpoints().mediumDown;
	return (
		//URUGUAY
		<div className={classes.root}>
			<Container sx={{ paddingLeft: '0', paddingRight: '0' }}>
				<Box className={classes.container}>
					<WhiteTextTypography variant="h6" align="center">
						CONTACTANOS
					</WhiteTextTypography>
					<Box paddingTop={2} display="flex" justifyContent="space-evenly" flexWrap="wrap">
						<a className={classes.noUnderlineLinks} href="tel:2903 34 34">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocalPhoneIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									2903 34 34
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="mailto:hola.uy@mevuelo.com">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<EmailIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									hola.uy@mevuelo.com
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://goo.gl/maps/MLZuwfJyHD5AxYw57"
							target="_blank"
							rel="noreferrer"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocationOnIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									Río Branco 1410
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle2" align="center">
									Montevideo
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://goo.gl/maps/2BXgn1GpfhuHNRuh6"
							target="_blank"
							rel="noreferrer"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocationOnIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									World Trade Center
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle2" align="center">
									Montevideo
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<Box display="flex" justifyContent="space-around" alignItems="center" paddingTop={4} paddingBottom={6}>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.facebook.com/mevuelo.uy/"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<FacebookIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.uy
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.instagram.com/mevuelo.uy/?hl=es-la"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<InstagramIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.uy
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="http://wa.me/59897683318" target="_blank" rel="noreferrer">
							<Box display="flex" flexDirection="column" alignItems="center">
								<WhatsAppIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									097 683 318
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.linkedin.com/company/mevuelo/"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<LinkedInIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<WhiteTextTypography variant="subtitle1" align="center">
						AGENCIA DE VIAJES REGISTRADA:
					</WhiteTextTypography>
					<Box paddingTop={4} display="flex" justifyContent="space-evenly" alignItems="center">
						<Box display="flex" flexDirection="column" alignItems="center">
							<WhiteTextTypography variant="subtitle2" align="center">
								MASTERBOX S.A.
							</WhiteTextTypography>
							<WhiteTextTypography variant="subtitle2" align="center">
								Habilitación MINTUR 472
							</WhiteTextTypography>
						</Box>
						<a
							className={classes.noUnderlineLinks}
							href="http://apps.mintur.gub.uy/operadores/operador.php?tipo=10&numero=472"
							target="_blank"
							rel="noreferrer"
						>
							<img className={classes.qr} src={qrUy} alt="qr" />
						</a>
						<img className={classes.qr} src={minturUy} alt="ministerio" />
					</Box>
					{isMediumDown && (
						<Box display="flex" alignItems="center" justifyContent="center" marginTop={2}>
							<WhiteTextTypography variant="subtitle2" align="center">
								Hecho con{' '}
							</WhiteTextTypography>
							<img src={Love}></img>
							<WhiteTextTypography variant="subtitle2" align="center">
								en Uruguay
							</WhiteTextTypography>
						</Box>
					)}
					<Box display="flex" justifyContent="space-evenly" paddingTop={6} paddingBottom={24} alignItems="end">
						{!isMediumDown && (
							<Box display="flex" alignItems="center">
								<WhiteTextTypography variant="subtitle2" align="center">
									Hecho con{' '}
								</WhiteTextTypography>
								<img src={Love}></img>
								<WhiteTextTypography variant="subtitle2" align="center">
									en Uruguay
								</WhiteTextTypography>
							</Box>
						)}
						<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com" target="_blank" rel="noreferrer">
							<Box display="flex" flexDirection="column" alignItems="center">
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.com está presente en:{' '}
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle1" align="center">
									Uruguay, Paraguay, Bolivia y Colombia{' '}
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="https://mevuelo.com.uy" target="_blank" rel="noreferrer">
							<Box sx={{ paddingRight: '8px' }}>
								<img src={whiteLogo} alt="logo" />
							</Box>
						</a>
					</Box>
				</Box>
			</Container>
		</div>
	);
};
export default MevueloUyFooter;
