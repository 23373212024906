import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import qrPy from '../../../../assets/qrRealConsulting.png';
import minturPy from '../../../../assets/minturPyColor.svg';
import logo from '../../../../assets/TiendaNaranjaViajesLogo.svg';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const WhiteTextTypography = withStyles({
	root: {
		color: '#595957',
	},
})(Typography);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
		},
		container: {
			backgroundColor: '#FFFFFF',
			paddingTop: theme.spacing(4),
			color: '#595957',
		},
		whiteIcon: {
			color: '#595957',
		},
		qr: {
			width: '120px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
		},
	}),
);

const TiendaNaranjaPyFooter = (): JSX.Element => {
	const classes = useStyles();
	return (
		//PARAGUAY
		<div className={classes.root}>
			<Container sx={{ paddingLeft: '0', paddingRight: '0' }}>
				<Box className={classes.container}>
					<WhiteTextTypography variant="h6" align="center">
						CONTACTANOS:
					</WhiteTextTypography>
					<Box paddingTop={2} display="flex" justifyContent="space-evenly" flexWrap="wrap">
						<a className={classes.noUnderlineLinks} href="tel:0217290629">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocalPhoneIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									021 729 0 629 / opción 2
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="mailto:viajes@tiendanaranja.com.py">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<EmailIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									viajes@tiendanaranja.com.py
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<Box display="flex" justifyContent="space-around" alignItems="center" paddingTop={4} paddingBottom={6}>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.facebook.com/p/Tienda-Naranja-Viajes-PY-100094724016374/"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<FacebookIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									Tienda Naranja Viajes PY
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.instagram.com/tiendanaranjaviajespy/"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<InstagramIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									tiendanaranjaviajespy
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="http://wa.me/595984729300" target="_blank" rel="noreferrer">
							<Box display="flex" flexDirection="column" alignItems="center">
								<WhatsAppIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									984 729300
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<WhiteTextTypography variant="subtitle1" align="center">
						AGENCIA DE VIAJES REGISTRADA:
					</WhiteTextTypography>
					<Box paddingTop={4} display="flex" justifyContent="space-evenly" alignItems="center">
						<Box display="flex" flexDirection="column" alignItems="center">
							<WhiteTextTypography variant="subtitle2" align="center">
								REAL CONSULTING S.A.
							</WhiteTextTypography>
							<WhiteTextTypography variant="subtitle2" align="center">
								Resolución REGISTUR Nº 1263/2019
							</WhiteTextTypography>
						</Box>
						<a
							className={classes.noUnderlineLinks}
							href="https://registur.gov.py/registur/verificacion?qr=e4e4f7c8442ad196282fb79b77bd6abe"
							target="_blank"
							rel="noreferrer"
						>
							<img className={classes.qr} src={qrPy} alt="qr" />
						</a>
						<img className={classes.qr} src={minturPy} alt="ministerio" />
					</Box>
					<Box display="flex" justifyContent="space-evenly" paddingTop={6} paddingBottom={24} alignItems="end">
						<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com" target="_blank" rel="noreferrer">
							<Box display="flex" flexDirection="column" alignItems="center">
								<WhiteTextTypography variant="subtitle1" align="center">
									Tienda Naranja Viajes{' '}
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle2" align="center">
									es un proyecto de Itaú y Mevuelo.com{' '}
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com.py" target="_blank" rel="noreferrer">
							<img src={logo} alt="logo" />
						</a>
					</Box>
				</Box>
			</Container>
		</div>
	);
};
export default TiendaNaranjaPyFooter;
