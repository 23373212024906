import React from 'react';
import { Button, ButtonGroup, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded';
import { QuotationsListItem } from 'features/quotation/types';
import { formatDestinationAndNights } from '../../helpers';
import NoFlightsPicture from '../../../../assets/no-flights.png';
import { QuotationListItemRoomDistribution } from '../QuotationListItemRoomDistribution';
import DealOrCaseOpenDetail from '../../../common/components/DealOrCaseOpenDetail';

const useStyles = makeStyles((theme) => ({
	card: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	titleCard: {
		fontSize: 14,
	},
	carrierLogo: {
		height: 30,
		marginRight: 11,
	},
	arrowIcon: {
		width: 68,
		height: 36,
	},
	legBlockData: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	legBlockRow: {
		flexDirection: 'row',
		alignItems: 'center',
		display: 'flex',
	},
	airHolder: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	airImage: {
		width: '100%',
		height: 'auto',
	},
	airOtherData: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
	},
	airHolderItem1: {
		flexBasis: '40%',
		flexGrow: 1,
	},
	airHolderItem2: {
		flexBasis: '30%',
		flexGrow: 1,
	},
	cardActions: {
		flexDirection: 'row-reverse',
	},
	bottomContainer: {
		display: 'flex',
		placeContent: 'space-between',
		alignItems: 'center',
	},
}));

interface Props {
	data: QuotationsListItem;
	onClick: () => void;
	onDuplicate: () => void;
}

function QuotationListItem(props: Props): JSX.Element {
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<CardContent>
				<Grid container direction="row" spacing={2}>
					<Grid item md={4}>
						<Typography className={classes.titleCard} color="textSecondary" gutterBottom>
							{props.data.isBitrixDeal ? 'Bitrix' : 'Caso'}#:{' '}
							<DealOrCaseOpenDetail dealId={props.data.crmTicket} isBitrixDeal={props.data.isBitrixDeal} />
						</Typography>
					</Grid>
					<Grid item md={8}>
						<Grid container direction="column" alignItems="flex-end">
							<Typography variant="h5" component="h2">
								{props.data.name}
							</Typography>
							<Typography className={classes.titleCard} color="textSecondary" gutterBottom>
								Creado en {props.data.created} por {props.data.agentName}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				{props.data.air && (
					<div className={classes.airHolder}>
						<div className={`${classes.airOtherData} ${classes.airHolderItem1}`}>
							{props.data.air?.data?.map((leg, index) => (
								<div key={index}>
									<div className={classes.legBlockRow}>
										<img className={classes.carrierLogo} src={leg.airline.image} alt="carrierImg" />
										<div className={classes.legBlockData}>
											<Typography variant="body2" component="span">
												{leg.departure.airport.code}
											</Typography>
											<Typography variant="body2">{leg.departure.date}</Typography>
										</div>
										<ArrowRightAltIcon className={classes.arrowIcon} />
										<div className={classes.legBlockData}>
											<Typography variant="body2" component="span">
												{leg.arrival.airport.code}
											</Typography>
											<Typography variant="body2">{leg.arrival.date}</Typography>
										</div>
									</div>
								</div>
							))}
							{props.data.air?.images?.length > 0 && (
								<img src={props.data.air.images[0].image} className={classes.airImage} alt="imagen del vuelo" />
							)}
						</div>
						<div className={`${classes.airOtherData} ${classes.airHolderItem2}`}>
							<div>Carry on: {props.data.air.carryOn ? <CheckIcon /> : <CloseIcon />}</div>
							<div>Baggage: {props.data.air.baggage != 'none' ? <CheckIcon /> : <CloseIcon />} </div>
						</div>
						<div className={`${classes.airOtherData} ${classes.airHolderItem2}`}>
							<Typography variant="h5" component="h2">
								ADT: USD {props.data.air.fareADT}{' '}
							</Typography>
							<div>CHD: USD {props.data.air.fareCHD}</div>
							<div>INF: USD {props.data.air.fareINF}</div>
						</div>
					</div>
				)}
				{!props.data.air && (
					<Typography
						variant="h5"
						component="h2"
						color="gray"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<img style={{ maxWidth: '10%' }} src={NoFlightsPicture} alt="No Flights" />
						Sin vuelos configurados
					</Typography>
				)}
				{props.data.roomDistributions && (
					<QuotationListItemRoomDistribution
						roomDistributions={props.data.roomDistributions}
						quotationId={props.data.id}
					/>
				)}
			</CardContent>
			<div className={classes.bottomContainer}>
				{props.data.wasCopied ? <CopyrightRoundedIcon color="error" /> : <CopyrightRoundedIcon color="disabled" />}
				<Typography fontWeight="fontWeightBold" variant="body2" component="span" color="secondary">
					{formatDestinationAndNights(props.data.destinationNamesAndNights)}
				</Typography>

				<CardActions className={classes.cardActions}>
					<ButtonGroup variant="contained" color="secondary" aria-label="contained primary button group">
						<Button onClick={props.onDuplicate}>Copiar</Button>
						<Button onClick={props.onClick}>Ver</Button>
					</ButtonGroup>
				</CardActions>
			</div>
		</Card>
	);
}

export default QuotationListItem;
