import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { findQuotationToClient } from '../../services';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
	DestinationSimple,
	HotelOptionsSimple,
	QuotationSnapShotToClient,
	QuotationSnapShotToClientDestinationMultiple,
	QuotationSnapShotToClientOnlyFlights,
	QuotationType,
} from '../../features/quotation/types';
import { Header } from '../../features/quotation/components/Header';
import { AxiosResponse, AxiosError } from 'axios';
import { HotelDetail } from '../../features/hotels/types';
import { getHotelDetail } from '../../features/hotels/components/services';
import QuotationPreview from '../../features/quotation/components/QuotationPreview';
import { Alert } from '@mui/material';
import QuotationPreviewOnlyFlights from '../../features/quotation/components/QuotationOnlyFlightsPreview';
import { ErrorType, PageClientError404 } from 'features/common/components/PageClientError404';
import { getRates } from 'features/stats/services';
import { CountryCodeEnum } from 'features/common/types';
import { useDispatch } from 'react-redux';
import { actions } from 'features/auth/slice';

const returnCountry = ({
	quotationToClient,
	quotationToClientDestinationMultiple,
	quotationToClientOnlyFlights,
}: {
	quotationToClient: QuotationSnapShotToClient | null;
	quotationToClientDestinationMultiple: QuotationSnapShotToClientDestinationMultiple | null;
	quotationToClientOnlyFlights: QuotationSnapShotToClientOnlyFlights | null;
}) => {
	if (quotationToClient?.countryCode) {
		return quotationToClient?.countryCode;
	} else if (quotationToClientDestinationMultiple?.countryCode) {
		return quotationToClientDestinationMultiple?.countryCode;
	} else if (quotationToClientOnlyFlights?.countryCode) {
		return quotationToClientOnlyFlights?.countryCode;
	}
	return undefined;
};

function ClientPreview(): JSX.Element {
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(false);
	const [exchangeRate, setExchangeRate] = React.useState(Number);
	const [localCurrency, setLocalCurrency] = React.useState(String);
	const [error, setError] = React.useState('');
	const [quotationToClient, setQuotationToClient] = React.useState<QuotationSnapShotToClient | null>(null);
	const [quotationToClientDestinationMultiple, setQuotationToClientDestinationMultiple] =
		React.useState<QuotationSnapShotToClientDestinationMultiple | null>(null);
	const [quotationToClientOnlyFlights, setQuotationToClientOnlyFlights] =
		React.useState<QuotationSnapShotToClientOnlyFlights | null>(null);
	const { id } = useParams<{ id: string }>();
	const [flightIndexSelected, setFlightIndexSelected] = useState<number | null>(0);
	const [showError404, setShowError404] = useState<boolean>(false);

	const fetchDataQuotationToClient = async () => {
		try {
			setLoading(true);
			const result = await findQuotationToClient(id);
			const dateToday = new Date();
			const myMonth = dateToday.getMonth() + 1;
			const toRenderMonth = String(myMonth).length === 1 ? `0${myMonth}` : myMonth;
			const day = dateToday.getDate() < 10 ? `0${dateToday.getDate()}` : dateToday.getDate();
			const dateFormat = `${dateToday.getFullYear()}-${toRenderMonth}-${day}`;
			const quotation = result.data;
			quotation.countryCode = quotation.countryCode.toUpperCase();
			dispatch(actions.setUpdateCountry(quotation.countryCode as CountryCodeEnum));
			const response = await getRates();

			const valueRate = response.data.ratesList.find((rate) => rate.date === dateFormat);
			setExchangeRate(valueRate?.values[0] ? valueRate?.values[0].fee : 1);
			setLocalCurrency(valueRate?.values[0] ? valueRate?.values[0].coinCode : 'n/a');

			if (quotation.quotationType == QuotationType.SIMPLEDESTINATION) {
				const quotationDestinationSimple = quotation as QuotationSnapShotToClient;
				const destinations: DestinationSimple[] = [];
				for (const destination of quotationDestinationSimple.destination) {
					const promisesHotelDetail: Promise<AxiosResponse<HotelDetail>>[] = [];
					destination.hotelOptions.forEach((hotelOption) => {
						promisesHotelDetail.push(getHotelDetail(hotelOption.hotelId, hotelOption.provider));
					});
					const resultPromise = await Promise.all(promisesHotelDetail);
					const hotelOptions: HotelOptionsSimple[] = [];
					destination.hotelOptions.forEach((hotelOption, indexHotelOption) => {
						hotelOptions.push({
							...hotelOption,
							details: resultPromise[indexHotelOption].data,
						} as HotelOptionsSimple);
					});
					destinations.push({
						...destination,
						hotelOptions: hotelOptions,
					} as DestinationSimple);
				}

				const resultUpdated = {
					...quotation,
					destination: destinations,
				} as QuotationSnapShotToClient;
				setQuotationToClient(resultUpdated);
			} else if (quotation.quotationType == QuotationType.MULTIDESTINATION) {
				const resultUpdated = {
					...quotation,
				} as QuotationSnapShotToClientDestinationMultiple;
				setQuotationToClientDestinationMultiple(resultUpdated);
			} else if (quotation.quotationType == QuotationType.ONLYFLIGHTS) {
				const resultUpdated = {
					...quotation,
				} as QuotationSnapShotToClientOnlyFlights;
				setQuotationToClientOnlyFlights(resultUpdated);
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			const axiosError = error as AxiosError;
			if (axiosError.response) {
				if (axiosError.response.status === 404) {
					setShowError404(true);
				} else {
					const errorMessage =
						axiosError.response.data && axiosError.response.data.message
							? axiosError.response.data.message
							: 'No se pudo cargar la cotización';
					setError(errorMessage);
				}
			} else {
				setError('Error al conectar con el servicio');
			}
		}
	};

	useEffect(() => {
		fetchDataQuotationToClient();
	}, []);

	if (showError404) {
		return <PageClientError404 errorType={ErrorType.CLIENT_QUOTATION_NOT_FOUND} />;
	}
	return (
		<>
			<Header
				countryCode={returnCountry({
					quotationToClient,
					quotationToClientDestinationMultiple,
					quotationToClientOnlyFlights,
				})}
			/>
			<Backdrop open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{error && (
				<Alert variant="filled" severity={'error'}>
					{error}
				</Alert>
			)}
			{quotationToClient != null && (
				<QuotationPreview
					exchangeRate={exchangeRate}
					localCurency={localCurrency}
					showLocalCurrency={quotationToClient?.showLocalCurrency}
					flightIndexSelected={flightIndexSelected}
					showFlightSelectionControl={quotationToClient.airs.length > 1}
					handleFlightSelectionChange={setFlightIndexSelected}
					airs={quotationToClient.airs}
					isMultiDestination={false}
					containerMultiDestination={null}
					description={quotationToClient?.description}
					destinationsForQuotationSimpleDestination={quotationToClient.destination}
					manualServices={quotationToClient.manualServices}
					notIncludes={quotationToClient.notIncludes}
					paxTotals={quotationToClient.paxTotal}
					countryCode={quotationToClient.countryCode}
					quotationId={quotationToClient.id}
				/>
			)}

			{quotationToClientDestinationMultiple != null && (
				<QuotationPreview
					exchangeRate={exchangeRate}
					localCurency={localCurrency}
					showLocalCurrency={quotationToClientDestinationMultiple?.showLocalCurrency}
					flightIndexSelected={flightIndexSelected}
					showFlightSelectionControl={quotationToClientDestinationMultiple.airs.length > 1}
					handleFlightSelectionChange={setFlightIndexSelected}
					airs={quotationToClientDestinationMultiple.airs}
					isMultiDestination={true}
					containerMultiDestination={quotationToClientDestinationMultiple.containerDestinationMultiple}
					description={quotationToClientDestinationMultiple?.description}
					destinationsForQuotationSimpleDestination={null}
					manualServices={quotationToClientDestinationMultiple.manualServices}
					notIncludes={quotationToClientDestinationMultiple.notIncludes}
					paxTotals={quotationToClientDestinationMultiple.paxTotal}
					countryCode={quotationToClientDestinationMultiple.countryCode}
					quotationId={quotationToClientDestinationMultiple.id}
				/>
			)}

			{quotationToClientOnlyFlights != null && (
				<QuotationPreviewOnlyFlights
					exchangeRate={exchangeRate}
					localCurrency={localCurrency}
					showLocalCurrency={quotationToClientOnlyFlights?.showLocalCurrency}
					description={quotationToClientOnlyFlights.description}
					passengerConfig={quotationToClientOnlyFlights.passengerConfig}
					destinations={quotationToClientOnlyFlights.destination}
					notIncludes={quotationToClientOnlyFlights.notIncludes}
					countryCode={quotationToClientOnlyFlights.countryCode}
					flightIndexSelected={flightIndexSelected}
					showFlightSelectionControl={quotationToClientOnlyFlights.airs.length > 1}
					handleFlightSelectionChange={setFlightIndexSelected}
					flightFinalPriceSummaries={quotationToClientOnlyFlights.flightFinalPriceSummaries}
					airs={quotationToClientOnlyFlights.airs}
				/>
			)}
		</>
	);
}

export default ClientPreview;
