import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	Grid,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeOptions } from 'constants/constants';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	orderTypes,
	SelectFilterModelSimple,
} from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import { getGatewayDocumentTypeName, getStatusNameTransactionInGateway } from 'features/transactions/helpers';
import { getGatewayTransactionList, getGatewayTransactionListToExport } from 'features/transactions/services';
import { gatewayDocumentTypeList, gatewayStatusList, IGatewayTransaction } from 'features/transactions/types';
import React, { useEffect, useState } from 'react';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { putFilterInProfile } from '../../../common/services';
import { PageNoResult } from '../../../common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const headerCells: IHeadCell[] = [
	{
		field: 'orderId',
		sortable: true,
		headerName: 'Orden',
	},
	{
		field: 'crmTicket',
		sortable: true,
		headerName: 'Deal o Caso',
	},
	{
		field: 'isBitrixDeal',
		sortable: true,
		headerName: 'Es un Deal',
	},
	{
		field: 'file',
		sortable: true,
		headerName: 'File',
	},
	{
		field: 'id',
		sortable: true,
		headerName: 'ID de Transacción',
	},
	{
		field: 'invoiceNumber',
		sortable: true,
		headerName: 'Número de Factura',
	},
	{
		field: 'fee',
		sortable: true,
		headerName: 'Cuota',
	},
	{
		field: 'amount',
		sortable: true,
		headerName: 'Monto',
	},
	{
		field: 'coinCode',
		sortable: true,
		headerName: 'Moneda',
	},
	{
		field: 'name',
		sortable: true,
		headerName: 'Nombre',
	},
	{
		field: 'lastName',
		sortable: true,
		headerName: 'Apellidos',
	},
	{
		field: 'email',
		sortable: true,
		headerName: 'Correo Electrónico',
	},
	{
		field: 'document',
		sortable: true,
		headerName: 'Documento',
	},
	{
		field: 'documentType',
		sortable: true,
		headerName: 'Tipo de Documento',
	},
	{
		field: 'phone',
		sortable: true,
		headerName: 'Teléfono',
	},
	{
		field: 'status',
		sortable: true,
		headerName: 'Estado',
	},
	{
		field: 'approvalCode',
		sortable: true,
		headerName: 'Código de Aprobación',
	},
	{
		field: 'cardLastFourDigit',
		sortable: true,
		headerName: 'Últimos 4 Dígitos de Tarjeta',
	},
	{
		field: 'cardExpiration',
		sortable: true,
		headerName: 'Vencimiento de Tarjeta',
	},
	{
		field: 'changedAt',
		sortable: true,
		headerName: 'Fecha de Modificación',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de Creación',
	},
];

export interface GatewayTransactionListProps {
	orderTypeId: number;
	profileFilters?: FilterModel[];
	profileSorts?: ISort[];
}

export const GatewayTransactionList = ({
	orderTypeId,
	profileFilters,
	profileSorts,
}: GatewayTransactionListProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [transactionsList, setTransactionsList] = useState<IGatewayTransaction[] | null>(null);
	const [sortFields, setSortFields] = useState<ISort[]>(
		profileSorts ? [...profileSorts] : [{ field: 'createdAt', order: 'desc' }],
	);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>(profileFilters ? [...profileFilters] : []);
	const nameOfOrderType = orderTypes.find((orderType) => orderType.id === +orderTypeId)?.name || '';

	const { auth } = useSelector((state: RootState) => state);

	const userId = auth.user?.id;
	const apiKey = auth.apiKey;
	const countryCode = auth.country;
	const profileTag = 'gateway-transaction-list-tag';

	const filters = [
		{
			label: 'Monto de Transacción',
			type: FilterType.NUMERIC,
			key: 'amountFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Monto de Transacción',
			type: FilterType.NUMERIC,
			key: 'amountTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Fecha de Creación', type: FilterType.DATE, key: 'createdAt' },
		new SelectFilterModelSimple('Estado', 'status', gatewayStatusList),
		{ label: 'ID de Transacción', type: FilterType.NUMERIC, key: 'id' },
		{ label: 'Orden', type: FilterType.STRING, key: 'orderId' },
		{
			label: 'Deal o Caso',
			type: FilterType.NUMERIC,
			key: 'crmTicket',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Es un Deal',
			type: FilterType.BOOLEAN,
			key: 'isBitrixDeal',
			operator: OperatorFilterEnum.EQUALS,
		},
		{ label: 'File', type: FilterType.STRING, key: 'file', operator: OperatorFilterEnum.EQUALS },
		new DateToFromFilterModel('La Fecha de Creación', 'createdAtFrom', 'createdAtTo'),
		{
			label: 'Número de Factura',
			type: FilterType.NUMERIC,
			key: 'invoiceNumber',
			operator: OperatorFilterEnum.EQUALS,
		},
		{ label: 'Cuotas', type: FilterType.NUMERIC, key: 'fee', operator: OperatorFilterEnum.EQUALS },
		{
			label: 'Monto de Transacción',
			type: FilterType.NUMERIC,
			key: 'amount',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Moneda',
			type: FilterType.STRING,
			key: 'coinCode',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Nombre',
			type: FilterType.STRING,
			key: 'name',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'Apellido',
			type: FilterType.STRING,
			key: 'lastName',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'E-Mail',
			type: FilterType.STRING,
			key: 'email',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'Documento',
			type: FilterType.STRING,
			key: 'email',
			operator: OperatorFilterEnum.CONTAINS,
		},
		new SelectFilterModelSimple('Tipo de Documento', 'documentType', gatewayDocumentTypeList),
		{
			label: 'Teléfono',
			type: FilterType.STRING,
			key: 'phone',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'Código de Aprobación',
			type: FilterType.NUMERIC,
			key: 'approvalCode',
			operator: OperatorFilterEnum.EQUALS,
		},
		{ label: 'Últimos 4 Dígitos de Tarjeta', type: FilterType.STRING, key: 'cardLastFourDigit' },
		{ label: 'Vencimiento de Tarjeta', type: FilterType.STRING, key: 'cardExpiration' },
		{ label: 'Fecha de Modificación', type: FilterType.DATE, key: 'changedAt' },
		new DateToFromFilterModel('La Fecha de Modificación', 'changedAtFrom', 'changedAtTo'),
	];

	const getTransactionListToClient = async () => {
		try {
			setLoading(true);
			await putFilterInProfile(profileTag, apiKey, countryCode, sortFields, filterApplied, userId?.toString());
			const envelopedTransactionList = (
				await getGatewayTransactionList(
					orderTypeId,
					currentPage - 1,
					currentSize,
					sortFields,
					convertFiltersToRecord(filterApplied),
				)
			).data;
			setPageTotal(getEnvelopedListPageTotal(envelopedTransactionList));
			setTransactionsList(envelopedTransactionList.data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getTransactionListToClient();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const sortableFields = getSortableFields(headerCells);

	const transformValue = (key: string, value: any, row: IGatewayTransaction) => {
		switch (key) {
			case 'file':
				return row?.file ? row.file : '-';
			case 'isBitrixDeal':
				return row.isBitrixDeal ? 'Si' : 'No';
			case 'fee':
				return row?.fee ? row.fee : '-';
			case 'createdAt':
				return formatDateTimeToShowUser(value);
			case 'changedAt':
				return formatDateTimeToShowUser(row.changeAt);
			case 'email':
				return row.email ? row.email : '-';
			case 'document':
				return row.document ? row.document : '-';
			case 'phone':
				return row.phone ? row.phone : '-';
			case 'approvalCode':
				return row.approvalCode ? row.approvalCode : '-';
			case 'cardLastFourDigit':
				return row.cardLastFourDigit ? row.cardLastFourDigit : '-';
			case 'cardExpiration':
				return row.cardExpiration ? row.cardExpiration : '-';
			case 'documentType':
				return row.documentType ? getGatewayDocumentTypeName(row.documentType) : '-';
			case 'status':
				return getStatusNameTransactionInGateway(row.status);

			default:
				return value;
		}
	};

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const title = `Listado de Transacciones de ${nameOfOrderType}`;
			const envelopedTransactionList = (
				await getGatewayTransactionListToExport(orderTypeId, sortFields, convertFiltersToRecord(filterApplied))
			).data;
			const gatewayTransactions = envelopedTransactionList.data;
			const optionsToExport = {
				title: title,
				headers: headerCells,
				widthColumns: [40, 15, 20, 20, 20, 20, 10, 15, 10, 20, 20, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20],
				filename: title,
			} as ExportExcelOptions;
			exportToExcel(gatewayTransactions, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid filters={filters} handleAppliedFilters={handleApplyFilters} preAppliedFilters={filterApplied} />
					</Grid>
					<Grid item xs={1}>
						<ExportToExcelButton disabled={transactionsList?.length == 0} handleExportToExcel={handleExportToExcel} />
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && transactionsList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									transactionsList?.map((transaction) => (
										<TableRow key={transaction.id} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell align="right" component="p">
												{transaction.orderId}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.crmTicket}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('isBitrixDeal', transaction.isBitrixDeal, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction?.file ? transaction.file : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.id}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.invoiceNumber}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('fee', transaction.fee, transaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.amount}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.coinCode}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.name}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.lastName}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.email ? transaction.email : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.document ? transaction.document : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.documentType ? getGatewayDocumentTypeName(transaction.documentType) : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.phone ? transaction.phone : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{getStatusNameTransactionInGateway(transaction.status)}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.approvalCode ? transaction.approvalCode : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.cardLastFourDigit ? transaction.cardLastFourDigit : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.cardExpiration ? transaction.cardExpiration : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{formatDateTimeToShowUser(transaction.changeAt)}
											</TableCell>
											<TableCell align="right" component="p">
												{formatDateTimeToShowUser(transaction.createdAt)}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} textAlign="center" marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
