export default {
	airParser: 'api/quotations/flights/converters/tst',
	destinationSuggestion: 'api/quotations/places/suggestions/',
	optionalServicesByDestination: 'api/quotations/places/[cityCode]/optionalservices',
	hotelSuggestion: 'api/quotations/accommodations/hotels/[cityCode]/suggestions/[queryText]',
	servicesSuggestion: 'api/quotations/services/suggestions',
	quotationToClient: 'api/quotations/toclient',
	autocompleteContact: 'api/quotations/crm/[dealId]/contact',
	contactsByDealId: 'api/quotations/crm/[dealId]/contacts',
	paymentOrders: 'api/quotations/orders',
	paymentOrdersVN: '/api/viaje-naranja/orders',
	performTransaction: 'api/quotations/transactions',
	linkToClient: 'api/quotations/orders/linktoclient',
	linkToClientVN: '/api/viaje-naranja/orders/linktoclient',
	transactionSummary: 'api/quotations/transactions/summary',
	transactionList: 'api/quotations/transactions',
	profileFilters: '/v1/mevuelo/backend/users/profiles',
	validatingCarriers: 'api/quotations/flights/validatingcarriers',
	chargedAgainstOptions: 'api/quotations/chargedagainstoptions',
	operators: 'api/quotations/crosses/operators',
	transactions: 'api/quotations/transactions',
	authorizations: 'api/quotations/authorizations',
	crosses: 'api/quotations/crosses',
	unbindCrossEticket: 'api/quotations/crosses/unbind',
	transactionInGatewayBase: '/api/quotations/transactionsingateway',
	transactionInGatewaySiemprePago: '/api/quotations/transactionsingateway/siemprepago',
	checkConfirmationPaymentBancard: '/api/quotations/transactionsingateway/bancard/checkconfirm/{shopProcessId}',
	checkConfirmationPaymentAtc: '/api/quotations/transactionsingateway/atc/checkconfirm/{reference}',
	checkConfirmationPaymentWompi: '/api/quotations/transactionsingateway/wompi/checkconfirm/:reference',
	transactionInGatewayBancard: '/api/quotations/transactionsingateway/bancard',
	transactionWompi: '/api/quotations/transactionsingateway/wompi',
	transactionInGatewayAtc: '/api/quotations/transactionsingateway/atc',
	newETicket: 'api/quotations/crosses/eticket',
	locateClient: '/v1/mevuelo/frontend/localize',
	countries: '/v1/mevuelo/frontend/places/countries',
	airports: '/v1/mevuelo/frontend/places/airports',
	packageCompositeSuggestions: '/v1/mevuelo/backend/packages/composites/suggestions/',
	packageCompositeDetail: '/v1/mevuelo/backend/packages/composites/:id/detailforpower',
	automaticRouteFarePrice: '/api/quotations/automaticroutes/:id/allprices',
	automaticRouteFarePriceInfoForGraphics: '/api/quotations/automaticroutes/:id/infoforgraphics',
	automaticRouteFarePriceSegments: '/api/quotations/automaticroutes/segments',
	automaticRouteFarePriceAmadeusCommands: '/api/quotations/automaticroutes/amadeuscommands',
	automaticRouteSuggestions: '/api/quotations/automaticroutes/suggestions/',
	retrievePackagesPrices:
		'/v1/mevuelo/backend/packages/composites/[packageId]/pricesforpower?travelFrom=[startDate]&travelTo=[endDate]',
	retrievePackagesDetails: 'v1/mevuelo/backend/packages/composites',
	ratesList: '/v1/mevuelo/backend/dashboards/rates',
	checkWireTransfer: '/api/quotations/wiretransfers/check',
	wireTransferCheck: '/api/quotations/wiretransfers/check',
	wireTransferList: '/api/quotations/wiretransfers/list',
	wireTransferBanks: '/api/quotations/wiretransfers/banks',
	wireTransferCurrencies: '/api/quotations/wiretransfers/currencies',
	wireTransferProviders: '/api/quotations/wiretransfers/providers',
	wireTransferAddReference: '/api/quotations/wiretransfers/reference',
	wireTransferCurrentBalanceByCountries: '/api/quotations/wiretransfers/currentbalance/bycountries',
	wireTransferCurrentBalanceInBankByCountries: '/api/quotations/wiretransfers/currentbalance/inbanksbycountries',
	wireTransferRefresh: '/api/quotations/wiretransfers/refresh',
	wireTransferChangeObservations: '/api/quotations/wiretransfers/observations',
	cashTransaction: '/api/quotations/cashtransactions',
	viajeNaranjaBancardTransactionList: '/api/viaje-naranja/transactions/bancard',
	viajeNaranjaItauPointTransactionList: '/api/viaje-naranja/transactions/itaupoints',
	viajeNaranjaReturnItauPoints: '/api/viaje-naranja/transactions/itaupoints/return',
	activityLogList: '/api/activitylogs',
	getAirlines: '/v1/mevuelo/frontend/airlines?page=-1',
	getNimbuxCaseSummary: '/api/quotations/nimbux/case-summary-to-power/:caseId',
};
