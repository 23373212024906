// AirConfigCompleteQuotation.tsx

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, CssBaseline, Divider, FormControlLabel, Switch, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AirDisplay } from './AirDisplay';
import { addAirConfig, deleteAirConfig, deleteAllAirsConfig, updateAirConfig } from '../../slice';
import { Air } from '../../types';
import { RootState } from '../../../../store';
import { FlightFormConfig } from './FlightFormConfig';
import HelpTooltip from '../HelpContent/HelpTooltip';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	configureFlight: {
		float: 'right',
		color: 'white',
	},
}));

export interface IDefaultPriceConfig {
	adtPrice: number;
	chdPrice: number;
	infPrice: number;
}

export interface Props {
	showNotConfigureFlightControl: boolean;
	showSelectionControl?: boolean;
	indexSelected?: number | null;
	handleSelectionChange?: (index: number | null) => void;
	maxFlightsAllowed?: number;
	defaultPriceConfig?: IDefaultPriceConfig;
	handleChangeConfigureFlights?: (value: boolean) => void;
}

export default function AirConfigCompleteQuotation({
	showNotConfigureFlightControl,
	showSelectionControl,
	indexSelected,
	handleSelectionChange,
	maxFlightsAllowed,
	defaultPriceConfig,
	handleChangeConfigureFlights,
}: Props): JSX.Element {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [editIndex, setEditIndex] = useState<number | null>(null); // Estado para el índice de edición

	const { airs, id } = useSelector((state: RootState) => state.quotation);

	// Si ya hay airs y un id > 0, o si id == 0 (nueva cotización),
	// iniciamos configureFlight en true. Caso contrario, false.
	const [configureFlight, setConfigureFlight] = useState<boolean>((airs.length > 0 && id > 0) || id === 0);

	const handleDeleteAirConfig = (index: number) => {
		dispatch(deleteAirConfig(index));
	};

	const handleAddAir = (air: Air) => {
		dispatch(addAirConfig(air));
	};

	const handleUpdateAir = (air: Air) => {
		if (editIndex !== null) {
			dispatch(updateAirConfig({ index: editIndex, air }));
			setEditIndex(null); // Finaliza el modo de edición
		}
	};

	const handleConfigureFlight = () => {
		setConfigureFlight((prevState) => !prevState);
		if (configureFlight) {
			// Si se desactiva la configuración, finaliza cualquier edición en curso
			setEditIndex(null);
		}
	};

	useEffect(() => {
		// Si se desactiva "Configurar vuelos", borramos todos
		// los flights de la cotización
		if (!configureFlight) {
			dispatch(deleteAllAirsConfig());
		}
		// Notificamos al padre (si corresponde)
		handleChangeConfigureFlights?.(configureFlight);
	}, [configureFlight]);

	return useMemo(() => {
		return (
			<Container component="main" maxWidth="md">
				<CssBaseline />

				{/* Título “Vuelos” con estilo */}
				<Typography
					marginTop={2}
					component="h1"
					variant="h6"
					gutterBottom
					sx={{
						backgroundColor: 'primary.light',
						px: 2,
						py: 1,
						borderRadius: 2,
						color: 'white',
					}}
				>
					Configuración de vuelos:
					<HelpTooltip
						helpKey="flightsConfig"
						placement="bottom-end"
						iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
					/>
					{showNotConfigureFlightControl && (
						<FormControlLabel
							className={classes.configureFlight}
							control={
								<Switch
									color="success"
									checked={configureFlight}
									name="configureFlight"
									onChange={handleConfigureFlight}
								/>
							}
							label="Activo"
						/>
					)}
				</Typography>

				<Card sx={{ borderRadius: 2, p: 2, mt: 1 }}>
					{/* Si NO estamos configurando, no mostramos nada más */}
					{!configureFlight && (
						<Typography variant="subtitle1" gutterBottom>
							No se configuran vuelos para esta cotización
						</Typography>
					)}

					{configureFlight && (
						<>
							{/* Sección donde mostramos los vuelos que ya están en la cotización */}
							{airs.length > 0 ? (
								<>
									<Typography variant="subtitle1" fontWeight="500" gutterBottom>
										Vuelos en la cotización:
									</Typography>

									{airs.map((air, index) => (
										<Box key={index} mb={2} mt={1}>
											{/* Mostrar el vuelo en sí */}
											<AirDisplay
												data={air}
												previewMode={false}
												index={index}
												handleDeleteFlight={handleDeleteAirConfig}
												handleSelectionChange={handleSelectionChange}
												showSelectionControl={showSelectionControl}
												indexSelected={indexSelected}
												onEdit={() => setEditIndex(index)} // Pasar la función de edición
											/>
										</Box>
									))}

									{/* Divider y encabezado para “Agregar nuevo vuelo” */}
									<Divider sx={{ my: 2 }} />
									<Typography variant="subtitle1" fontWeight="500" gutterBottom>
										{editIndex !== null ? 'Editando vuelo:' : 'Agregar nuevo vuelo:'}
									</Typography>
								</>
							) : (
								// Si no hay vuelos, invitamos a agregar el primero
								<Typography variant="subtitle1" fontWeight="500" gutterBottom>
									Aún no hay vuelos, ¡agrega uno para comenzar!
								</Typography>
							)}

							{/* Mostrar formulario de edición si se está editando un vuelo */}
							{editIndex !== null ? (
								<FlightFormConfig
									defaultPriceConfig={defaultPriceConfig}
									handleAddAir={handleUpdateAir}
									initialData={airs[editIndex]}
									isEditing
								/>
							) : (
								// Mostrar formulario para agregar nuevo vuelo si no se está editando
								airs.length < (maxFlightsAllowed || 3) && (
									<FlightFormConfig defaultPriceConfig={defaultPriceConfig} handleAddAir={handleAddAir} />
								)
							)}
						</>
					)}
				</Card>
			</Container>
		);
	}, [
		airs,
		configureFlight,
		indexSelected,
		showSelectionControl,
		maxFlightsAllowed,
		defaultPriceConfig,
		editIndex, // Agregar editIndex como dependencia
	]);
}
