import React from 'react';
import { Link, Tooltip } from '@mui/material';
import { getDetailOfDealOrCaseInCRM } from '../../helpers';

interface Props {
	dealId: number;
	isBitrixDeal: boolean;
}

const DealOrCaseOpenDetail: React.FC<Props> = (props: Props) => {
	const { dealId, isBitrixDeal } = props;
	const title = `Clic para abrir el ${isBitrixDeal ? 'deal de B24' : 'Caso'} en otra pestaña`;
	return (
		<Tooltip title={title}>
			<Link href={`${getDetailOfDealOrCaseInCRM(dealId, isBitrixDeal)}`} target="_blank" rel="noopener noreferrer">
				{dealId}
			</Link>
		</Tooltip>
	);
};

export default DealOrCaseOpenDetail;
