import { ApiService, ApiTravelInfoService } from 'services';
import { AxiosResponse } from 'axios';
import {
	AccommodationFaresByRoom,
	AccommodationFaresRequest,
	AccommodationFaresResponse,
	AccommodationFaresRoomPrice,
	DestinationList,
	GenerateTravelInfoRequest,
	GenerateTravelInfoResponse,
	HotelOptions,
	IHotelMostQuoted,
	InputCopyQuotation,
	IQuotationSaleReportList,
	Quotation,
	QuotationDefaultDescription,
	QuotationDefaultNotInclude,
	QuotationFlightInfoFromAutomaticRoute,
	QuotationOnlyFlights,
	QuotationRegion,
	QuotationRoomDistributionFaresRequest,
	QuotationsListItem,
	RoomBaseConfig,
	RoomFare,
	roomRateType,
	roomServiceType,
} from './types';
import { IEnvelopeList, ISort } from 'features/common/types';
import { ApiResponse } from 'features/common/types';
import { formatSortingFields } from '../common/helpers';
import { getHotelDetail } from '../../features/hotels/components/services';
import {
	compareDistributionAndRoomDistribution,
	findCustomBoard,
	findCustomRate,
	getAccommodationFaresByDistribution,
} from './helpers';

const endpoints = {
	new: 'api/quotations',
	newOnlyFlights: 'api/quotations/onlyflights',
	edit: 'api/quotations/[id]',
	editOnlyFlights: 'api/quotations/[id]/onlyflights',
	list: 'api/quotations/list?envelope=true',
	get: 'api/quotations/[id]',
	getOnlyFlights: 'api/quotations/[id]/onlyflights',
	getDestinations: 'api/quotations/places/destinations',
	getRegions: 'api/quotations/places/regions',
	getDefaultOrigin: 'api/quotations/defaultorigin',
	copy: 'api/quotations/copy',
	defaultDescription: 'api/quotations/defaultdescriptions',
	defaultNotIncludes: 'api/quotations/defaultnotincludes',
	flightInfoFromAutomaticRoute: 'api/quotations/automaticroutes/flightinfo',
	flightInfoFromGDS: 'api/quotations/automaticroutes/flightinfoinmptb',
	hotelsMostQuoted: 'api/quotations/hotelmostquoted/[destinationCode]',
	hotelsFares: 'api/quotations/accommodations/fares',
	salesReport: 'api/quotations/salesreport',
	generateTravelInfo: '/api/generate-travel-info',
};

export const postQuotation = (payload: Quotation): Promise<AxiosResponse<{ id: string }>> =>
	ApiService.post(endpoints.new, payload);

export const postQuotationOnlyFlights = (payload: QuotationOnlyFlights): Promise<AxiosResponse<{ id: string }>> =>
	ApiService.post(endpoints.newOnlyFlights, payload);

export const putQuotation = (payload: Quotation): Promise<AxiosResponse<Quotation>> =>
	ApiService.put(endpoints.edit.replace('[id]', String(payload.id)), payload);

export const putQuotationOnlyFlights = (payload: QuotationOnlyFlights): Promise<AxiosResponse<QuotationOnlyFlights>> =>
	ApiService.put(endpoints.editOnlyFlights.replace('[id]', String(payload.id)), payload);

export const getQuotation = (id: number): Promise<AxiosResponse<Quotation>> =>
	ApiService.get(endpoints.get.replace('[id]', String(id)));

export const getQuotationOnlyFlights = (id: number): Promise<AxiosResponse<QuotationOnlyFlights>> =>
	ApiService.get(endpoints.getOnlyFlights.replace('[id]', String(id)));

export const getQuotationDefaultDescription = (): Promise<AxiosResponse<QuotationDefaultDescription>> =>
	ApiService.get(endpoints.defaultDescription);

export const getQuotationDefaultNotIncludesList = (): Promise<AxiosResponse<QuotationDefaultNotInclude[]>> =>
	ApiService.get(endpoints.defaultNotIncludes);

export const postDefaultNotIncludes = (data: QuotationDefaultNotInclude): Promise<AxiosResponse> =>
	ApiService.post(endpoints.defaultNotIncludes, data);

export const deleteDefaultNotIncludes = (id: number): Promise<AxiosResponse> =>
	ApiService.delete(`${endpoints.defaultNotIncludes}/${id}`);

export const putDefaultNotIncludes = (data: QuotationDefaultNotInclude): Promise<AxiosResponse> =>
	ApiService.put(`${endpoints.defaultNotIncludes}/${data.id}`, data);

export const putQuotationDefaultDescription = (newDescription: QuotationDefaultDescription): Promise<AxiosResponse> =>
	ApiService.put(endpoints.defaultDescription, newDescription);

export const copyQuotation = (payload: InputCopyQuotation): Promise<AxiosResponse<number>> =>
	ApiService.post(endpoints.copy, payload);

export const listQuotation = (
	page = 0,
	filter: { [p: string]: any },
): Promise<AxiosResponse<ApiResponse<QuotationsListItem[]>>> => {
	const queryParams = Object.assign({ page, sort: '-changed' });
	return ApiService.post(`${endpoints.list}`, filter, { params: queryParams });
};

export const getDestinations = (): Promise<AxiosResponse<DestinationList[]>> =>
	ApiService.get(endpoints.getDestinations);
export const getRegions = (): Promise<AxiosResponse<QuotationRegion[]>> => ApiService.get(endpoints.getRegions);
export const getDefaultOrigin = (): Promise<AxiosResponse<string>> => ApiService.get(endpoints.getDefaultOrigin);

export const getFlightInfoFromAutomaticRoute = (
	automaticRouteId: number,
	travelDate: string,
): Promise<AxiosResponse<QuotationFlightInfoFromAutomaticRoute>> =>
	ApiService.get(endpoints.flightInfoFromAutomaticRoute, {
		params: { routeId: automaticRouteId, travelDate },
	});
export const getFlightInfoFromGDS = (
	automaticRouteId: number,
	travelDate: string,
): Promise<AxiosResponse<QuotationFlightInfoFromAutomaticRoute>> =>
	ApiService.get(endpoints.flightInfoFromGDS, {
		params: { routeId: automaticRouteId, travelDate },
	});
export const getHotelMostQuotedByDestination = (destinationCode: string): Promise<AxiosResponse<IHotelMostQuoted[]>> =>
	ApiService.get(endpoints.hotelsMostQuoted.replace('[destinationCode]', destinationCode));

export const getSalesReportList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IQuotationSaleReportList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.salesReport, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getSalesReportListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IQuotationSaleReportList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.salesReport, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getHotelFares = (request: AccommodationFaresRequest): Promise<AxiosResponse<AccommodationFaresResponse>> =>
	ApiService.post(endpoints.hotelsFares, request);

const processFaresByDistribution = (
	roomBaseConfig: RoomBaseConfig[],
	distributions: AccommodationFaresRequest['distributions'],
	hotelFaresResponse: AccommodationFaresResponse,
	faresByDistribution: AccommodationFaresResponse['faresByDistribution'],
	selectedBoardCode: string,
	selectedCancellationPolicy: string,
): RoomFare[] | null => {
	if (distributions.length !== roomBaseConfig.length) return null;
	const roomFares: RoomFare[] = [];
	roomBaseConfig.forEach((room, index) => {
		const faresByDistribution = getAccommodationFaresByDistribution(
			hotelFaresResponse.faresByDistribution,
			distributions,
		);
		const faresByRoom: AccommodationFaresByRoom[] =
			faresByDistribution.find((fareByDistribution) =>
				compareDistributionAndRoomDistribution(distributions[index], fareByDistribution),
			)?.faresByRoom || [];
		let allFaresByRoom: AccommodationFaresRoomPrice[] = [];
		faresByRoom.forEach((fareByRoom) => {
			allFaresByRoom = allFaresByRoom.concat(fareByRoom.fares);
		});

		const allFareByRoomsApplyBoardsAndCancellationPolicy = allFaresByRoom.filter((fareByRoom) => {
			const applyBoardSelected = selectedBoardCode == findCustomBoard(fareByRoom.board)?.code;
			const applyCancellationPolicySelected =
				selectedCancellationPolicy === 'all' ||
				selectedCancellationPolicy == findCustomRate(fareByRoom.cancellationPolicy)?.code;
			return applyBoardSelected && applyCancellationPolicySelected;
		});

		const faresByRoomApplyBoardsSortedByPrice = allFareByRoomsApplyBoardsAndCancellationPolicy.sort(
			(n1, n2) => n1.fare - n2.fare,
		);
		if (faresByRoomApplyBoardsSortedByPrice.length > 0) {
			const fareForRoom = faresByRoomApplyBoardsSortedByPrice[0];
			const customBoard = findCustomBoard(fareForRoom.board);
			const customRate = findCustomRate(fareForRoom.cancellationPolicy);
			roomFares.push({
				price: fareForRoom.fare,
				board: (customBoard ? customBoard.code : '') as roomServiceType,
				rateType: (customRate ? customRate.code : '') as roomRateType,
			} as RoomFare as RoomFare);
		}
	});
	if (roomFares.length === roomBaseConfig.length) {
		return roomFares;
	} else return null;
};

export const getHotelProcessedFares = async (
	roomBaseConfig: RoomBaseConfig[],
	request: AccommodationFaresRequest,
	selectedBoardCode: string,
	selectedCancellationPolicy: string,
): Promise<RoomFare[] | null> => {
	const hotelsFares = await getHotelFares(request);

	const processedFares = processFaresByDistribution(
		roomBaseConfig,
		request.distributions,
		hotelsFares.data,
		hotelsFares.data.faresByDistribution,
		selectedBoardCode,
		selectedCancellationPolicy,
	);

	if (!processedFares) return null;
	return processedFares;
};

export const getHotelsWithBestFares = async (
	cityCode: string,
	checkIn: string,
	checkOut: string,
	roomBaseConfig: RoomBaseConfig[],
	selectedBoardCode: string,
	selectedCancellationPolicy: string,
): Promise<HotelOptions[]> => {
	const { data: mostQuotedHotels } = await getHotelMostQuotedByDestination(cityCode);

	const distributions: QuotationRoomDistributionFaresRequest[] = roomBaseConfig.map((room) => ({
		adults: room.adults,
		childrenAges: (room.childs || []).concat(room.infants || []),
	}));
	const hotelPromises = mostQuotedHotels.map(async (hotel) => {
		try {
			const request: AccommodationFaresRequest = {
				hotelId: hotel.hotelId,
				provider: hotel.hotelProvider,
				distributions: distributions,
				checkIn,
				checkOut,
			};

			const processedFares = await getHotelProcessedFares(
				roomBaseConfig,
				request,
				selectedBoardCode,
				selectedCancellationPolicy,
			);
			if (!processedFares) return null;

			const { data: hotelDetails } = await getHotelDetail(hotel.hotelId, hotel.hotelProvider);

			return {
				hotelId: hotelDetails.travelonuxId,
				provider: hotel.hotelProvider,
				hotelName: hotelDetails.name,
				chdCharged: 0,
				infCharged: 0,
				rooms: processedFares,
				details: hotelDetails,
			} as HotelOptions;
		} catch (error) {
			console.error(`Error processing hotel ${hotel.hotelId}:`, error);
			return null;
		}
	});

	const results = await Promise.all(hotelPromises);
	return results.filter(Boolean) as HotelOptions[];
};

export async function generateTravelInfo(payload: GenerateTravelInfoRequest): Promise<GenerateTravelInfoResponse> {
	try {
		const response = await ApiTravelInfoService.post(endpoints.generateTravelInfo, payload);
		return response.data;
	} catch (error) {
		throw new Error('Error al generar info de viaje');
	}
}
