import React from 'react';
import { Card, Container, CssBaseline, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GeneralConfigForm from './GeneralConfigForm';
import HelpTooltip from '../HelpContent/HelpTooltip';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface Props {
	onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCRMTicketChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onIsBitrixDealChange: (value: boolean) => void;
	onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onConfirmAutoCompleteDataByDeal: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	crmTicket: number;
	isBitrixDeal: boolean;
	name: string;
	email: string | null;
	phone: string;
	descripcion: string;
	showDescriptionField: boolean;
}

export default function GeneralConfig(props: Props): JSX.Element {
	const classes = useStyles();
	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography
					marginTop={2}
					component="h1"
					variant="h6"
					gutterBottom
					sx={{
						backgroundColor: 'primary.light',
						px: 2,
						py: 1,
						borderRadius: 2,
						color: 'white',
					}}
				>
					Configuración General
					<HelpTooltip
						helpKey="general"
						placement="bottom-end"
						iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
					/>
				</Typography>
				<Card sx={{ borderRadius: 2, p: 2, mt: 1 }}>
					<GeneralConfigForm {...props} />
				</Card>
			</div>
		</Container>
	);
}
