import React, { useEffect, useRef } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { HotelOptionDetailDestinationMultiple } from 'features/quotation/types';

interface HotelOptionItemProps {
	isSelected: boolean;
	hotel: HotelOptionDetailDestinationMultiple;
	onSelect: () => void;
}

export function HotelOptionItem({ isSelected, hotel, onSelect }: HotelOptionItemProps): JSX.Element {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isSelected && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				inline: 'center',
				block: 'nearest',
			});
		}
	}, [isSelected]);

	return (
		<Grid sx={{ display: 'flex', alignItems: 'stretch' }} item ref={isSelected ? ref : null}>
			<Button
				size="small"
				sx={{
					width: '240px',
					overflow: 'hidden',
					height: '28px',
					backgroundColor: isSelected ? 'rgba(49, 25, 75, 0.2)' : 'none',
				}}
				variant="outlined"
				onClick={onSelect}
				color="primary"
			>
				<Typography
					fontSize={12}
					fontWeight={isSelected ? 'bold' : ''}
					minHeight="30px"
					display="flex"
					alignItems="center"
					justifyContent="center"
					minWidth="200px"
					maxWidth="240px"
					component="p"
					sx={{
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
					}}
				>
					{hotel.hotelName}
				</Typography>
			</Button>
		</Grid>
	);
}
