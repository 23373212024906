import React from 'react';
import { Card, Container, CssBaseline, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationServiceSimple } from '../../types';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	listItem: {
		marginBottom: theme.spacing(1), // Espaciado entre elementos
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		lineHeight: 1.5,
		fontFamily: theme.typography.fontFamily,
	},
	content: {
		marginTop: '16px',
	},
}));

interface Props {
	services: QuotationServiceSimple[];
	manualServices: QuotationServiceSimple[];
}
export const QuotationServicesObservationPreview = ({ services, manualServices }: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography
					component="h1"
					variant="h6"
					sx={{
						marginBottom: '8px',
						width: '100%',
						backgroundColor: '#31194B',
						paddingLeft: '16px',
						paddingRight: '16px',
						paddingTop: '8px',
						paddingBottom: '8px',
						borderRadius: '4px',
						fontSize: '1rem',
						color: 'white',
					}}
				>
					Observaciones relacionadas a los servicios incluidos en el paquete:
				</Typography>
				{/* <div className={classes.content}> */}
				<Card sx={{ padding: '16px' }}>
					{services.map((service, index) => (
						<Typography
							key={`service-observations-preview-${index}`}
							component="div"
							variant="body2"
							className={classes.listItem}
						>
							<strong>* {service.name}:</strong> {service.description}
						</Typography>
					))}

					{manualServices.map((manualService, index) => (
						<Typography
							key={`manual-service-observations-preview-${index}`}
							component="div"
							variant="body2"
							className={classes.listItem}
						>
							<strong>* {manualService.name}:</strong> {manualService.description}
						</Typography>
					))}
				</Card>
				{/* </div> */}
			</div>
		</Container>
	);
};
