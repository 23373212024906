import React from 'react';
import { Typography, Link } from '@mui/material';

export const helpContents: Record<string, React.ReactNode> = {
	general: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Configuracion general
			</Typography>
			<Typography variant="body2">
				<strong>1-</strong> Ingresa el Deal Id de Bitrix24 o el Id de caso de nuestro CRM según corresponda y presiona
				&quot;enter&quot; para prepopular los datos del cliente.
			</Typography>
			<Typography variant="body2">
				<strong>2-</strong> En el bloque &quot;Comentarios del asesor&quot; coloca la información util para el cliente
				relacionada a esta cotización.
			</Typography>
			<Typography variant="body2">
				<strong>tip:</strong> 🥷 Podes configurar comentarios por default en la seccion &quot;Quotation default config /
				Description&quot;, estos comentarios se van a agregar cada vez que creas una cotizacion, y si no te sirve para
				la cotización especifica lo podes borrar directamente en l bloque de texto.
			</Typography>
			<Typography variant="body2">
				<strong>info: </strong> Tu cotización puede ser modificada cuantas veces precises, pero tiene que tener en
				cuenta que cada vez que la envias al cliente a traves de whatsApp generas una &quot;foto &quot; del momento
				actual, esto genera un link que es lo que le llega al cliente. Si le enviaste y luego haces cambios, es
				necesario que guardes y vuelvas a enviarle.
			</Typography>
			<Typography variant="body2">
				<strong>pdf: </strong> Imprimir pdf de cotización está habilitado unicamente para cotizaciones con un itinerario
				de vuelo y solo un destino, de lo contrario el formato se rompe y queda ilegible.
			</Typography>
		</>
	),
	destinationConfig: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Configuracion de destinos
			</Typography>
			<Typography variant="body2">
				En esta sección vas a configurar el o los destinos de viaje de esta cotización. <br /> Es{' '}
				<strong>importante</strong> que tengas en cuenta que son destinos dentro de un mismo viaje (por ejemplo: Rio y
				Buzios ó Miami y Orlando), si el cliente te solicito mas de un destino para comparar debes crear cotizaciones
				distintas.
			</Typography>
			<Typography variant="body2">
				<strong>1-</strong> Selecciona el destino por codigo IATA o nombre
			</Typography>
			<Typography variant="body2">
				<strong>2-</strong> Ingresa la fecha en la cual el cliente hará check-in en el alojamiento
			</Typography>
			<Typography variant="body2">
				<strong>3-</strong> Podes ingresar fecha de check-out o cantidad de noches para poder añadir el destino.
			</Typography>
			<Typography variant="body2">
				<strong>importante:</strong> Recuerda que la fecha de check-in es en la cual quieres que el pasajero haga
				check-in en el alojamiento en este destino y no la fecha en la cual comienza el vuelo
			</Typography>
		</>
	),
	flightsConfig: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Configuracion de vuelos
			</Typography>
			<Typography variant="body2">
				<strong>Si la citización no requeire itinerario</strong> podes desactivar la configuración de vuelos haciendo
				click en el toggle <strong>&quot;activo&quot;</strong>
			</Typography>
			<Typography variant="body2">
				Se pueden configurar <strong>hasta 3 opciones</strong> de itinerario, pero tené en cuenta que son opciones y que
				el precio de la cotización varía en base a la opción seleccionada por el cliente.{' '}
			</Typography>
			<Typography variant="body2">
				Podés agregar una opción de itinerario mediante la opción <strong>&quot;configurar por GDS&quot;</strong>{' '}
				pegando el itineario de Amadeus en el bloque &quot;GDS Raw Data&quot; o mediante la opción{' '}
				<strong>&quot;configurar por imagenes&quot;</strong> pegando la/las imagenes del itineario
			</Typography>
			<Typography variant="body2">
				Para agregar una opción de itineario siempre es necesario seleccionar la aerolina, ingresar el costo de cada uno
				de los tipos de pasajeros que contenga la cotizacion (ADT, CHD, e INF) y el equipaje que incluye el itinerario
			</Typography>
			<Typography variant="body2">
				Una vez ingresada la opción <strong>no es posible modificarla</strong> por lo que si precisas cambiar el costo o
				la politica de equipaje es necesario eliminarlo 😢 y volverlo a cargar{' '}
			</Typography>
			<Typography variant="body2">
				<strong>Tip: 🥷</strong> Agrgea el costo adicional del equipaje en los &quot;comentarios del asesor&quot; para
				evitar que el cliente tenga que consultartelo{' '}
			</Typography>
		</>
	),

	roomsConfig: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Configuracion de habitaciones
			</Typography>
			<Typography variant="body2">Configura las habitaciones de la cotización.</Typography>
			<Typography variant="body2">
				<strong>1- </strong>Ingresá la cantidad de adultos que se alojarán en la habitación.
			</Typography>
			<Typography variant="body2">
				<strong>2- </strong>Ingresá la edad de cada CHD o INF que vas a agregar en la habitación y recordá hacer click
				en &quot;AGREGAR&quot; para pre-cargar el menor .
			</Typography>
			<Typography variant="body2">
				<strong>3- </strong>Verifica con el texto de ayuda la pre-carga de la habitación, si estas oka, avanzá haciendo
				click en &quot;AGREGAR HABITACIÓN&quot;
			</Typography>
			<Typography variant="body2">
				<strong>Tip: 🥷</strong> Tené en cuenta que la configuración de los ocupantes de cada habitación te va a limitar
				la busqueda automatica de tarifas, por ejemplo, si colocas 5 pasajeros en una habitación, dificilmente consigas
				alojamientos que permitan esta configuración.
			</Typography>
		</>
	),
	hotelConfig: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Configuracion de alojamiento
			</Typography>
			<Typography variant="body2">
				Es necesario <strong>configurar destinos y habitaciones</strong> para poder avanzar en este paso.
			</Typography>
			<Typography variant="body2">
				La elección de alojamiento se realiza <strong>por destino</strong>, en caso de que el paquete sea multidestino
				el cliente podrá seleccionar la opción deseada en cada destino y el sistema le calcula la tarifa del paquete.
				Por defecto el cliente recibe la cotización con las opciones mas economicas preseleccionadas.
			</Typography>
			<Typography variant="body2">
				Exceptuando los casos en los cuales el cliente nos pide especificamente un alojamiento, siempre es recomendable{' '}
				<strong>ofrecerle al menos 3 opciones</strong> por destino.
			</Typography>
			<Typography variant="body2">
				<strong>1- </strong>Ingresa el nombre del alojamiento para avanzar, es necesario{' '}
				<strong>colocar al menos 4 letras</strong> para comenzar a ver las opciones disponibles.
			</Typography>
			<Typography variant="body2">
				Si no encuentras el alojamiento desedo puedes solicitar la carga manual del mismo{' '}
				<Link href="https://forms.gle/pBmivtw1DHjoZm9v7" target="_blank" rel="noopener noreferrer">
					<strong>haciendo click AQUI</strong>
				</Link>
				, pero no permitará tarifas automáticas.
			</Typography>
			<Typography variant="body2">
				<strong>2- </strong>Ingresa el nombre del alojamiento para avanzar, es necesario colocar al menos 4 letras para
				comenzar a ver las opciones disponibles.
			</Typography>
			<Typography variant="body2">
				<strong>&quot;CHD charged?&quot;</strong> e <strong>&quot;INF charged?&quot;</strong> vienen prepopulados por
				defecto en &quot;0&quot; y admiten valores decimales entre 0 y 1. Cuando tu configuración de habitaciones
				contiene CHD o INF, puedes utilizar estos campos si deseas asignar parte del costo del la habitación al
				presupuesto del menor. Por ejemplo, supongamos que tienes una habitacion con 2 ADT y 1 CHD, y que la misma tiene
				un costo de 100. Si el valor está en 0 asociarás 50 a cada ADT y 0 al CHD. Si colocas el valor en 1 asociarás
				33.33 a cada ADT y al CHD. Si colocas el valos en 0.2 vas a asociar al CHD el 20% del costo asociado a un ADT,
				siendo 45.45 el costo de un ADT y 9.09 el costo de un CHD.
			</Typography>
			<Typography variant="body2">
				<strong>3- </strong>Podés cargar las tarifas de forma manual (buscandolas en un broker u operador) o de forma
				automatica haciendo click en el toggle y luego en el botón de &quot;VER PRECIOS&quot;, deberás seleccionar una
				tarifa x habitación.
			</Typography>
		</>
	),
	preloadedServices: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Configuracion de servicios pre-cargados
			</Typography>
			<Typography variant="body2">Esta sección funciona por destino.</Typography>
			<Typography variant="body2">Agrega servicios a la cotización.</Typography>
			<Typography variant="body2">
				<strong>Servicio: </strong>El listado de servicios viene precargado desde el backend, selecciona uno para
				avanzar.
			</Typography>
			<Typography variant="body2">
				<strong>Tarifa: </strong>Selecciona la tarifa del servicio, vienen desde backend y generalmente por base (Sgl,
				Dbl, etc).
			</Typography>
			<Typography variant="body2">
				<strong>Tipo de servicio: </strong>Si seleccionas &quot;por pasajero&quot; se aplica el costo por cada uno de
				los pasajeros, si aplicas &quot;por grupo&quot; se divide el costo entre todos los pasajeros de la cotización.
			</Typography>
			<Typography variant="body2">
				<strong>&quot;CHD charged?&quot;</strong> e <strong>&quot;INF charged?&quot;</strong> vienen prepopulados por
				defecto en &quot;1&quot; y admiten valores decimales entre 0 y 1. Cuando tu configuración de servicios contiene
				CHD o INF, puedes utilizar estos campos si deseas asignar parte del costo del servicio al presupuesto del menor.
				Por ejemplo, supongamos que tenes un traslado con 2 ADT y 1 CHD, y que el mismo tiene un costo de 100 por
				pasajero adulto y 50 por pasajero CHD. Si el valor está en 0 asociarás 100 a cada ADT y 0 al CHD. Si colocas el
				valor en 1 asociarás 100 a cada ADT y al CHD. Si colocas el valos en 0.5 vas a asociar al CHD el 50% del costo
				asociado a un ADT, siendo 100 el costo de un ADT y 50 el costo de un CHD.
			</Typography>
			<Typography variant="body2">
				<strong>Descripcion: </strong>Podes agregar información o detalles en esta seccion que en la vista del cliente
				se visualizará como anexo.
			</Typography>
			<Typography variant="body2">
				Si encontras un servicio que tiene la tarifa desactualizada o hay un servicio que no se encuentra cargado pero
				que utilizas habitualmente, podes solicitar la carga a traves del backend{' '}
				<Link href="https://forms.gle/wjmsEBF6b29wxmHw7" target="_blank" rel="noopener noreferrer">
					<strong>haciendo click AQUI</strong>
				</Link>
				, para que podamos priorizar su mantenimiento.
			</Typography>
		</>
	),
	manualServices: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Configuracion de servicios manuales
			</Typography>
			<Typography variant="body2">
				Funciona de froma similar a &quot;servicios pre-cargados&quot; con la diferencia que podes agregar en fromato
				libre.
			</Typography>
			<Typography variant="body2">
				Acordate que podes solicitar la carga de un servicio{' '}
				<Link href="https://forms.gle/wjmsEBF6b29wxmHw7" target="_blank" rel="noopener noreferrer">
					<strong>haciendo click AQUI</strong>
				</Link>
				, para los casos donde recurrentemente estas utilizando un servicio que no existe en &quot;servicios
				pre-cargados&quot;.
			</Typography>
		</>
	),
	notIncludes: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Agrega que &quot;No incluye&quot; la ctoización
			</Typography>
			<Typography variant="body2">
				Utilizamos este bloque para cargar todo aquello que NO INCLUYE la ctoización. Los items que vayas agregando acá
				conforman una lista que el cliente visualiza bajo el titulo No incluye.
			</Typography>
			<Typography variant="body2">
				<strong>Tip: 🥷</strong>Pordes pre-cargarte una lista de items de &quot;NO INCLUYE&quot; para que se generen
				automaticamente cada vez que creas una cotización, y luego si en tu cotización no aplicara o no fuera necesario
				lo podes eliminar haciendo click en el icono de &quot;borrar&quot;. Por ejemplo podes agregar &quot;Tasas
				turísticas locales, resort fees, extras en hoteles y propinas&quot;. Dirigite a la seccion &quot;Quotation
				Default Config - Not Includes&quot; para realizar esta configuración.
			</Typography>
		</>
	),
	quotationResultMulti: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Presupuesto
			</Typography>
			<Typography variant="body2">
				<strong>MarkUp</strong> es el porcentaje de margen bruto (ganancia bruta) que vas a aplicar sobre los costos de
				la cotización. Por ejemplo, supongamos que la suma de todos los costos de la ctoizacion es de 100 y aplicas un
				valor de 20 en el markup. El precio de venta de tu cotización será de 125 ya que el 20% de 125 es 25 y el costo
				es de 100.{' '}
			</Typography>
			<Typography variant="body2">
				<strong>Ver precio local</strong> Por defecto viene desactivado, al hacer click en el toggle y activar esta
				función, los valores de la cotización seran mostrados al cliente también en moneda local, el tipo de cambio
				utilizado se aplica de forma automatica en base al sistema.{' '}
			</Typography>
			<Typography variant="body2">
				<strong>Seña (Reserva desde)</strong> Por defecto viene desactivado, al hacer click en el toggle y activar esta
				función, el sistema calcula cual es el monto que aceptaremos como seña para garantizar el precio de venta al
				cliente. El cálculo es automatico y tiene en cuenta el costo del aéreo y el margen bruto del paquete. Deja por
				fuera los costos de servicios y del alojamiento por lo que es importante que siempre que vayas a disponibilizar
				la opción de seña, cotices utilizando tarifas reembolsables de alojamientos y servicios. El plazo maximo que
				daremos al cliente en relación al pago del saldo es de 45 días previos al viaje, pero si utilizaste una tarifa
				en alguno de los servicios que no cumple con esta regla debes considarlo para comunicar al cliente.
			</Typography>
			<Typography variant="body2">
				<strong>Multidestino: </strong>El sistema genera todas las combinaciones posibles entre los distintos
				itinerarios y opciones de alojamiento que hayas establecido para cada destino, podes ir viendo el costo de cada
				opcion y el valor de venta a medida que vas seleccionando cada combinación.{' '}
			</Typography>
			<Typography variant="body2">
				<strong>Tip: 🥷</strong> Nuestro sistema actualmente no contempla la posibilidad de colocar un markup distinto
				para cada opcion de alojamiento o itinerario por lo que el markup establecido es lineal para todas las opciones.
				Podes usar esto a tu favor y en la comunicación con el cliente ofrecer algun descuento especial ya sea por un
				monto fijo o un porcentaje para alguna de las opciones.{' '}
			</Typography>
		</>
	),
	quotationResult: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Presupuesto
			</Typography>
			<Typography variant="body2">
				<strong>MarkUp</strong> es el porcentaje de margen bruto (ganancia bruta) que vas a aplicar sobre los costos de
				la cotización. Por ejemplo, supongamos que la suma de todos los costos de la ctoizacion es de 100 y aplicas un
				valor de 20 en el markup. El precio de venta de tu cotización será de 125 ya que el 20% de 125 es 25 y el costo
				es de 100.{' '}
			</Typography>
			<Typography variant="body2">
				<strong>Ver precio local</strong> Por defecto viene desactivado, al hacer click en el toggle y activar esta
				función, los valores de la cotización seran mostrados al cliente también en moneda local, el tipo de cambio
				utilizado se aplica de forma automatica en base al sistema.{' '}
			</Typography>
			<Typography variant="body2">
				<strong>Seña (Reserva desde)</strong> Por defecto viene desactivado, al hacer click en el toggle y activar esta
				función, el sistema calcula cual es el monto que aceptaremos como seña para garantizar el precio de venta al
				cliente. El cálculo es automatico y tiene en cuenta el costo del aéreo y el margen bruto del paquete. Deja por
				fuera los costos de servicios y del alojamiento por lo que es importante que siempre que vayas a disponibilizar
				la opción de seña, cotices utilizando tarifas reembolsables de alojamientos y servicios. El plazo maximo que
				daremos al cliente en relación al pago del saldo es de 45 días previos al viaje, pero si utilizaste una tarifa
				en alguno de los servicios que no cumple con esta regla debes considarlo para comunicar al cliente.
			</Typography>
			<Typography variant="body2">
				<strong>Tip: 🥷</strong> Nuestro sistema actualmente no contempla la posibilidad de colocar un markup distinto
				para cada opcion de alojamiento o itinerario por lo que el markup establecido es lineal para todas las opciones.
				Podes usar esto a tu favor y en la comunicación con el cliente ofrecer algun descuento especial ya sea por un
				monto fijo o un porcentaje para alguna de las opciones.{' '}
			</Typography>
		</>
	),
	optionalServices: (
		<>
			<Typography variant="body1" fontWeight="bold">
				Servicios opcionales
			</Typography>
			<Typography variant="body2">
				En esta sección se muestran los servicios opcionales que tenemos rpe-cargados para cada destino.
			</Typography>
			<Typography variant="body2">
				Por defecto vienen apagados, pero podes activar el/los que quieras haciendo click en el toggle a la derecha de
				cada servicio.
			</Typography>
			<Typography variant="body2">
				Si crees que sería bueno sumar algun servicio opcional en algun destino o encuentras uno que esta
				desactualizado, por favor solicita la carga/actualización{' '}
				<Link href="https://forms.gle/wRjt5kskWR5edzwc6" target="_blank" rel="noopener noreferrer">
					<strong>haciendo click AQUI</strong>
				</Link>
				, para que podamos evaluar y priorizar la solicitud.
			</Typography>
		</>
	),
};
