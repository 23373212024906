import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import minturBo from '../../../../assets/minturbol.png';
import whiteLogo from '../../../../assets/mevuelo-blanco.svg';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

const WhiteTextTypography = withStyles({
	root: {
		color: '#FFFFFF',
	},
})(Typography);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
		},
		container: {
			backgroundColor: theme.palette.primary.main,

			paddingTop: theme.spacing(4),
		},
		whiteIcon: {
			color: '#FFFFFF',
		},
		qr: {
			width: '120px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
		},
	}),
);

const MevueloBoFooter = (): JSX.Element => {
	const classes = useStyles();
	return (
		//BOLIVIA
		<div className={classes.root}>
			<Container sx={{ paddingLeft: '0', paddingRight: '0' }}>
				<Box className={classes.container}>
					<WhiteTextTypography variant="h6" align="center">
						CONTACTANOS
					</WhiteTextTypography>
					<Box paddingTop={2} display="flex" justifyContent="space-evenly" flexWrap="wrap">
						<a className={classes.noUnderlineLinks} href="tel:77321230">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocalPhoneIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									77321230
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="mailto:hola.bo@mevuelo.com">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<EmailIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									hola.bo@mevuelo.com
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://goo.gl/maps/Eht7yKaV68oQzhhj6"
							target="_blank"
							rel="noreferrer"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={120}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocationOnIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									C. Fermín Peralta Local 23 Shopping FIDALGA Equipetrol
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle2" align="center">
									Santa Cruz
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<Box display="flex" justifyContent="space-around" alignItems="center" paddingTop={4} paddingBottom={6}>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.facebook.com/mevuelo.bo/"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<FacebookIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.bo
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.instagram.com/mevuelo.bo/?hl=es-la"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<InstagramIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.bo
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="http://wa.me/59162244778" target="_blank" rel="noreferrer">
							<Box display="flex" flexDirection="column" alignItems="center">
								<WhatsAppIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									591 62244778
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.linkedin.com/company/mevuelo/"
							target="_blank"
							rel="noreferrer"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<LinkedInIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<WhiteTextTypography variant="subtitle1" align="center">
						AGENCIA DE VIAJES REGISTRADA:
					</WhiteTextTypography>
					<Box paddingTop={4} display="flex" justifyContent="space-evenly" alignItems="center">
						<Box display="flex" flexDirection="column" alignItems="center">
							<WhiteTextTypography variant="subtitle2" align="center">
								MEVUELO BOL S.R.L.
							</WhiteTextTypography>
							<WhiteTextTypography variant="subtitle2" align="center">
								{' '}
								NIT Nº 383000025
							</WhiteTextTypography>
						</Box>
						{/* <a className={classes.noUnderlineLinks} href="https://registur.gov.py/registur/verificacion?qr=e4e4f7c8442ad196282fb79b77bd6abe" target="_blank" rel="noreferrer">
						<img className={classes.qr} src={qrPy} alt="qr" />
					</a> */}
						<img className={classes.qr} src={minturBo} alt="ministerio" />
					</Box>
					<Box display="flex" justifyContent="space-evenly" paddingTop={6} paddingBottom={24} alignItems="end">
						<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com" target="_blank" rel="noreferrer">
							<Box display="flex" flexDirection="column" alignItems="center">
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.com está presente en:{' '}
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle1" align="center">
									Bolivia, Paraguay, Colombia y Uruguay{' '}
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com.bo" target="_blank" rel="noreferrer">
							<Box sx={{ paddingRight: '8px' }}>
								<img src={whiteLogo} alt="logo" />
							</Box>
						</a>
					</Box>
				</Box>
			</Container>
		</div>
	);
};
export default MevueloBoFooter;
