import React from 'react';
import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import logo from '../../../assets/logo.png';
//import logo from '../../../assets/logo-blanco-navidad.png';
import logoTN from '../../../assets/logo_tn.svg';

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: theme.palette.primary.main,
		textAlign: 'center',
		padding: theme.spacing(2),
		borderBottom: 'solid',
		color: theme.palette.secondary.main,
	},
}));

interface Props {
	countryCode?: string | null;
}

export const Header = ({ countryCode }: Props): JSX.Element => {
	const classes = useStyles();
	return countryCode === 'TN' ? (
		<Box
			sx={{
				backgroundColor: 'white',
				height: '72px',
				boxShadow: 'rgba(0, 0, 0, 0.25) 0px 8px 16px',
				paddingTop: '14px',
			}}
		>
			<Container maxWidth="md">
				<img width={172} src={logoTN} alt="Tienda Naranaja Viajes logo" />
			</Container>
		</Box>
	) : (
		<div className={classes.paper}>
			<Box>
				<img src={logo} alt="logo" />
			</Box>
		</div>
	);
};
