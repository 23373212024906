import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {
	SaleOrderPendingPayMessageScheduleStatusEnum,
	saleOrderPendingPayMessageScheduleStatusEnumWithName,
} from '../../../../../features/salesOrder/types';
import { formatDateTimeToShowUser } from '../../../../../features/common/helpers';

interface SaleOrderPendingPayMessageScheduleStatusProps {
	status: SaleOrderPendingPayMessageScheduleStatusEnum;
	observation?: string;
	created: string;
	createdBy: string;
	executed?: string;
}

// Mapeo de estados a iconos y colores
const statusIconMap: Record<
	SaleOrderPendingPayMessageScheduleStatusEnum,
	{
		icon: JSX.Element;
		color: string;
		label: string;
	}
> = {
	[SaleOrderPendingPayMessageScheduleStatusEnum.Sent]: {
		icon: <CheckCircleIcon />,
		color: 'green',
		label: saleOrderPendingPayMessageScheduleStatusEnumWithName[SaleOrderPendingPayMessageScheduleStatusEnum.Sent].name,
	},
	[SaleOrderPendingPayMessageScheduleStatusEnum.Cancelled]: {
		icon: <SyncAltIcon />,
		color: 'blue',
		label:
			saleOrderPendingPayMessageScheduleStatusEnumWithName[SaleOrderPendingPayMessageScheduleStatusEnum.Cancelled].name,
	},
	[SaleOrderPendingPayMessageScheduleStatusEnum.Error]: {
		icon: <ErrorIcon />,
		color: 'red',
		label:
			saleOrderPendingPayMessageScheduleStatusEnumWithName[SaleOrderPendingPayMessageScheduleStatusEnum.Error].name,
	},
	[SaleOrderPendingPayMessageScheduleStatusEnum.Pending]: {
		icon: <HourglassEmptyIcon />,
		color: 'orange',
		label:
			saleOrderPendingPayMessageScheduleStatusEnumWithName[SaleOrderPendingPayMessageScheduleStatusEnum.Pending].name,
	},
};

const SaleOrderPendingPayMessageScheduleStatus: React.FC<SaleOrderPendingPayMessageScheduleStatusProps> = (
	props: SaleOrderPendingPayMessageScheduleStatusProps,
) => {
	const { status, observation, executed, createdBy, created } = props;
	const { icon, color, label } = statusIconMap[status];

	const getTooltipContent = (): string => {
		let content = `Estado: ${label}  - Programación creada por ${createdBy} el ${formatDateTimeToShowUser(created)}`;

		switch (status) {
			case SaleOrderPendingPayMessageScheduleStatusEnum.Error:
				if (observation) {
					content += ` - Error: ${observation}`;
				}
				break;
			case SaleOrderPendingPayMessageScheduleStatusEnum.Sent:
				if (executed) {
					content += ` - Mensaje enviado: ${formatDateTimeToShowUser(executed)}`;
				}
				break;
			case SaleOrderPendingPayMessageScheduleStatusEnum.Cancelled:
				if (observation) {
					content += ` - Causa de cancelación: ${observation}`;
				}
				break;
			case SaleOrderPendingPayMessageScheduleStatusEnum.Pending:
				break;
			default:
				return 'Estado: Desconocido';
		}

		return content;
	};

	return (
		<>
			{label}
			<Tooltip title={getTooltipContent()} arrow>
				<IconButton style={{ color }}>{icon}</IconButton>
			</Tooltip>
		</>
	);
};

export default SaleOrderPendingPayMessageScheduleStatus;
