import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeOptions } from 'constants/constants';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	formatDateToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterModelSimple,
	ShowAlertState,
} from 'features/common/types';
import {
	extractErrorMessage,
	getQuotationSaleReportServiceTypeName,
	handleViewQuotation,
} from 'features/quotation/helpers';
import { getSalesReportList, getSalesReportListToExport } from 'features/quotation/services';
import {
	IQuotationSaleReportList,
	QuotationSaleReportServiceTypeEnum,
	quotationSaleReportServiceTypes,
} from 'features/quotation/types';
import React, { useEffect, useState } from 'react';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import { PageNoResult } from '../../../common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

const headerCells: IHeadCell[] = [
	{
		field: 'crmTicket',
		sortable: true,
		headerName: 'Deal o Caso',
	},
	{
		field: 'isBitrixDeal',
		sortable: true,
		headerName: 'Es un Deal',
	},
	{
		field: 'serviceType',
		sortable: true,
		headerName: 'Tipo de servicio',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de creación',
	},
	{
		field: 'travelDateQuoted',
		sortable: true,
		headerName: 'Fecha de viaje cotizada',
	},
	{
		field: 'destinationsNames',
		sortable: true,
		headerName: 'Destinos',
	},
	{
		field: 'applySign',
		sortable: true,
		headerName: 'Aplica seña',
	},
	{
		field: 'markup',
		sortable: true,
		headerName: 'Markup',
	},
	{
		field: 'totalAdults',
		sortable: true,
		headerName: 'Total adultos',
	},
	{
		field: 'totalChild',
		sortable: true,
		headerName: 'Total niños',
	},
	{
		field: 'totalInfant',
		sortable: true,
		headerName: 'Total infantes',
	},
	{
		field: 'totalAdultsAndChildren',
		sortable: true,
		headerName: 'Total de adultos y niños',
	},
	{
		field: 'validatingCarriesCodes',
		sortable: true,
		headerName: 'VC',
	},
	{
		field: 'sellerName',
		sortable: true,
		headerName: 'Nombre del vendedor',
	},
];

export const QuotationSaleReport = (): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [quotationsList, setQuotationsList] = useState<IQuotationSaleReportList[] | null>(null);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'createdAt', order: 'desc' }]);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>();

	const filters = [
		{
			label: 'Deal o Caso',
			type: FilterType.NUMERIC,
			key: 'crmTicket',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Es un Deal',
			type: FilterType.BOOLEAN,
			key: 'isBitrixDeal',
			operator: OperatorFilterEnum.EQUALS,
		},
		new SelectFilterModelSimple('Tipo de servicio', 'serviceType', quotationSaleReportServiceTypes),
		{ label: 'Fecha de creación', type: FilterType.DATE, key: 'createdAt' },
		new DateToFromFilterModel('La Fecha de Creación', 'createdAtFrom', 'createdAtTo'),
		{ label: 'Fecha de viaje cotizada', type: FilterType.DATE, key: 'travelDateQuoted' },
		new DateToFromFilterModel('Fecha de viaje cotizada', 'travelDateQuotedFrom', 'travelDateQuotedTo'),
		{
			label: 'Destinos',
			type: FilterType.STRING,
			key: 'destinationsNames',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{ label: 'Aplica seña', type: FilterType.BOOLEAN, key: 'applySign' },
		{ label: 'Markup', type: FilterType.NUMERIC, key: 'markup' },
		{ label: 'Total adultos', type: FilterType.NUMERIC, key: 'totalAdults' },
		new DateToFromFilterModel('Total adultos', 'totalAdultsFrom', 'totalAdultsTo'),
		{ label: 'Total niños', type: FilterType.NUMERIC, key: 'totalChild' },
		new DateToFromFilterModel('Total niños', 'totalChildFrom', 'totalChildTo'),
		{ label: 'Total infantes', type: FilterType.NUMERIC, key: 'totalInfant' },
		new DateToFromFilterModel('Total infantes', 'totalInfantFrom', 'totalInfantTo'),
		{ label: 'Total de adultos y niños', type: FilterType.NUMERIC, key: 'totalAdultsAndChildren' },
		new DateToFromFilterModel('Total de adultos', 'totalAdultsAndChildrenFrom', 'totalAdultsAndChildrenTo'),
		{
			label: 'VC',
			type: FilterType.STRING,
			key: 'validatingCarriesCodes',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'Nombre del vendedor',
			type: FilterType.STRING,
			key: 'sellerName',
			operator: OperatorFilterEnum.CONTAINS,
		},
	];

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const sortableFields = getSortableFields(headerCells);

	const transformValue = (key: string, value: any, row: IQuotationSaleReportList) => {
		switch (key) {
			case 'createdAt':
				return formatDateTimeToShowUser(value);
			case 'travelDateQuoted':
				return formatDateToShowUser(value);
			case 'isBitrixDeal':
			case 'applySign':
				return value ? 'Si' : 'No';
			case 'serviceType':
				return getQuotationSaleReportServiceTypeName(row.serviceType);
			case 'validatingCarriesCodes':
				return row.validatingCarriesCodes ? row.validatingCarriesCodes : '-';
			default:
				return value;
		}
	};

	const loadQuotationsList = async () => {
		try {
			setLoading(true);
			const salesReport = (
				await getSalesReportList(currentPage - 1, currentSize, sortFields, convertFiltersToRecord(filterApplied ?? []))
			).data;
			setPageTotal(getEnvelopedListPageTotal(salesReport));
			setQuotationsList(salesReport.data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de cotización'),
			});
			setLoading(false);
		}
	};

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const title = 'Listado de reportes de ventas';
			const envelopedTransactionList = (
				await getSalesReportListToExport(sortFields, convertFiltersToRecord(filterApplied ?? []))
			).data;
			const listTransactions = envelopedTransactionList.data;
			const optionsToExport = {
				title: title,
				headers: headerCells,
				widthColumns: [15, 15, 15, 20, 20, 20, 20, 15, 15, 20, 20, 20, 20],
				filename: title,
			} as ExportExcelOptions;
			exportToExcel(listTransactions, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const colSpan = headerCells.length;

	useEffect(() => {
		loadQuotationsList();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid filters={filters} handleAppliedFilters={handleApplyFilters} preAppliedFilters={filterApplied} />
					</Grid>
					<Grid item xs={1}>
						<ExportToExcelButton disabled={quotationsList?.length == 0} handleExportToExcel={handleExportToExcel} />
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
									<TableCell className={classes.tableHeader} align="center"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && quotationsList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									quotationsList?.map((quotations) => (
										<TableRow
											key={quotations.quotationId}
											hover
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell align="right" component="p">
												{quotations.crmTicket}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('isBitrixDeal', quotations.isBitrixDeal, quotations)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('serviceType', quotations.serviceType, quotations)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('createdAt', quotations.createdAt, quotations)}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.travelDateQuoted}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.destinationsNames}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('applySign', quotations.applySign, quotations)}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.markup}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.totalAdults}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.totalChild}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.totalInfant}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.totalAdultsAndChildren}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('validatingCarriesCodes', quotations.validatingCarriesCodes, quotations)}
											</TableCell>
											<TableCell align="right" component="p">
												{quotations.sellerName}
											</TableCell>
											<TableCell component="p">
												<Button
													variant="contained"
													title={'Ver Cotización'}
													sx={{ height: 40, ml: 1 }}
													onClick={handleViewQuotation(
														quotations.quotationId,
														quotations.serviceType === QuotationSaleReportServiceTypeEnum.FLIGHT,
													)}
												>
													Ver
												</Button>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} textAlign="center" marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
