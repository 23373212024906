import React from 'react';
import { Box, Card, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import visa from '../../../../assets/payment-logos/visa.svg';
import amex from '../../../../assets/payment-logos/american-express.svg';
import master from '../../../../assets/payment-logos/mastercard.svg';
import bancolombia from '../../../../assets/payment-logos/boton-bancolombia.svg';
import cash from '../../../../assets/payment-logos/cash.svg';
import nequi from '../../../../assets/payment-logos/nequi.svg';
import pse from '../../../../assets/payment-logos/pse.svg';
import qrCodeButton from '../../../../assets/payment-logos/qr-code-button.svg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
			maxWidth: '100%',
		},
		cardsItem: {
			padding: '5px',
			display: 'inline-block',
			textAlign: 'center',
			verticalAlign: 'middle',
		},
		cardBoxHolder: {
			marginTop: 20,
		},
		consulta: {
			marginTop: 30,
		},
		background: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '8px',
		},
		itauBox: {
			backgroundColor: ' #EE7000',
			borderRadius: '8px',
			display: 'flex',
		},
		itauContentBox: {
			alignItems: 'center',
			display: 'flex',
			width: '100%',
			placeContent: 'space-evenly',
		},
		itauLogo: {
			width: '80px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
			color: 'inherit',
		},
	}),
);

const MevueloCOPaymentMethods = (): JSX.Element => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="md">
				<Typography
					component="h1"
					variant="h6"
					sx={{
						marginBottom: '8px',
						width: '100%',
						backgroundColor: '#31194B',
						paddingLeft: '16px',
						paddingRight: '16px',
						paddingTop: '8px',
						paddingBottom: '8px',
						borderRadius: '4px',
						fontSize: '1rem',
						color: 'white',
					}}
				>
					Nuestras formas de pago:
				</Typography>
				<Card sx={{ padding: '16px', marginBottom: '16px' }}>
					<Box className={classes.cardBoxHolder} display="flex" justifyContent="center" alignItems="flex-end">
						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={visa} alt="visa" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={amex} alt="american-express" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={master} alt="master-card" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>
						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={nequi} alt="nequi" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>
					</Box>
					<Box className={classes.cardBoxHolder} display="flex" justifyContent="center" alignItems="flex-end">
						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={bancolombia} alt="Bancolombia" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={cash} alt="pago-en-efectivo" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={pse} alt="pse" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>
						<Box display="flex" flexDirection="column" alignItems="center">
							<Box className={classes.cardsItem}>
								<img src={qrCodeButton} alt="codigo-qr" width={80} />
							</Box>
							<Typography variant="subtitle2" align="center"></Typography>
						</Box>
					</Box>
					<Typography className={classes.consulta} variant="subtitle1" align="center">
						Aprovechá nuestros beneficios directos:
					</Typography>
					<Typography className={classes.consulta} variant="subtitle1" align="center">
						Tarjetas de crédito de otros bancos en un solo pago sin interés o en cuotas con recargo. Consultá con tu
						banco.
					</Typography>
				</Card>
			</Container>
		</div>
	);
};
export default MevueloCOPaymentMethods;
