import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionItem {
	title: string;
	content: React.ReactNode;
}

interface CustomAccordionProps {
	title: string;
	subtitle?: string; // Opcional
	items: AccordionItem[];
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ title, subtitle, items }) => (
	<div>
		<Container maxWidth="md" sx={{ paddingTop: '16px' }}>
			<Typography
				component="h1"
				variant="h6"
				sx={{
					marginBottom: '8px',
					width: '100%',
					backgroundColor: '#31194B',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingTop: '8px',
					paddingBottom: '8px',
					borderRadius: '4px',
					fontSize: '1rem',
					color: 'white',
				}}
			>
				{title}
			</Typography>
			{subtitle && (
				<Typography
					variant="h6"
					sx={{
						paddingLeft: '16px',
						paddingRight: '16px',
						paddingTop: '8px',
						paddingBottom: '8px',
						fontSize: '0.90rem',
						fontFamily: 'Montserrat, sans-serif',
					}}
				>
					{subtitle}
				</Typography>
			)}
			{items.map((item, index) => (
				<Accordion key={index}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />} // Agregamos la flecha aquí
						sx={{
							fontFamily: 'Montserrat, sans-serif',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography>{item.title}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{item.content}</Typography>
					</AccordionDetails>
				</Accordion>
			))}
		</Container>
	</div>
);

// PropTypes con validación
CustomAccordion.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			content: PropTypes.node.isRequired,
		}).isRequired,
	).isRequired,
};

export default CustomAccordion;
