// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import DeleteIcon from '@mui/icons-material/Delete';
// import {
// 	Button,
// 	Card,
// 	Chip,
// 	Container,
// 	CssBaseline,
// 	Dialog,
// 	DialogActions,
// 	DialogContent,
// 	DialogContentText,
// 	DialogTitle,
// 	Grid,
// 	IconButton,
// 	Paper,
// 	Table,
// 	TableBody,
// 	TableCell,
// 	TableContainer,
// 	TableHead,
// 	TableRow,
// 	TextField,
// 	Typography,
// } from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';

// import { RoomBaseConfig } from '../types';
// import { addRoomToRoomBaseConfig, deleteRoomBaseConfigRow } from '../slice';
// import { RootState } from '../../../store';
// import { getPAXTotals } from '../selectors';

// const useStyles = makeStyles((theme) => ({
// 	paper: {
// 		marginTop: theme.spacing(8),
// 		display: 'flex',
// 		flexDirection: 'column',
// 		alignItems: 'left',
// 	},
// 	avatar: {
// 		margin: theme.spacing(1),
// 		backgroundColor: theme.palette.secondary.main,
// 	},
// 	form: {
// 		width: '100%', // Fix IE 11 issue.
// 		marginTop: theme.spacing(3),
// 	},
// 	submit: {
// 		margin: theme.spacing(3, 0, 2),
// 	},
// 	table: {
// 		minWidth: 650,
// 	},
// }));

// const initialCreateBaseConfigRoomObject: RoomBaseConfig = {
// 	adults: 0,
// 	childs: [],
// 	infants: [],
// };

// export default function RoomBaseConfigComponent(): JSX.Element {
// 	const classes = useStyles();
// 	const dispatch = useDispatch();

// 	const paxTotals = useSelector(getPAXTotals);

// 	const [createBaseConfigRoomObject, setCreateBaseConfigRoomObject] = useState<RoomBaseConfig>(
// 		initialCreateBaseConfigRoomObject,
// 	);
// 	const [childsAge, setChildAge] = useState<number>(0);
// 	const [infantAge, setInfantAge] = useState<number>(0);

// 	const { roomBaseConfig } = useSelector((state: RootState) => state.quotation);

// 	const [openDeleteRoomConfigConfirmDialog, setOpenDeleteRoomConfigConfirmDialog] = React.useState(false);
// 	const initDeleteRoomConfigPayload = -1;
// 	const [deleteRoomConfigPayload, setDeleteRoomConfigPayload] = React.useState<number>(initDeleteRoomConfigPayload);

// 	const handleClickOpenConfirmDeleteRoomConfig = (index: number) => () => {
// 		setOpenDeleteRoomConfigConfirmDialog(true);
// 		setDeleteRoomConfigPayload(index);
// 	};

// 	const handleCloseConfirmDeleteRoomConfig = () => {
// 		setOpenDeleteRoomConfigConfirmDialog(false);
// 	};
// 	const handleDeleteRoomConfig = () => {
// 		setOpenDeleteRoomConfigConfirmDialog(false);
// 		// @ts-ignore
// 		dispatch(deleteRoomBaseConfigRow(deleteRoomConfigPayload));
// 	};

// 	const handleAdultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// 		setCreateBaseConfigRoomObject((prevState) => {
// 			return { ...prevState, adults: parseInt(e.target.value) };
// 		});
// 	};

// 	const handleChildAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// 		setChildAge(parseInt(e.target.value));
// 	};

// 	const handleChildAdd = () => {
// 		setCreateBaseConfigRoomObject((prevState) => {
// 			const newState = { ...prevState };
// 			newState.childs.push(childsAge);
// 			return newState;
// 		});
// 		setChildAge(0);
// 	};

// 	const handleChildRemove = (index: number) => () => {
// 		setCreateBaseConfigRoomObject((prevState) => {
// 			const newState = { ...prevState };
// 			newState.childs.splice(index, 1);
// 			return newState;
// 		});
// 	};

// 	const handleInfantAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// 		setInfantAge(parseInt(e.target.value));
// 	};

// 	const handleInfantAdd = () => {
// 		setCreateBaseConfigRoomObject((prevState) => {
// 			const newState = { ...prevState };
// 			newState.infants.push(infantAge);
// 			return newState;
// 		});
// 		setInfantAge(0);
// 	};

// 	const handleInfantRemove = (index: number) => () => {
// 		setCreateBaseConfigRoomObject((prevState) => {
// 			const newState = { ...prevState };
// 			newState.infants.splice(index, 1);
// 			return newState;
// 		});
// 	};

// 	const handleRoomBaseConfigAdd = () => {
// 		dispatch(addRoomToRoomBaseConfig(createBaseConfigRoomObject));
// 		setCreateBaseConfigRoomObject({
// 			adults: 0,
// 			childs: [],
// 			infants: [],
// 		});
// 	};

// 	return (
// 		<Container component="main" maxWidth="md">
// 			<div>
// 				<Dialog
// 					open={openDeleteRoomConfigConfirmDialog}
// 					onClose={handleCloseConfirmDeleteRoomConfig}
// 					aria-labelledby="alert-dialog-title"
// 					aria-describedby="alert-dialog-description"
// 				>
// 					<DialogTitle id="alert-dialog-title">{'Eliminar configuración de la habitación'}</DialogTitle>
// 					<DialogContent>
// 						<DialogContentText id="alert-dialog-description">
// 							¿Confirma eliminar la configuración de la habitación #{deleteRoomConfigPayload + 1}?
// 						</DialogContentText>
// 					</DialogContent>
// 					<DialogActions>
// 						<Button variant="outlined" onClick={handleCloseConfirmDeleteRoomConfig}>
// 							Cancelar
// 						</Button>
// 						<Button variant="contained" color="primary" onClick={handleDeleteRoomConfig} autoFocus>
// 							Aceptar
// 						</Button>
// 					</DialogActions>
// 				</Dialog>
// 			</div>
// 			<CssBaseline />
// 			<div className={classes.paper}>
// 				<Typography
// 								marginTop={2}
// 								component="h1"
// 								variant="h6"
// 								gutterBottom
// 								sx={{ backgroundColor: 'primary.light', px: 2, py: 1, borderRadius: 2, color: 'white' }}
// 							>
// 								Configuración de habitaciones:
// 							</Typography>
// 				<Card sx={{ borderRadius: 2, padding: 2, marginTop: 1 }}>
// 				<TableContainer component={Paper}>
// 					<Table className={classes.table} aria-label="simple table">
// 						<TableHead>
// 							<TableRow>
// 								<TableCell>Room #</TableCell>
// 								<TableCell align="right">ADT</TableCell>
// 								<TableCell align="right">CHD</TableCell>
// 								<TableCell align="right">INF</TableCell>
// 								<TableCell align="right" />
// 							</TableRow>
// 						</TableHead>
// 						<TableBody>
// 							{roomBaseConfig.map((item, index) => (
// 								<TableRow key={index}>
// 									<TableCell component="th" scope="row">
// 										{index + 1}
// 									</TableCell>
// 									<TableCell align="right">{item.adults}</TableCell>
// 									<TableCell align="right">{item.childs.join()}</TableCell>
// 									<TableCell align="right">{item.infants.join()}</TableCell>
// 									<TableCell align="right">
// 										<IconButton
// 											onClick={handleClickOpenConfirmDeleteRoomConfig(index)}
// 											color="secondary"
// 											component="span"
// 											size="large"
// 										>
// 											<DeleteIcon />
// 										</IconButton>
// 									</TableCell>
// 								</TableRow>
// 							))}

// 							<TableRow>
// 								<TableCell component="th" scope="row">
// 									Total
// 								</TableCell>
// 								<TableCell align="right">{paxTotals.adults}</TableCell>
// 								<TableCell align="right">{paxTotals.child}</TableCell>
// 								<TableCell align="right">{paxTotals.infants}</TableCell>
// 								<TableCell align="right"></TableCell>
// 							</TableRow>
// 						</TableBody>
// 					</Table>
// 				</TableContainer>
// 				<form className={classes.form} noValidate>
// 					<Grid container spacing={2}>
// 						<Grid item xs={12} sm={12}>
// 							<Typography component="span" variant="subtitle1">
// 								Adultos:
// 							</Typography>
// 							<TextField
// 								name="adults"
// 								variant="outlined"
// 								value={createBaseConfigRoomObject.adults}
// 								required
// 								id="adults"
// 								label="Cantidad de Adultos"
// 								type="number"
// 								size="small"
// 								onChange={handleAdultChange}
// 							/>
// 						</Grid>
// 						<Grid item xs={12} sm={12}>
// 							<Typography component="span" variant="subtitle1">
// 								Childs:
// 							</Typography>
// 							{createBaseConfigRoomObject.childs.map((age, index) => (
// 								<Chip key={index} label={age} onDelete={handleChildRemove(index)} />
// 							))}

// 							<TextField
// 								name="childAge"
// 								variant="outlined"
// 								required
// 								id="childAge"
// 								label="Edad"
// 								type="number"
// 								value={childsAge}
// 								size="small"
// 								onChange={handleChildAgeChange}
// 							/>
// 							<Button variant="contained" color="primary" onClick={handleChildAdd}>
// 								Agregar
// 							</Button>
// 						</Grid>
// 						<Grid item xs={12} sm={12}>
// 							<Typography component="span" variant="subtitle1">
// 								Infants:
// 							</Typography>
// 							{createBaseConfigRoomObject.infants.map((age, index) => (
// 								<Chip key={index} label={age} onDelete={handleInfantRemove(index)} />
// 							))}
// 							<TextField
// 								name="infants"
// 								variant="outlined"
// 								required
// 								id="infants"
// 								label="Edad"
// 								type="number"
// 								size="small"
// 								value={infantAge}
// 								onChange={handleInfantAgeChange}
// 							/>
// 							<Button variant="contained" color="primary" onClick={handleInfantAdd}>
// 								Agregar
// 							</Button>
// 						</Grid>
// 					</Grid>
// 					<Button
// 						type="button"
// 						fullWidth
// 						variant="contained"
// 						color="primary"
// 						className={classes.submit}
// 						onClick={handleRoomBaseConfigAdd}
// 					>
// 						Agregar Habitacion
// 					</Button>
// 				</form>
// 				</Card>
// 			</div>
// 		</Container>
// 	);
// }

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Button,
	Card,
	Chip,
	Container,
	CssBaseline,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { RoomBaseConfig } from '../types';
import { addRoomToRoomBaseConfig, deleteRoomBaseConfigRow } from '../slice';
import { RootState } from '../../../store';
import { getPAXTotals } from '../selectors';
import HelpTooltip from './HelpContent/HelpTooltip';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	table: {
		minWidth: 650,
	},
	sectionTitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

const initialCreateBaseConfigRoomObject: RoomBaseConfig = {
	adults: 0,
	childs: [],
	infants: [],
};

export default function RoomBaseConfigComponent(): JSX.Element {
	const classes = useStyles();
	const dispatch = useDispatch();

	const paxTotals = useSelector(getPAXTotals);

	const [createBaseConfigRoomObject, setCreateBaseConfigRoomObject] = useState<RoomBaseConfig>(
		initialCreateBaseConfigRoomObject,
	);
	const [childsAge, setChildAge] = useState<number>(0);
	const [infantAge, setInfantAge] = useState<number>(0);

	const { roomBaseConfig } = useSelector((state: RootState) => state.quotation);

	const [openDeleteRoomConfigConfirmDialog, setOpenDeleteRoomConfigConfirmDialog] = useState(false);
	const initDeleteRoomConfigPayload = -1;
	const [deleteRoomConfigPayload, setDeleteRoomConfigPayload] = useState<number>(initDeleteRoomConfigPayload);

	const handleClickOpenConfirmDeleteRoomConfig = (index: number) => () => {
		setOpenDeleteRoomConfigConfirmDialog(true);
		setDeleteRoomConfigPayload(index);
	};

	const handleCloseConfirmDeleteRoomConfig = () => {
		setOpenDeleteRoomConfigConfirmDialog(false);
	};

	const handleDeleteRoomConfig = () => {
		setOpenDeleteRoomConfigConfirmDialog(false);
		dispatch(deleteRoomBaseConfigRow(deleteRoomConfigPayload));
	};

	const handleAdultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCreateBaseConfigRoomObject((prevState) => ({
			...prevState,
			adults: parseInt(e.target.value, 10),
		}));
	};

	const handleChildAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChildAge(parseInt(e.target.value, 10));
	};

	const handleChildAdd = () => {
		if (childsAge > 0) {
			setCreateBaseConfigRoomObject((prevState) => ({
				...prevState,
				childs: [...prevState.childs, childsAge],
			}));
			setChildAge(0);
		}
	};

	const handleChildRemove = (index: number) => () => {
		setCreateBaseConfigRoomObject((prevState) => ({
			...prevState,
			childs: prevState.childs.filter((_, i) => i !== index),
		}));
	};

	const handleInfantAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInfantAge(parseInt(e.target.value, 10));
	};

	const handleInfantAdd = () => {
		if (infantAge > 0) {
			setCreateBaseConfigRoomObject((prevState) => ({
				...prevState,
				infants: [...prevState.infants, infantAge],
			}));
			setInfantAge(0);
		}
	};

	const handleInfantRemove = (index: number) => () => {
		setCreateBaseConfigRoomObject((prevState) => ({
			...prevState,
			infants: prevState.infants.filter((_, i) => i !== index),
		}));
	};

	const isValidToAddRoom = () => {
		const { adults, childs, infants } = createBaseConfigRoomObject;

		// Validar que haya al menos un adulto
		if (adults < 1) {
			return false;
		}

		// Validar que todas las edades de niños e infantes sean mayores que 0
		const areChildAgesValid = childs.every((age) => age > 0);
		const areInfantAgesValid = infants.every((age) => age > 0);

		if (!areChildAgesValid || !areInfantAgesValid) {
			return false;
		}

		return true;
	};

	const handleRoomBaseConfigAdd = () => {
		if (isValidToAddRoom()) {
			dispatch(addRoomToRoomBaseConfig(createBaseConfigRoomObject));
			setCreateBaseConfigRoomObject(initialCreateBaseConfigRoomObject);
		}
	};

	// Generar texto de resumen
	const generateSummaryText = () => {
		const { adults, childs, infants } = createBaseConfigRoomObject;
		const numAdults = adults;
		const numChilds = childs.length;
		const numInfants = infants.length;

		// Función auxiliar para manejar el plural
		const pluralize = (count: number, singular: string, plural: string) => {
			return count === 1 ? singular : plural;
		};

		const adultText = `${numAdults} ${pluralize(numAdults, 'adulto', 'adultos')}`;
		const childText =
			numChilds > 0 ? `${numChilds} ${pluralize(numChilds, 'niño', 'niños')} (de ${childs.join(', ')} años)` : '';
		const infantText =
			numInfants > 0
				? `${numInfants} ${pluralize(numInfants, 'infante', 'infantes')} (de ${infants.join(', ')} años)`
				: '';

		// Construir el resumen evitando comas innecesarias
		const summaryParts: string[] = [adultText];
		if (childText) summaryParts.push(childText);
		if (infantText) summaryParts.push(infantText);

		return `Vas a agregar una habitación con: ${summaryParts.join(', ')}. 👇`;
	};

	return (
		<Container component="main" maxWidth="md">
			<div>
				{/* Dialog para confirmar eliminación de habitación */}
				<Dialog
					open={openDeleteRoomConfigConfirmDialog}
					onClose={handleCloseConfirmDeleteRoomConfig}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{'Eliminar configuración de la habitación'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							¿Confirma eliminar la configuración de la habitación #{deleteRoomConfigPayload + 1}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={handleCloseConfirmDeleteRoomConfig}>
							Cancelar
						</Button>
						<Button variant="contained" color="primary" onClick={handleDeleteRoomConfig} autoFocus>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<CssBaseline />
			<div className={classes.paper}>
				{/* Título principal */}
				<Typography
					marginTop={2}
					component="h1"
					variant="h6"
					gutterBottom
					sx={{
						backgroundColor: 'primary.light',
						px: 2,
						py: 1,
						borderRadius: 2,
						color: 'white',
					}}
				>
					Configuración de habitaciones
					<HelpTooltip
						helpKey="roomsConfig"
						placement="bottom-end"
						iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
					/>
				</Typography>

				{/* Card que contiene la tabla de habitaciones y el formulario */}
				<Card sx={{ borderRadius: 2, padding: 2, marginTop: 1 }}>
					{/* Tabla de habitaciones existentes */}
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Room #</TableCell>
									<TableCell align="right">ADT</TableCell>
									<TableCell align="right">CHD</TableCell>
									<TableCell align="right">INF</TableCell>
									<TableCell align="right" />
								</TableRow>
							</TableHead>
							<TableBody>
								{roomBaseConfig.map((item, index) => (
									<TableRow key={index}>
										<TableCell component="th" scope="row">
											habitación {index + 1}
										</TableCell>
										<TableCell align="right">{item.adults}</TableCell>
										<TableCell align="right">
											{item.childs.length > 0 ? `${item.childs.length} (${item.childs.join(', ')} años)` : '0'}
										</TableCell>
										<TableCell align="right">
											{item.infants.length > 0 ? `${item.infants.length} (${item.infants.join(', ')} año)` : '0'}
										</TableCell>
										<TableCell align="right">
											<IconButton
												onClick={handleClickOpenConfirmDeleteRoomConfig(index)}
												color="secondary"
												component="span"
												size="large"
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}

								<TableRow>
									<TableCell component="th" scope="row">
										Total
									</TableCell>
									<TableCell align="right">{paxTotals.adults}</TableCell>
									<TableCell align="right">{paxTotals.child}</TableCell>
									<TableCell align="right">{paxTotals.infants}</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>

					{/* Subtítulo condicional */}
					<Box mt={3} mb={2}>
						{roomBaseConfig.length === 0 ? (
							<Typography variant="subtitle1" gutterBottom>
								Configura la primera habitación para avanzar!
							</Typography>
						) : (
							<Typography variant="subtitle1" gutterBottom>
								Agrega una nueva habitación a la cotización
							</Typography>
						)}
					</Box>

					{/* Formulario para agregar nueva habitación */}
					<form className={classes.form} noValidate>
						<Grid container spacing={2}>
							{/* Adultos */}
							<Grid item xs={12} sm={4}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography component="span" variant="subtitle1">
										ADT:
									</Typography>
									<TextField
										name="adults"
										variant="outlined"
										value={createBaseConfigRoomObject.adults}
										required
										id="adults"
										label="Cantidad de Adultos"
										type="number"
										size="small"
										onChange={handleAdultChange}
										sx={{ ml: 1, width: '150px' }}
									/>
								</Box>
							</Grid>
							{/* Niños */}
							<Grid item xs={12} sm={4}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography component="span" variant="subtitle1">
										CHD:
									</Typography>
									<TextField
										name="childAge"
										variant="outlined"
										required
										id="childAge"
										label="Edad"
										type="number"
										size="small"
										value={childsAge}
										onChange={handleChildAgeChange}
										sx={{ width: '100px', ml: 1, mr: 1 }}
									/>
									<Button variant="contained" color="primary" onClick={handleChildAdd}>
										Agregar
									</Button>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
									{createBaseConfigRoomObject.childs.map((age, index) => (
										<Chip key={index} label={`${age} años`} onDelete={handleChildRemove(index)} sx={{ mr: 1 }} />
									))}
								</Box>
							</Grid>

							{/* Infantes */}
							<Grid item xs={12} sm={4}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography component="span" variant="subtitle1">
										INF:
									</Typography>
									<TextField
										name="infantAge"
										variant="outlined"
										required
										id="infantAge"
										label="Edad"
										type="number"
										size="small"
										value={infantAge}
										onChange={handleInfantAgeChange}
										sx={{ width: '100px', ml: 1, mr: 1 }}
									/>
									<Button variant="contained" color="primary" onClick={handleInfantAdd}>
										Agregar
									</Button>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
									{createBaseConfigRoomObject.infants.map((age, index) => (
										<Chip key={index} label={`${age} años`} onDelete={handleInfantRemove(index)} sx={{ mr: 1 }} />
									))}
								</Box>
							</Grid>
						</Grid>

						{/* Texto resumen antes del botón */}
						{isValidToAddRoom() && (
							<Box mt={2} mb={2}>
								<Typography variant="body1" color="#ED6500">
									{generateSummaryText()}
								</Typography>
							</Box>
						)}

						{/* Botón para agregar habitación */}
						<Button
							type="button"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={handleRoomBaseConfigAdd}
							disabled={!isValidToAddRoom()} // Usar la nueva función de validación
						>
							Agregar Habitación
						</Button>
					</form>
				</Card>
			</div>
		</Container>
	);
}
