import React from 'react';
import { Card, Container, CssBaseline, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationServiceSimple } from '../../types';
import { getCityNameCleaned } from '../../helpers';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

export interface DestinationPreview {
	services?: QuotationServiceSimple[];
	cityCode: string;
	cityName: string;
	nights: number;
}

interface Props {
	destinations: DestinationPreview[];
	manualServices: QuotationServiceSimple[];
	notIncludes: string[];
	description: string;
	hasAir: boolean;
	hasHotels: boolean;
}
export const QuotationIntroPreview = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { destinations, manualServices, notIncludes, description, hasAir } = props;
	//const [introCity, setIntroCity] = useState<string>('');
	// useEffect(() => {
	// 	if (destinations?.length) {
	// 		const cityName = destinations[0] && destinations[0]?.cityName.split('-')[1];
	// 		cityName && setIntroCity(cityName);
	// 	}
	// }, [destinations]);

	const getTotalNights = (destinationCode: string) =>
		destinations.filter((x) => x.cityCode === destinationCode).reduce((a, b) => a + b.nights, 0);

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Card sx={{ padding: '16px', marginBottom: '16px' }}>
					<Typography component="h1" variant="h6">
						¡Hola!
					</Typography>

					<Typography component="p" variant="body1">
						En Mevuelo estamos emocionados de presentarte una propuesta de viaje especialmente diseñada para ofrecerte
						una experiencia excepcional.
					</Typography>
				</Card>

				<Typography
					component="h1"
					variant="h6"
					sx={{
						marginBottom: '8px',
						width: '100%',
						backgroundColor: '#31194B',
						paddingLeft: '16px',
						paddingRight: '16px',
						paddingTop: '8px',
						paddingBottom: '8px',
						borderRadius: '4px',
						fontSize: '1rem',
						color: 'white',
					}}
				>
					Incluye:
				</Typography>
				<Card sx={{ padding: '16px', marginBottom: '16px' }}>
					<ul>
						{destinations.length === 1 && hasAir && (
							<li>
								<Typography component="p" variant="body1">
									Boleto aéreo según itinerario con tasas e impuestos incluidos.
								</Typography>
							</li>
						)}
						{destinations.length > 1 && hasAir && (
							<li>
								<Typography component="p" variant="body1">
									Boleto aéreo según itinerario con tasas e impuestos incluidos.
								</Typography>
							</li>
						)}
						{props.hasHotels &&
							destinations.map((option, index) => (
								<React.Fragment key={`hotel-${index}`}>
									{destinations.findIndex((x) => x.cityCode === option.cityCode) === index && (
										<li>
											<Typography component="p" variant="body1">
												{getTotalNights(option.cityCode)} noches de alojamiento en {getCityNameCleaned(option.cityName)}
												.
											</Typography>
										</li>
									)}
									{option.services?.map((service, serviceIndex) => (
										<li key={`service-preview-${serviceIndex}`}>
											<Typography component="p" variant="body1">
												{service.name}.
											</Typography>
										</li>
									))}
								</React.Fragment>
							))}
						{manualServices.map((manualService, index) => (
							<li key={`manual-service-preview-${index}`}>
								<Typography component="p" variant="body1">
									{manualService.name}.
								</Typography>
							</li>
						))}
					</ul>
				</Card>

				{notIncludes.length > 0 && (
					<>
						<Typography
							component="h1"
							variant="h6"
							sx={{
								marginBottom: '8px',
								width: '100%',
								backgroundColor: '#31194B',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '8px',
								paddingBottom: '8px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							No incluye:
						</Typography>
						<Card sx={{ padding: '16px', marginBottom: '16px' }}>
							<ul>
								{notIncludes.map((option, index) => (
									<li key={`not-includes-preview-${index}`}>
										<Typography component="p" variant="body1">
											{option}.
										</Typography>
									</li>
								))}
							</ul>
						</Card>
					</>
				)}
				{description && description.length > 0 && (
					<>
						<Typography
							component="h1"
							variant="h6"
							sx={{
								marginBottom: '8px',
								width: '100%',
								backgroundColor: '#31194B',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '8px',
								paddingBottom: '8px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							Comentarios del Asesor:
						</Typography>
						<Card sx={{ padding: '16px', marginBottom: '16px' }}>
							<Typography style={{ whiteSpace: 'pre-line' }} component={'span'} variant={'body1'}>
								{' '}
								{description}{' '}
							</Typography>
						</Card>
					</>
				)}
			</div>
		</Container>
	);
};
