import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { DestinationSummaryForDestinationMultiple, QuotationServiceSimple } from '../../types';
import { Container } from '@mui/material';
import { getCityNameCleaned } from '../../helpers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		includeTitle: {
			fontStyle: 'normal',
			fontWeight: 700,
			letterSpacing: '0em',
			textAlign: 'left',
			color: theme.palette.secondary.main,
			marginBottom: theme.spacing(1),
		},
		textInfo: {
			fontSize: '12px',
			fontStyle: 'normal',
			fontWeight: 500,
			//lineHeight: '24px',
			float: 'left',
		},
	}),
);
interface Props {
	destinations: DestinationSummaryForDestinationMultiple[];
	manualServices: QuotationServiceSimple[];
	hasFlight: boolean;
}

export const IncludeMultiDestination = ({ destinations, manualServices, hasFlight }: Props): JSX.Element => {
	const classes = useStyles();
	const getTotalNights = (destinationCode: string) =>
		destinations.filter((x) => x.cityCode === destinationCode).reduce((a, b) => a + b.nights, 0);
	return (
		<Container disableGutters sx={{ overflow: 'auto', marginY: 2 }}>
			{/* <Typography component="h1" variant="subtitle1" className={classes.includeTitle} sx={{ paddingLeft: 2 }}>
				Incluye:
			</Typography> */}
			<ul className={classes.textInfo}>
				{hasFlight && (
					<li>
						<Typography variant="body1">Boleto aéreo con tasas e impuestos incluidos.</Typography>
					</li>
				)}
				{destinations.map((option, index) => (
					<>
						{destinations.findIndex((x) => x.cityCode === option.cityCode) === index && (
							<li key={`nights-preview-${index}`}>
								<Typography variant="body1">
									{getTotalNights(option.cityCode)} noches de alojamiento en {getCityNameCleaned(option.cityName)}.
								</Typography>
							</li>
						)}
						{option.services.map((service, serviceIndex) => (
							<li key={`service-preview-${serviceIndex}`}>
								<Typography variant="body1">
									{service.name} {service.description}.
								</Typography>
							</li>
						))}
					</>
				))}
				{manualServices.map((manualService, index) => (
					<li key={`manual-service-preview-${index}`}>
						<Typography variant="body1">
							{manualService.name} {manualService.description}.
						</Typography>
					</li>
				))}
			</ul>
		</Container>
	);
};
