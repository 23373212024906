import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	clearAutoCompleteContactData,
	setAutoCompleteContactData,
	setCrmTicket,
	setDescription,
	setEmail,
	setIsBitrixDeal,
	setName,
	setPhone,
} from '../../slice';
import Component from './GeneralConfig';
import { RootState } from 'store';
import { isOnlyDigit } from '../../helpers';
import { findContactDataByDeal } from '../../../../services';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { AutocompleteContactData } from '../../types';
import { getNimbuxCaseSummary } from '../../../common/services';

interface Props {
	onErrorOnAutocompleteContact: (error: string) => void;
	showDescriptionField: boolean;
}
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.tooltip + 1,
		color: '#fff',
	},
}));

export default function GeneralConfigCompleteQuotation(props: Props): JSX.Element {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { crmTicket, isBitrixDeal, name, email, phone, description } = useSelector(
		(state: RootState) => state.quotation,
	);
	const [loading, setLoading] = React.useState(false);

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setEmail(e.target.value));
	};
	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setName(e.target.value));
	};
	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setPhone(e.target.value));
	};
	const handleIsBitrixDealChange = (value: boolean) => {
		dispatch(setIsBitrixDeal(value));
	};
	const handleCRMTicketChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isOnlyDigit(e.target.value) && Number(e.target.value) > 0) dispatch(setCrmTicket(Number(e.target.value)));
	};

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setDescription(e.target.value));
	};

	const handleConfirmAutoCompleteContactDataByDeal = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && crmTicket > 0) {
			handleAutoCompleteNameEmailAndPhone();
		}
	};

	const handleAutoCompleteNameEmailAndPhone = async () => {
		try {
			setLoading(true);
			if (isBitrixDeal) {
				const response = await findContactDataByDeal(crmTicket);
				dispatch(setAutoCompleteContactData(response.data));
			} else {
				const response = await getNimbuxCaseSummary(crmTicket);
				if (!response.data?.client) {
					props.onErrorOnAutocompleteContact('El caso no tiene cliente asociado, por favor asocie un cliente al caso');
					clearAutoCompleteContactData();
				} else {
					const client = response.data.client;
					if (!client.email) {
						props.onErrorOnAutocompleteContact('Por favor complete el email del cliente');
						clearAutoCompleteContactData();
					} else {
						const autocompleteContactData = {
							name: `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim(),
							email: client.email,
							phone: client.completePhoneNumber,
						} as AutocompleteContactData;
						dispatch(setAutoCompleteContactData(autocompleteContactData));
					}
				}
			}
			setLoading(false);
		} catch (error) {
			const errorMessage =
				error['response'] && error['response']['data'] && error['response']['data']['message']
					? error['response']['data']['message']
					: 'No se pudo obtener los datos de contacto';
			setLoading(false);
			props.onErrorOnAutocompleteContact(errorMessage);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Component
				onEmailChange={handleEmailChange}
				onCRMTicketChange={handleCRMTicketChange}
				onIsBitrixDealChange={handleIsBitrixDealChange}
				onPhoneChange={handlePhoneChange}
				onNameChange={handleNameChange}
				onConfirmAutoCompleteDataByDeal={handleConfirmAutoCompleteContactDataByDeal}
				crmTicket={crmTicket}
				isBitrixDeal={isBitrixDeal}
				name={name}
				email={email}
				phone={phone}
				descripcion={description}
				onDescriptionChange={handleDescriptionChange}
				showDescriptionField={props.showDescriptionField}
			/>
		</>
	);
}
