// slice.ts

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	Air,
	AutocompleteContactData,
	Destination,
	DestinationToAdd,
	HotelOptions,
	ManualService,
	Quotation,
	RoomBaseConfig,
	Service,
} from './types';
import { getQuotation, postQuotation, putQuotation } from './services';
import { ApiError } from '../auth/types';
import { getHotelDetail } from '../hotels/components/services';
import { AxiosResponse } from 'axios';
import { HotelDetail } from '../hotels/types';
import { getRates } from 'features/stats/services';
import axios from 'axios';

export const initialQuotation: Quotation = {
	id: 0,
	crmTicket: 0,
	isBitrixDeal: true,
	description: '',
	name: '',
	email: '',
	phone: '',
	roomBaseConfig: [],
	notIncludes: [],
	manualServices: [],
	sign: false,
	destination: [],
	airs: [],
	quotations: [],
	markup: 0,
	loading: false,
	wasCopied: false,
	showLocalCurrency: false,
	exchangeRate: 1,
	localCurrency: '',
};

// UpdtateExchangeRate
export const updtateExchangeRate = createAsyncThunk<
	{ exchangeRate: number; localCurrency: string },
	any,
	{ rejectValue: ApiError }
>('quotations/updateExchangeRate', async (_payload, thunkApi) => {
	try {
		const dateToday = new Date();
		const myMonth = dateToday.getMonth() + 1;
		const toRenderMonth = String(myMonth).length === 1 ? `0${myMonth}` : myMonth;
		const day = dateToday.getDate() < 10 ? `0${dateToday.getDate()}` : dateToday.getDate();
		const dateFormat = `${dateToday.getFullYear()}-${toRenderMonth}-${day}`;
		const response = await getRates();
		const valueRate = response.data.ratesList.find((rate) => rate.date === dateFormat);
		return {
			exchangeRate: valueRate?.values[0] ? valueRate.values[0].fee : 1,
			localCurrency: valueRate?.values[0] ? valueRate.values[0].coinCode : 'n/a',
		};
	} catch (error: unknown) {
		if (axios.isAxiosError(error)) {
			return thunkApi.rejectWithValue(error.response?.data as ApiError);
		} else {
			return thunkApi.rejectWithValue({ message: 'An unexpected error occurred.' } as ApiError);
		}
	}
});

// SaveQuotation
export const saveQuotation = createAsyncThunk<{ id: string }, Quotation, { rejectValue: ApiError }>(
	'quotations/save',
	async (payload, thunkApi) => {
		try {
			const response = await postQuotation(payload);
			return response.data;
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				return thunkApi.rejectWithValue(error.response?.data as ApiError);
			} else {
				return thunkApi.rejectWithValue({ message: 'An unexpected error occurred.' } as ApiError);
			}
		}
	},
);

// UpdateQuotation
export const updateQuotation = createAsyncThunk<Quotation, Quotation, { rejectValue: ApiError }>(
	'quotations/update',
	async (payload, thunkApi) => {
		try {
			const response = await putQuotation(payload);
			return response.data;
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				return thunkApi.rejectWithValue(error.response?.data as ApiError);
			} else {
				return thunkApi.rejectWithValue({ message: 'An unexpected error occurred.' } as ApiError);
			}
		}
	},
);

// GetQuotationById
export const getQuotationById = createAsyncThunk<Quotation, number, { rejectValue: ApiError }>(
	'quotations/getById',
	async (payload, thunkApi) => {
		try {
			const response = await getQuotation(payload);
			const quotation = response.data;
			const destinations: Destination[] = [];
			for (const destination of quotation.destination) {
				const promisesHotelDetail: Promise<AxiosResponse<HotelDetail>>[] = [];
				destination.hotelOptions.forEach((hotelOption) => {
					promisesHotelDetail.push(getHotelDetail(hotelOption.hotelId, hotelOption.provider));
				});
				const resultPromise = await Promise.all(promisesHotelDetail);
				const hotelOptions: HotelOptions[] = [];
				destination.hotelOptions.forEach((hotelOption, indexHotelOption) => {
					hotelOptions.push({
						...hotelOption,
						details: resultPromise[indexHotelOption].data,
					} as HotelOptions);
				});
				destinations.push({
					...destination,
					hotelOptions: hotelOptions,
				} as Destination);
			}

			return {
				...quotation,
				destination: destinations,
			} as Quotation;
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				return thunkApi.rejectWithValue(error.response?.data as ApiError);
			} else {
				return thunkApi.rejectWithValue({ message: 'An unexpected error occurred.' } as ApiError);
			}
		}
	},
);

export const quotationSlice = createSlice({
	name: 'quotation',
	initialState: initialQuotation,
	reducers: {
		toggleShowLocalCurrency: (state) => {
			state.showLocalCurrency = !state.showLocalCurrency;
		},
		clearQuotation: (state) => {
			state.id = 0;
			state.crmTicket = 0;
			state.description = '';
			state.name = '';
			state.email = '';
			state.phone = '';
			state.roomBaseConfig = [];
			state.notIncludes = [];
			state.manualServices = [];
			state.sign = false;
			state.destination = [];
			state.airs = [];
			state.quotations = [];
			state.markup = 0;
			state.loading = false;
			state.showLocalCurrency = false;
		},
		setCrmTicket: (state, { payload }: PayloadAction<number>) => {
			state.crmTicket = payload;
		},
		setIsBitrixDeal: (state, { payload }: PayloadAction<boolean>) => {
			state.isBitrixDeal = payload;
			state.name = '';
			state.phone = '';
			state.email = '';
			state.crmTicket = 0;
		},
		setSign: (state, { payload }: PayloadAction<boolean>) => {
			state.sign = payload;
		},
		setAutoCompleteContactData: (state, { payload }: PayloadAction<AutocompleteContactData>) => {
			state.name = payload.name;
			state.phone = payload?.phone || '';
			state.email = payload?.email || '';
		},
		clearAutoCompleteContactData: (state) => {
			state.name = '';
			state.phone = '';
			state.email = '';
		},
		setName: (state, { payload }: PayloadAction<string>) => {
			state.name = payload;
		},
		setEmail: (state, { payload }: PayloadAction<string | null>) => {
			state.email = payload;
		},
		setDescription: (state, { payload }: PayloadAction<string>) => {
			state.description = payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setPhone: (state, { payload }: PayloadAction<string>) => {
			state.phone = payload;
		},
		setMarkup: (state, { payload }: PayloadAction<string>) => {
			state.markup = Number(payload);
		},
		addRoomToRoomBaseConfig: (state, { payload }: PayloadAction<RoomBaseConfig>) => {
			state.roomBaseConfig = [...state.roomBaseConfig, payload];
		},
		addAirConfig: (state, { payload }: PayloadAction<Air>) => {
			state.airs = [...state.airs, payload];
		},
		refreshAirConfig: (state, { payload }: PayloadAction<Air[]>) => {
			state.airs = [...payload];
		},
		deleteAllAirsConfig: (state) => {
			state.airs = [];
		},
		deleteAirConfig: (state, { payload }: PayloadAction<number>) => {
			const newAirs = [...state.airs];
			newAirs.splice(payload, 1);
			state.airs = newAirs;
		},
		// **Nueva Acción para Actualizar un Vuelo**
		updateAirConfig: (state, { payload }: PayloadAction<{ index: number; air: Air }>) => {
			const { index, air } = payload;
			if (state.airs[index]) {
				state.airs[index] = air;
			}
		},
		deleteRoomBaseConfigRow: (state, { payload }: PayloadAction<number>) => {
			const newRoomBaseConfig = [...state.roomBaseConfig];
			newRoomBaseConfig.splice(payload, 1);
			const totalRooms = newRoomBaseConfig.length;
			state.roomBaseConfig = newRoomBaseConfig;

			const newDestination = [...state.destination];
			if (totalRooms === 0) {
				newDestination.forEach((value, index, array) => {
					array[index].hotelOptions = [];
				});
			} else {
				newDestination.forEach((value, index, array) => {
					array[index].hotelOptions = array[index].hotelOptions.map((hotelOption) => {
						const rooms = [...hotelOption.rooms];
						rooms.splice(payload, 1);
						hotelOption.rooms = rooms;
						return hotelOption;
					});
				});
			}

			state.destination = newDestination;
		},
		changeShowOptionalServiceInDestination: (
			state,
			{ payload }: PayloadAction<{ destinationIndex: number; optionalServiceIndex: number }>,
		) => {
			const newDestination = [...state.destination];
			newDestination[payload.destinationIndex].optionalServices[payload.optionalServiceIndex].show =
				!newDestination[payload.destinationIndex].optionalServices[payload.optionalServiceIndex].show;
			state.destination = newDestination;
		},
		addDestinationItem: (state, { payload }: PayloadAction<DestinationToAdd>) => {
			state.destination = [
				...state.destination,
				{
					...payload,
					services: [],
					hotelOptions: [],
				},
			];
		},
		removeAllDestinations: (state) => {
			state.destination = [];
		},
		setDestinations: (state, { payload }: PayloadAction<Destination[]>) => {
			state.destination = [...payload];
		},
		addHotelOptionToDestination: (
			state,
			{ payload }: PayloadAction<{ destinationIndex: number; data: HotelOptions }>,
		) => {
			const newDestination = [...state.destination];
			newDestination[payload.destinationIndex].hotelOptions.push(payload.data);
			state.destination = newDestination;
		},
		setHotelOptionsToDestination: (
			state,
			{ payload }: PayloadAction<{ destinationIndex: number; data: HotelOptions[] }>,
		) => {
			const newDestination = [...state.destination];
			newDestination[payload.destinationIndex].hotelOptions = payload.data;
			state.destination = newDestination;
		},
		removeHotelOptionsFromDestinations: (state) => {
			const newDestination = [...state.destination];
			newDestination.forEach((value, index) => (newDestination[index].hotelOptions = []));
			state.destination = newDestination;
		},
		addServiceOptionToDestination: (state, { payload }: PayloadAction<{ destinationIndex: number; data: Service }>) => {
			const newDestination = [...state.destination];
			newDestination[payload.destinationIndex].services.push(payload.data);
			state.destination = newDestination;
		},
		deleteServiceOptionToDestination: (
			state,
			{ payload }: PayloadAction<{ destinationIndex: number; serviceOptionIndex: number }>,
		) => {
			const newDestination = [...state.destination];
			const newServices = [...newDestination[payload.destinationIndex].services];
			newServices.splice(payload.serviceOptionIndex, 1);
			newDestination[payload.destinationIndex].services = newServices;
			state.destination = newDestination;
		},
		deleteHotelOptionToDestination: (
			state,
			{ payload }: PayloadAction<{ destinationIndex: number; hotelOptionIndex: number }>,
		) => {
			const newDestination = [...state.destination];
			const newHotelOptions = [...newDestination[payload.destinationIndex].hotelOptions];
			newHotelOptions.splice(payload.hotelOptionIndex, 1);
			newDestination[payload.destinationIndex].hotelOptions = newHotelOptions;
			state.destination = newDestination;
		},
		addNotInclude: (state, { payload }: PayloadAction<string>) => {
			const newNotIncluded = [...state.notIncludes];
			newNotIncluded.push(payload);
			state.notIncludes = newNotIncluded;
		},
		setNotIncludes: (state, { payload }: PayloadAction<string[]>) => {
			state.notIncludes = [...payload];
		},
		deleteNotInclude: (state, { payload }: PayloadAction<number>) => {
			const newNotIncluded = [...state.notIncludes];
			newNotIncluded.splice(payload, 1);
			state.notIncludes = newNotIncluded;
		},
		addManualService: (state, { payload }: PayloadAction<ManualService>) => {
			const newManualServices = [...state.manualServices];
			newManualServices.push(payload);
			state.manualServices = newManualServices;
		},
		deleteManualService: (state, { payload }: PayloadAction<number>) => {
			const newManualServices = [...state.manualServices];
			newManualServices.splice(payload, 1);
			state.manualServices = newManualServices;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(saveQuotation.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(saveQuotation.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(saveQuotation.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(updateQuotation.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(updateQuotation.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(updateQuotation.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(updtateExchangeRate.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(updtateExchangeRate.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(updtateExchangeRate.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.exchangeRate = payload.exchangeRate;
			state.localCurrency = payload.localCurrency;
		});
		builder.addCase(getQuotationById.pending, (state) => {
			state.loading = true;
			state.destination = [];
		});
		builder.addCase(getQuotationById.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getQuotationById.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.agentId = payload.agentId;
			state.id = payload.id;
			state.name = payload.name;
			state.crmTicket = payload.crmTicket;
			state.email = payload.email;
			state.phone = payload.phone;
			state.description = payload.description;
			state.airs = payload.airs;
			state.destination = payload.destination;
			state.roomBaseConfig = payload.roomBaseConfig;
			state.quotations = payload.quotations;
			state.markup = payload.markup;
			state.notIncludes = payload.notIncludes;
			state.manualServices = payload.manualServices;
			state.sign = payload.sign;
			state.wasCopied = payload.wasCopied;
			state.showLocalCurrency = payload.showLocalCurrency;
		});
	},
});

export const {
	setName,
	setCrmTicket,
	setSign,
	setAutoCompleteContactData,
	clearAutoCompleteContactData,
	setDescription,
	setEmail,
	setIsBitrixDeal,
	setPhone,
	setMarkup,
	addRoomToRoomBaseConfig,
	addAirConfig,
	deleteAllAirsConfig,
	deleteAirConfig,
	refreshAirConfig,
	updateAirConfig, // Asegúrate de exportar la nueva acción
	deleteRoomBaseConfigRow,
	changeShowOptionalServiceInDestination,
	addDestinationItem,
	removeAllDestinations,
	setDestinations,
	addHotelOptionToDestination,
	setHotelOptionsToDestination,
	removeHotelOptionsFromDestinations,
	addServiceOptionToDestination,
	deleteServiceOptionToDestination,
	deleteHotelOptionToDestination,
	addNotInclude,
	deleteNotInclude,
	addManualService,
	deleteManualService,
	clearQuotation,
	setLoading,
	setNotIncludes,
	toggleShowLocalCurrency,
} = quotationSlice.actions;

export const { reducer, actions } = quotationSlice;

export const quotationReducer = quotationSlice.reducer;
