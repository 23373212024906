import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, TooltipProps, IconButtonProps } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { helpContents } from './HelpContents';

// Definimos la interfaz para TypeScript
interface HelpTooltipProps {
	helpKey: string;
	placement?: TooltipProps['placement'];
	// Agregamos la nueva prop que contendrá cualquier propiedad
	// válida de IconButton, incluyendo sx, color, etc.
	iconButtonProps?: IconButtonProps;
}

const HelpTooltip: React.FC<HelpTooltipProps> = ({ helpKey, placement = 'top', iconButtonProps }) => {
	const content = helpContents[helpKey] || 'Contenido de ayuda no encontrado.';

	return (
		<Tooltip
			title={content}
			placement={placement}
			componentsProps={{
				tooltip: {
					sx: {
						maxWidth: 600, // Por ejemplo, 300px
						// backgroundColor: '#f5f5f5',
						// color: '#333',
						whiteSpace: 'pre-line', // para manejar saltos de línea
					},
				},
			}}
		>
			{/* Pasamos "iconButtonProps" al IconButton */}
			<IconButton color="info" size="medium" {...iconButtonProps}>
				<HelpOutlineIcon />
			</IconButton>
		</Tooltip>
	);
};

// Declaramos la prop en PropTypes para que ESLint no se queje
HelpTooltip.propTypes = {
	helpKey: PropTypes.string.isRequired,
	placement: PropTypes.oneOf([
		'bottom-end',
		'bottom-start',
		'bottom',
		'left-end',
		'left-start',
		'left',
		'right-end',
		'right-start',
		'right',
		'top-end',
		'top-start',
		'top',
	]),
	// iconButtonProps puede ser un objeto de cualquier forma
	// (podrías refinarlo más, pero en PropTypes se suele usar "object")
	iconButtonProps: PropTypes.object,
};

export default HelpTooltip;
