import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { calculateUtility } from '../helpers';

const useStyles = makeStyles((theme) => ({
	utility: {
		color: theme.palette.info.dark,
		fontWeight: 'light',
	},
}));

interface Props {
	show: boolean;
	price: number;
	markup: number | null;
}

export const Utility = (props: Props): JSX.Element => {
	const classes = useStyles();
	return props.show && props.markup != null ? (
		<Typography component={'span'} variant={'body2'} className={classes.utility}>
			mb: {calculateUtility(props.price, props.markup)}{' '}
		</Typography>
	) : (
		<></>
	);
};
