import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertColor, Box, Fab, Snackbar } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SaveIcon from '@mui/icons-material/Save';
import makeStyles from '@mui/styles/makeStyles';
import { useReactToPrint } from 'react-to-print';

import { Header } from 'features/quotation/components/Header';
import { RootState } from 'store';
import { Layout } from 'features/common/components/Layout';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'constants/routes';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
	createFlightSummariesPriceForQuotationOnlyFlights,
	extractErrorMessage,
	isNotFoundError,
	isValidQuotationOnlyFlightsToSendClient,
	needRefreshAirs,
} from '../../features/quotation/helpers';
import { IFlightFinalPriceSummary, QuotationOnlyFlights } from '../../features/quotation/types';
import { ContentCopy } from '@mui/icons-material';
import { CopyQuotationDialog } from '../../features/quotation/components/CopyQuotationDialog';
import { getQuotationDefaultDescription, getQuotationDefaultNotIncludesList } from '../../features/quotation/services';
import DestinationConfigQuotationOnlyFlights from '../../features/quotation/components/DestinationConfig/DestinationConfigQuotationOnlyFlights';
import {
	clearQuotationOnlyFlights,
	refreshAirConfigQuotationOnlyFlights,
	saveQuotationOnlyFlights,
	setDescriptionQuotationOnlyFlights,
	setLoadingQuotationOnlyFlights,
	setNotIncludesQuotationOnlyFlights,
	updateQuotationOnlyFlights,
} from '../../features/quotation/sliceOnlyFlights';
import GeneralConfigQuotationOnlyFlights from '../../features/quotation/components/GeneralConfig/GeneralConfigQuotationOnlyFlights';
import PassengerConfig from '../../features/quotation/components/PassengerConfig';
import AirConfigQuotationOnlyFlights from '../../features/quotation/components/AirConfig/AirConfigQuotationOnlyFlights';
import { NotIncludesQuotationOnlyFlights } from '../../features/quotation/components/NotIncludes/NotIncludesQuotationOnlyFlights';
import QuotationPreviewOnlyFlights from '../../features/quotation/components/QuotationOnlyFlightsPreview';
import { QuotationOnlyFlightsMarkup } from '../../features/quotation/components/QuotationOnlyFlightsMarkup';
import { SendQuotationOnlyFlightToClient } from '../../features/quotation/components/SendQuotationOnlyFlightToClient';
import { updtateExchangeRate } from 'features/quotation/slice';

const useStyles = makeStyles((theme) => ({
	root: {
		lexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		lexGrow: 1,
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	countrySelector: {
		marginLeft: theme.spacing(3),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

function QuotationsNewOnlyFlights(): JSX.Element {
	const classes = useStyles();
	const [previewMode, setPreviewMode] = React.useState(false);
	const [openSendToClient, setOpenSendToClient] = React.useState(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const componentRef = useRef(null);
	const [isValidQuotationToClient, setIsValidQuotationToClient] = useState(false);
	const dispatch = useDispatch();
	const quotation = useSelector((state: RootState) => state.quotation);
	const { exchangeRate, localCurrency } = quotation;
	const history = useHistory();
	const location = useLocation();
	const { quotationOnlyFlights, auth } = useSelector((state: RootState) => state);
	const { showLocalCurrency } = quotationOnlyFlights;
	const [openDialogCopyQuotation, setOpenDialogCopyQuotation] = React.useState(false);
	const [idToCopy, setIdToCopy] = useState(-1);
	const [idQuotationToShowDetailOnCopy, setIdQuotationToShowDetailOnCopy] = useState(-1);
	const [flightIndexSelected, setFlightIndexSelected] = useState<number | null>(0);
	const [flightsFinalPriceSummaries, setFlightsFinalPriceSummaries] = useState<IFlightFinalPriceSummary[]>([]);
	const handleClear = () => {
		dispatch(clearQuotationOnlyFlights());
		getDefaultDescription();
		getDefaultNotIncludes();
	};

	useEffect(() => {
		dispatch(updtateExchangeRate({}));
	}, [showLocalCurrency, dispatch]);

	const getDefaultDescription = async () => {
		try {
			dispatch(setLoadingQuotationOnlyFlights(true));
			const defaultDescription = await getQuotationDefaultDescription();
			dispatch(setDescriptionQuotationOnlyFlights(defaultDescription.data.description));
			dispatch(setLoadingQuotationOnlyFlights(false));
		} catch (error) {
			if (!isNotFoundError(error)) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al cargar la descripción por defecto'),
				});
			}
			dispatch(setLoadingQuotationOnlyFlights(false));
		}
	};

	const getDefaultNotIncludes = async () => {
		try {
			dispatch(setLoadingQuotationOnlyFlights(true));
			const defaultNotIncludes = (await getQuotationDefaultNotIncludesList()).data;
			dispatch(setNotIncludesQuotationOnlyFlights(defaultNotIncludes.map((x) => x.notInclude)));
			dispatch(setLoadingQuotationOnlyFlights(false));
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los no incluye por defecto'),
			});
			dispatch(setLoadingQuotationOnlyFlights(false));
		}
	};

	useEffect(() => {
		if (location.pathname == routes.quotations.newOnlyFlights) handleClear();
	}, []);

	const redirectToEdit = (id: number) => {
		history.push(routes.quotations.editOnlyFlights.replace(':id', String(id)));
	};

	useEffect(() => {
		setIsValidQuotationToClient(isValidQuotationOnlyFlightsToSendClient(quotationOnlyFlights));
	}, [
		quotationOnlyFlights.id,
		quotationOnlyFlights.airs,
		quotationOnlyFlights.destination,
		quotationOnlyFlights.passengerConfig,
	]);

	useEffect(() => {
		if (idQuotationToShowDetailOnCopy > 0) {
			const timer = setTimeout(() => {
				redirectToEdit(idQuotationToShowDetailOnCopy);
			}, 1200);
			return () => clearTimeout(timer);
		}
	}, [idQuotationToShowDetailOnCopy]);

	useMemo(() => {
		if (quotationOnlyFlights.airs.length > 0) {
			if (flightIndexSelected == null) {
				setFlightIndexSelected(0);
			} else if (flightIndexSelected == quotationOnlyFlights.airs.length) {
				setFlightIndexSelected(quotationOnlyFlights.airs.length - 1);
			}
		} else {
			setFlightIndexSelected(null);
		}
	}, [quotationOnlyFlights.airs]);

	useEffect(() => {
		setFlightsFinalPriceSummaries(createFlightSummariesPriceForQuotationOnlyFlights(quotationOnlyFlights));
	}, [quotationOnlyFlights.airs, quotationOnlyFlights.markup, quotationOnlyFlights.passengerConfig]);

	const handleTogglePreview = () => setPreviewMode((prevState) => !prevState);

	const handleShowSendToClient = () => setOpenSendToClient((prevState) => !prevState);

	const handleCloseSendToClient = () => setOpenSendToClient(false);

	const handleOnSuccessSendToClient = (message: string) => {
		handleCloseSendToClient();
		setAlert({ show: true, severity: 'success', message: message });
	};

	const handleOnErrorSendToClient = (error: string) => {
		handleCloseSendToClient();
		setAlert({ show: true, severity: 'error', message: error });
	};

	const handleOnError = (error: string) => {
		setAlert({ show: true, severity: 'error', message: error });
	};

	const handleSave = async () => {
		const agentId = auth.user?.id;
		const finalQuotation = {
			...quotationOnlyFlights,
			email: quotationOnlyFlights.email && quotationOnlyFlights.email !== '' ? quotationOnlyFlights.email : null,
			agentId,
			showLocalCurrency,
		};

		const result =
			quotationOnlyFlights.id == 0
				? await dispatch(saveQuotationOnlyFlights(finalQuotation))
				: await dispatch(updateQuotationOnlyFlights(finalQuotation));
		// @ts-ignore
		if (result['error']) {
			// @ts-ignore
			if (result['payload'] && result['payload']['message']) {
				// @ts-ignore
				setAlert({ show: true, severity: 'error', message: result['payload']['message'] });
			} else {
				setAlert({
					show: true,
					severity: 'error',
					message: 'Ocurrió un error al guardar la cotización',
				});
			}
		} else {
			setAlert({
				show: true,
				severity: 'success',
				message: 'Cotización guardada satisfactoriamente',
			});
			if (quotationOnlyFlights.id == 0) {
				// @ts-ignore
				redirectToEdit(result.payload);
			} else {
				// @ts-ignore
				const quotationFromServer = result.payload as unknown as QuotationOnlyFlights;
				if (needRefreshAirs(quotation.airs)) dispatch(refreshAirConfigQuotationOnlyFlights(quotationFromServer.airs));
			}
		}
	};

	const handleCloseCopyQuotationDialog = () => setOpenDialogCopyQuotation(false);

	const handleSuccessCopyQuotation = (id: number) => {
		handleCloseCopyQuotationDialog();
		setAlert({ show: true, severity: 'success', message: 'Cotización copiada satisfactoriamente' });
		setIdQuotationToShowDetailOnCopy(id);
	};

	const handleClickOnCopyQuotation = () => {
		setIdToCopy(quotationOnlyFlights.id);
		setOpenDialogCopyQuotation(true);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	return (
		<Layout>
			<>
				<Backdrop className={classes.backdrop} open={quotationOnlyFlights.loading}>
					<CircularProgress color="inherit" />
				</Backdrop>

				{openDialogCopyQuotation && (
					<CopyQuotationDialog
						quotationIdToCopy={idToCopy}
						isOnlyFlights={true}
						open={openDialogCopyQuotation && idToCopy > 0}
						onClose={handleCloseCopyQuotationDialog}
						onSuccessCopyQuotation={handleSuccessCopyQuotation}
					/>
				)}

				{quotationOnlyFlights.id > 0 && (
					<Box zIndex="tooltip" position="fixed" bottom={280} right={30}>
						<Fab
							size="small"
							color="secondary"
							aria-label="Copiar cotización"
							title={'Copiar cotización '}
							onClick={handleClickOnCopyQuotation}
						>
							<ContentCopy />
						</Fab>
					</Box>
				)}
				<Box zIndex="tooltip" position="fixed" bottom={230} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Guardar cotización"
						title={'Guardar cotización'}
						onClick={handleSave}
					>
						<SaveIcon />
					</Fab>
				</Box>
				<Box zIndex="tooltip" position="fixed" bottom={180} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Borrar datos de cotización"
						title={'Borrar datos de cotización'}
						onClick={handleClear}
					>
						<BackspaceIcon />
					</Fab>
				</Box>
				<Box zIndex="tooltip" position="fixed" bottom={70} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Previsualizar cotización"
						title={'Previsualizar cotización'}
						onClick={handleTogglePreview}
					>
						<VisibilityIcon />
					</Fab>
				</Box>

				<Box zIndex="tooltip" position="fixed" bottom={130} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Imprimir cotización"
						title={'Imprimir cotización'}
						onClick={handlePrint}
					>
						<PictureAsPdfIcon />
					</Fab>
				</Box>

				{isValidQuotationToClient && (
					<Box zIndex="tooltip" position="fixed" bottom={20} right={30}>
						<Fab
							size="small"
							color="secondary"
							aria-label="Enviar al cliente"
							title={'Enviar al cliente'}
							onClick={handleShowSendToClient}
						>
							<WhatsAppIcon />
						</Fab>
					</Box>
				)}
				<div ref={componentRef}>
					<Header />
					{previewMode ? (
						<QuotationPreviewOnlyFlights
							exchangeRate={exchangeRate}
							localCurrency={localCurrency}
							showLocalCurrency={showLocalCurrency}
							description={quotationOnlyFlights.description}
							passengerConfig={quotationOnlyFlights.passengerConfig}
							destinations={quotationOnlyFlights.destination}
							notIncludes={quotationOnlyFlights.notIncludes}
							countryCode={auth.country}
							flightIndexSelected={flightIndexSelected}
							showFlightSelectionControl={quotationOnlyFlights.airs.length > 1}
							handleFlightSelectionChange={setFlightIndexSelected}
							flightFinalPriceSummaries={flightsFinalPriceSummaries}
							airs={quotationOnlyFlights.airs}
						/>
					) : (
						<>
							<GeneralConfigQuotationOnlyFlights
								onErrorOnAutocompleteContact={handleOnError}
								showDescriptionField={true}
							/>
							<DestinationConfigQuotationOnlyFlights onError={handleOnError} />
							<PassengerConfig />
							<AirConfigQuotationOnlyFlights
								exchangeRate={exchangeRate}
								localCurrency={localCurrency}
								showLocalCurrency={showLocalCurrency}
								flightFinalPriceSummaries={flightsFinalPriceSummaries}
								indexSelected={flightIndexSelected}
								showSelectionControl={quotationOnlyFlights.airs.length > 1}
								handleSelectionChange={setFlightIndexSelected}
							/>
							<NotIncludesQuotationOnlyFlights />
							<QuotationOnlyFlightsMarkup />
						</>
					)}

					<Snackbar open={alert.show} autoHideDuration={1000} onClose={() => setAlert(defaultAlertState)}>
						<Alert variant="filled" severity={alert.severity}>
							{alert.message}
						</Alert>
					</Snackbar>

					{isValidQuotationToClient && (
						<SendQuotationOnlyFlightToClient
							open={openSendToClient}
							onClose={handleCloseSendToClient}
							onSuccess={handleOnSuccessSendToClient}
							onError={handleOnErrorSendToClient}
						/>
					)}
				</div>
			</>
		</Layout>
	);
}

export default QuotationsNewOnlyFlights;
