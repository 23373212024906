import React, { useState, useEffect } from 'react';
import {
	Box,
	Card,
	CardContent,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	Button,
	Dialog,
	TextField,
	DialogContent,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
	createPendingPayMessageSchedule,
	getPendingPayMessageSchedule,
} from '../../../../../features/salesOrder/services';
import { ISaleOrderPendingPayMessageScheduleList } from '../../../../../features/salesOrder/types';
import { ShowAlertState } from '../../../../../features/common/types';
import { extractErrorMessage } from '../../../../../features/quotation/helpers';
import SaleOrderPendingPayMessageScheduleStatus from './SaleOrderPendingPayMessageScheduleStatus';

interface SaleOrderPendingPayMessageScheduleProps {
	saleOrderId: string;
	onSetAlert: (values: ShowAlertState) => void;
}

const SaleOrderPendingPayMessageSchedule = ({
	saleOrderId,
	onSetAlert,
}: SaleOrderPendingPayMessageScheduleProps): JSX.Element => {
	const [pendingPayMessageSchedule, setPendingPayMessageSchedule] = useState<ISaleOrderPendingPayMessageScheduleList[]>(
		[],
	);
	const [loading, setLoading] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

	useEffect(() => {
		fetchPendingPayMessageSchedules();
	}, [saleOrderId]);

	const fetchPendingPayMessageSchedules = async (): Promise<void> => {
		setLoading(true);
		try {
			const result = await getPendingPayMessageSchedule(saleOrderId);
			setPendingPayMessageSchedule(result.data);
		} catch (error) {
			onSetAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(
					error,
					'Ocurrió un error al cargar la programación de envío de los mensajes de WhatsApp por saldo.',
				),
			});
		} finally {
			setLoading(false);
		}
	};

	const handleDateChange = (newDate: Date | null): void => {
		setSelectedDate(newDate);
	};

	const handleOpenDatePicker = (): void => {
		setOpenDatePicker(true);
	};

	const handleCloseDatePicker = (): void => {
		setOpenDatePicker(false);
	};

	const handleReprogram = async (): Promise<void> => {
		if (!selectedDate || !saleOrderId) return;

		setLoading(true);

		try {
			handleCloseDatePicker();
			await createPendingPayMessageSchedule(saleOrderId, { dateToSend: selectedDate.toISOString().split('T')[0] });
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Reprogramación creada satisfactorimente',
			});
			await fetchPendingPayMessageSchedules();
		} catch (error) {
			onSetAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al guardar la reprogramación.'),
			});
		} finally {
			setLoading(false);
			handleCloseDatePicker();
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Box mt={2}>
				<Card elevation={4}>
					<CardContent>
						<Typography variant="h6">WhatsApp x saldo</Typography>
						{loading ? (
							<Typography>Cargando envíos...</Typography>
						) : (
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Fecha</TableCell>
											<TableCell>Status</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{pendingPayMessageSchedule.map((notification) => (
											<TableRow key={notification.dateToSend}>
												<TableCell>{notification.dateToSend}</TableCell>
												<TableCell>
													<SaleOrderPendingPayMessageScheduleStatus
														status={notification.status}
														created={notification.created}
														createdBy={notification.createdBy}
														executed={notification.executed}
														observation={notification.observation}
													/>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
						<Button style={{ marginTop: '8px' }} variant="outlined" color="primary" onClick={handleOpenDatePicker}>
							Reprogramar
						</Button>
						<Dialog open={openDatePicker} onClose={handleCloseDatePicker}>
							<DialogContent sx={{ margin: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
								<DatePicker
									label="Nueva fecha para el envío mensaje"
									value={selectedDate}
									minDate={new Date()}
									onChange={handleDateChange}
									renderInput={(params) => <TextField {...params} />}
								/>
								<Button variant="outlined" color="primary" onClick={handleReprogram}>
									Guardar nueva fecha
								</Button>
							</DialogContent>
						</Dialog>
					</CardContent>
				</Card>
			</Box>
		</LocalizationProvider>
	);
};

export default SaleOrderPendingPayMessageSchedule;
