const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const SelectMenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const pageSizeByDefault = 10;
export const pageSizeOptions = [5, pageSizeByDefault, 15, 20];
export const pageSizeLargeOptions = [...pageSizeOptions, 30, 50];

// @ts-ignore
export const GOOGLE_MAPS_KEY: string = process.env.REACT_APP_GMAP_API_KEY;
// @ts-ignore
export const CIBERSOURCE_URL: string = process.env.REACT_APP_CIBERSOURCE_URL;
export const REACT_APP_SALES_ORDER_ACTIVE: string = process.env.REACT_APP_SALES_ORDER_ACTIVE || 'Off';

export const NIMBUX_CRM_APP_BASE_URL = process.env.NIMBUX_CRM_APP_BASE_URL || 'https://base.nimbux.tech/';

export const customNanoIdAlphabet = '0123456789abcdefghijklmnopqrstuvwxyz';

export const countryWAPMapping = {
	PY: '595992228058',
	BO: '59162244778',
	CO: '573142926276',
	TN: '595984729300',
	UY: '59897683318',
};
