import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { quotationReducer } from 'features/quotation/slice';
import { quotationOnlyFlightsReducer } from 'features/quotation/sliceOnlyFlights';
import { reducer as authReducer } from 'features/auth/slice';
import { paymentReducer } from 'features/payment/slice';
import { initAuthInterceptors } from '../features/auth/services/AuthService';

export const rootReducer = combineReducers({
	quotation: quotationReducer,
	quotationOnlyFlights: quotationOnlyFlightsReducer,
	auth: authReducer,
	payment: paymentReducer,
});

const persistConfig = {
	key: 'root-v6',
	version: 6,
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

initAuthInterceptors(store);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export default store;
