import React from 'react';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import routes from '../../../../constants/routes';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ShowAlertState } from '../../../common/types';
import { matchSaleRepresentatives } from '../../services';
import { extractErrorMessage } from '../../../quotation/helpers';

enum SyncOptions {
	ViewSyncClients = 'VIEW_SYNC_CLIENTS',
	ViewSyncSalesOrders = 'VIEW_SYNC_SALES_ORDERS',
	MatchSalesReps = 'MATCH_SALES_REPS',
}

interface Props {
	onSetAlert: (values: ShowAlertState) => void;
	setLoading: (loading: boolean) => void;
}
const SyncProcessNetsuiteOptionsMenu = ({ onSetAlert, setLoading }: Props): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleViewSyncCustomers = () => {
		const newRoute = routes.salesOrder.customersSyncStatus;
		const baseUrl = window.location.origin;
		window.open(`${baseUrl}${newRoute}`, '_blank');
	};

	const handleViewSyncSalesOrders = () => {
		const newRoute = routes.salesOrder.salesOrderSyncStatus;
		const baseUrl = window.location.origin;
		window.open(`${baseUrl}${newRoute}`, '_blank');
	};

	const handleMatchSalesRepresentatives = async () => {
		try {
			setLoading(true);
			await matchSaleRepresentatives();
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Macheo realizado  satisfactorimente',
			});
		} catch (error) {
			onSetAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(
					error,
					'Ocurrió un error al realizar el macheo de representantes de ventas. Por favor, inténtalo de nuevo.',
				),
			});
		} finally {
			setLoading(false);
		}
	};

	const handleMenuItemClick = (option: SyncOptions) => {
		switch (option) {
			case SyncOptions.ViewSyncClients:
				handleViewSyncCustomers();
				break;
			case SyncOptions.ViewSyncSalesOrders:
				handleViewSyncSalesOrders();
				break;
			case SyncOptions.MatchSalesReps:
				handleMatchSalesRepresentatives();
				break;
			default:
				console.warn('Opción de sincronización no reconocida');
		}
		handleClose();
	};

	return (
		<>
			<Button
				variant="contained"
				size="large"
				color="primary"
				title={'Opciones de sincronización con Netsuite'}
				startIcon={<SyncAltIcon />}
				endIcon={<ArrowDropDownIcon />}
				onClick={handleClick}
				aria-controls={open ? 'sync-process-netsuite-options-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			></Button>
			<Menu
				id="sync-process-netsuite-options-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'sync-process-netsuite-options-button',
				}}
			>
				<MenuItem onClick={() => handleMenuItemClick(SyncOptions.ViewSyncClients)}>
					<ListItemIcon>
						<PeopleIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Ver estado de proceso de sincronización de clientes con Netsuite</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => handleMenuItemClick(SyncOptions.ViewSyncSalesOrders)}>
					<ListItemIcon>
						<ReceiptLongIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Ver estado de proceso de sincronización de Sales Order con Netsuite</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => handleMenuItemClick(SyncOptions.MatchSalesReps)}>
					<ListItemIcon>
						<GroupWorkIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Machear representantes de ventas de Netsuite con usuarios de Mevuelo</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

export default SyncProcessNetsuiteOptionsMenu;
