import React from 'react';
import { Box, Switch, Typography } from '@mui/material';

interface BitrixDealOrCaseSwitchProps {
	isBitrixDeal: boolean;
	onChange: () => void;
}

const BitrixDealOrCaseSwitch: React.FC<BitrixDealOrCaseSwitchProps> = ({
	isBitrixDeal,
	onChange,
}: BitrixDealOrCaseSwitchProps) => {
	return (
		<Box>
			<Box display="flex" justifyContent="space-between" width="300px" alignItems="center">
				<Typography variant="body1">Es un Deal de Bitrix</Typography>
				<Switch checked={isBitrixDeal} onChange={onChange} color="primary" />
			</Box>
			<Box display="flex" justifyContent="space-between" width="300px" alignItems="center">
				<Typography variant="body1">Es un caso</Typography>
				<Switch checked={!isBitrixDeal} onChange={onChange} color="primary" />
			</Box>
		</Box>
	);
};

export default BitrixDealOrCaseSwitch;
