import React from 'react';
import { FormHelperText, useTheme } from '@mui/material';

interface Props {
	isBitrixDeal: boolean;
}

const BitrixDealOrCaseUserInfoConfirm: React.FC<Props> = ({ isBitrixDeal }: Props) => {
	const theme = useTheme();
	return (
		<FormHelperText sx={{ color: theme.palette.info.main }}>
			Inserte {isBitrixDeal ? 'el Deal ID' : 'el Caso'} y presione enter
		</FormHelperText>
	);
};

export default BitrixDealOrCaseUserInfoConfirm;
