import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RootState } from 'store';
import { setMarkup, setSign, toggleShowLocalCurrency, updtateExchangeRate } from '../slice';
import { QuotationFinalPricePreview } from './QuotationPreview/QuotationFinalPricePreview';
import { DestinationSimple, QuotationDestinationMultipleContainer, QuotationServiceSimple } from '../types';
import { GetPAXTotalsResponse } from '../helpers';
import { MultiSelectHotelsMultiDestination } from './MultiSelectHotelsMultiDestination';
import { CountryCodeEnum } from 'features/common/types';
import HelpTooltip from './HelpContent/HelpTooltip';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
	optionTableHolder: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
}));
interface Props {
	isMultiDestination: boolean;
	destinationsForSimpleDestination: DestinationSimple[] | null;
	containerMultiDestination: QuotationDestinationMultipleContainer | null;
	paxTotals: GetPAXTotalsResponse;
	manualServices: QuotationServiceSimple[];
	showUtility: boolean;
	flightIndexSelected: number | null;
	handleFlightSelectionChange?: (index: number | null) => void;
	localCurrency: string;
}

export const QuotationResult = (props: Props): JSX.Element => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const quotation = useSelector((state: RootState) => state.quotation);
	const { auth } = useSelector((state: RootState) => state);
	const { markup, showLocalCurrency, localCurrency, exchangeRate } = quotation;
	const handleMarkupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setMarkup(e.target.value));
	};
	const handleChangeSign = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setSign(event.target.checked));
	};

	const handleShowLocalCurrency = () => {
		dispatch(toggleShowLocalCurrency());
	};
	useEffect(() => {
		dispatch(updtateExchangeRate({}));
	}, [showLocalCurrency, dispatch]);

	return (
		<Container component="main" maxWidth="md">
			<div className={classes.paper}>
				{!props.isMultiDestination && props.destinationsForSimpleDestination && (
					<Typography
						marginTop={2}
						component="h1"
						variant="h6"
						gutterBottom
						sx={{
							backgroundColor: 'primary.light',
							px: 2,
							py: 1,
							borderRadius: 2,
							color: 'white',
						}}
					>
						Presupuesto
						<HelpTooltip
							helpKey="quotationResult"
							placement="bottom-end"
							iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
						/>
					</Typography>
				)}
				{props.isMultiDestination && props.containerMultiDestination && (
					<Typography
						marginTop={2}
						component="h1"
						variant="h6"
						gutterBottom
						sx={{
							backgroundColor: 'primary.light',
							px: 2,
							py: 1,
							borderRadius: 2,
							color: 'white',
						}}
					>
						Selecciona el alojamiento para cada destino
						<HelpTooltip
							helpKey="quotationResultMulti"
							placement="bottom-end"
							iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
						/>
					</Typography>
				)}
				<Card sx={{ borderRadius: 2, p: 2, mt: 1 }}>
					<Grid container spacing={2} pb={4}>
						<Grid item xs={12} sm={4}>
							<TextField
								value={markup}
								variant="standard"
								label="markup"
								size="small"
								onChange={handleMarkupChange}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Switch checked={showLocalCurrency} onChange={handleShowLocalCurrency} />
							Ver precio local
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControlLabel
								control={<Switch checked={quotation.sign} name="sign" onChange={handleChangeSign} />}
								label={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
							/>
						</Grid>
					</Grid>
				</Card>
				{!props.isMultiDestination && props.destinationsForSimpleDestination && (
					<QuotationFinalPricePreview
						exchangeRate={exchangeRate}
						localCurrency={localCurrency}
						showLocalCurrency={showLocalCurrency}
						destinations={props.destinationsForSimpleDestination}
						paxTotals={props.paxTotals}
						showUtility={props.showUtility}
						markup={markup}
						totalFlights={quotation.airs.length}
						flightIndexSelected={props.flightIndexSelected}
						quotationId={quotation.id}
					/>
				)}
				{props.isMultiDestination && props.containerMultiDestination && (
					<MultiSelectHotelsMultiDestination
						exchangeRate={exchangeRate}
						localCurrency={localCurrency}
						showLocalCurrency={showLocalCurrency}
						destinations={props.containerMultiDestination.destinations}
						priceCombinations={props.containerMultiDestination.priceCombinations}
						manualServices={props.manualServices}
						paxTotals={props.paxTotals}
						showUtility={props.showUtility}
						markup={markup}
						totalFlights={quotation.airs.length}
						flightIndexSelected={props.flightIndexSelected}
						handleFlightSelectionChange={props.handleFlightSelectionChange}
						quotationId={quotation.id}
					/>
				)}
			</div>
		</Container>
	);
};
