import React from 'react';

import { CountryCodeEnum } from '../../../common/types';

import MevueloUyFooter from './MevueloUyFooter';
import MevueloPyFooter from './MevueloPyFooter';
import MevueloBoFooter from './MevueloBoFooter';
import MevueloCoFooter from './MevueloCoFooter';
import TiendaNaranjaPyFooter from './TiendanaranjaPyFooter';
interface Props {
	country: string;
	isMultiDestination?: boolean;
}

export default function Footer({ country }: Props): JSX.Element {
	switch (country) {
		case CountryCodeEnum.URUGUAY:
			return <MevueloUyFooter />;
		case CountryCodeEnum.PARAGUAY:
			return <MevueloPyFooter />;
		case CountryCodeEnum.BOLIVIA:
			return <MevueloBoFooter />;
		case CountryCodeEnum.COLOMBIA:
			return <MevueloCoFooter />;
		case CountryCodeEnum.TN_ITAU_PY:
			return <TiendaNaranjaPyFooter />;
		default:
			return <MevueloUyFooter />;
	}
}
