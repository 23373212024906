import React from 'react';
import CustomAccordion from './index';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Typography } from '@mui/material';

export const QuotationsConditions: React.FC = () => {
	const { country } = useSelector((state: RootState) => state.auth);

	const getPaymentContent = (country: string) => {
		switch (country) {
			case 'UY':
				return (
					<Typography>
						Los pagos se realizan en dólares estadounidenses (USD). El tipo de cambio es definido por Mevuelo y puede
						variar sin previo aviso. Los pagos con tarjeta de crédito pueden ser procesados en una o más transacciones
						(&quot;spliteados&quot;), sumando el monto total, y podrían cargarse contra Mevuelo o directamente contra
						proveedores como aerolíneas o hoteles.
					</Typography>
				);

			case 'PY':
			case 'TN': // Tienda Naranja es igual a Paraguay
				return (
					<Typography>
						Los pagos se realizan principalmente en dólares estadounidenses (USD), pero también se aceptan pagos en
						guaraníes al tipo de cambio establecido por Mevuelo. Los pagos con tarjeta de crédito son siempre procesados
						en guaraníes y contra Mevuelo o Tienda Naranja Viajes.
					</Typography>
				);

			case 'BO':
				return (
					<Typography>
						Los pagos se realizan principalmente en dólares estadounidenses (USD) o en bolivianos al tipo de cambio
						establecido por Mevuelo. Los pagos con tarjeta de crédito son siempre procesados en bolivianos y contra
						Mevuelo o Tienda Naranja Viajes.
					</Typography>
				);

			case 'CO':
				return (
					<Typography>
						La moneda principal es el peso colombiano (COP). Todas las transacciones, salvo transferencias
						internacionales o pagos en efectivo, se realizan en esta moneda. Los pagos con tarjeta de crédito son
						siempre procesados en pesos colombianos.
					</Typography>
				);

			default:
				return <Typography>Información de pagos no disponible para este país.</Typography>;
		}
	};

	const data = (country: string) => [
		{
			title: 'Introducción',
			content: (
				<Typography>
					&quot;Mevuelo&quot; y &quot;Tienda Naranja Viajes&quot; son nombres comerciales utilizados para ofrecer
					servicios turísticos. Operamos bajo distintas empresas localmente en cada mercado o de forma internacional,
					según corresponda. A continuación, nos referiremos a &quot;Mevuelo&quot; para mayor simplicidad.
				</Typography>
			),
		},
		{
			title: 'Cómo adquirir servicios',
			content: (
				<Typography>
					Para realizar una reserva, el cliente deberá proporcionar los datos requeridos (incluyendo documentos de los
					pasajeros) y seleccionar o indicarle a su asesor el/los método/s de pago deseado. Mevuelo actúa como
					intermediario, gestionando la solicitud del cliente con el proveedor correspondiente (aerolíneas, hoteles,
					operadores turísticos, etc.). Las condiciones finales dependen del proveedor y están sujetas a disponibilidad,
					cambios en precios, rutas y tarifas hasta la confirmación de la compra.
					<br />
					Las reservas no tienen costo ni compromiso, pero son válidas únicamente por el día de generación. Luego de
					este período, deberán ser cotizadas nuevamente sin garantía de mantener las mismas condiciones. Mevuelo no
					asume responsabilidad por cambios o cancelaciones realizadas por los proveedores, como aerolíneas o hoteles.
				</Typography>
			),
		},
		{
			title: 'Pagos y Moneda',
			content: (
				<>
					{getPaymentContent(country)}
					<Typography sx={{ marginTop: '8px' }}>
						Mevuelo procesa los pagos con tarjeta de crédito a través de proveedores externos, utilizando únicamente
						links de pago seguros. Nunca solicitaremos datos de tarjetas de crédito a través de WhatsApp ni
						telefónicamente. Contamos con certificación PCI DSS, que garantiza los más altos estándares internacionales
						de seguridad en el procesamiento, almacenamiento y transmisión de datos de pago.
					</Typography>
				</>
			),
		},
		{
			title: 'Documentación',
			content: (
				<Typography>
					Es responsabilidad del cliente verificar la vigencia y validez de la documentación necesaria para viajar
					(pasaporte, visas, certificados de vacunación, permisos de menores, entre otros). Mevuelo no se hace
					responsable por denegaciones de embarque o entrada a destinos por documentación incompleta o incorrecta.
					<br />
					Recomendamos que los documentos tengan al menos 6 meses de vigencia desde la fecha de salida.
				</Typography>
			),
		},
		{
			title: 'Cambios y devoluciones',
			content: (
				<Typography>
					Una vez emitidos los boletos o confirmados los servicios, las modificaciones o cancelaciones estarán sujetas a
					las políticas del proveedor correspondiente (aerolíneas, hoteles, operadores, etc.), las cuales pueden incluir
					penalidades, multas o la pérdida total de la tarifa. Mevuelo aplicará gastos administrativos adicionales por
					gestiones de cambio, cancelación o reembolso.
				</Typography>
			),
		},
		{
			title: 'Qué no incluye',
			content: (
				<Typography>
					Las tarifas no incluyen tasas turísticas locales, resort fees, extras en hoteles, propinas, alimentación no
					mencionada, ni otros servicios opcionales. Tu serás responsable de pagar estos costos directamente en el
					destino.
				</Typography>
			),
		},
		{
			title: 'Responsabilidades del cliente',
			content: (
				<Typography>
					Mevuelo actúa como intermediario y no es responsable por cambios, cancelaciones o inconvenientes relacionados
					con los servicios proporcionados por terceros (aerolíneas, hoteles, etc.). No asumimos responsabilidad por
					eventos de fuerza mayor como desastres naturales, fenómenos climáticos, o huelgas.
				</Typography>
			),
		},
		{
			title: 'Protección de datos',
			content: (
				<Typography>
					Al utilizar los servicios de Mevuelo, autorizas el uso de tus datos personales y de tus acompañantes para
					gestionar la reserva, enviar comunicaciones relacionadas y ofrecer promociones personalizadas. Mevuelo
					garantiza la confidencialidad de los datos personales, en cumplimiento con la legislación vigente.
				</Typography>
			),
		},
		{
			title: 'Aceptación',
			content: (
				<Typography>
					Al avanzar con una reserva o compra, declaras que aceptas estos términos y condiciones en su totalidad,
					permitiendo además el uso de tus datos de contacto para la gestión de servicios y comunicaciones.
				</Typography>
			),
		},
	];

	return (
		<div>
			<CustomAccordion
				title="Términos aburridos, pero importantes:"
				subtitle="*** Por favor, léelos antes de avanzar 🧐 ***"
				items={data(country)}
			/>
		</div>
	);
};

export default QuotationsConditions;
