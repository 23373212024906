import React, { useEffect, useMemo, useState } from 'react';
import {
	Alert,
	Backdrop,
	Button,
	ButtonGroup,
	Checkbox,
	CircularProgress,
	Grid,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterListData,
	SelectFilterModelSimple,
	ShowAlertState,
} from 'features/common/types';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	getBankAccountsList,
	getBranchOfficesList,
	getCashTransactionList,
	getCashTransactionListToExport,
	getCashTransactionLogs,
	getCoinsList,
} from 'features/transactions/services';
import {
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import { extractErrorMessage } from 'features/quotation/helpers';
import {
	IBankAccountsList,
	IBranchOfficesList,
	ICashTransactionList,
	ICashTransactionLog,
} from 'features/transactions/types';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeOptions } from 'constants/constants';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CashTransactionLogsModal } from '../CashTransactionLogsModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { MoveCashModal } from '../MoveCashModal';
import { PageNoResult } from '../../../common/components/PageNoResults';
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		maxWidth: '90px',
	},
}));

const headerCells: IHeadCell[] = [
	{
		field: 'id',
		sortable: true,
		headerName: '#',
	},
	{
		field: 'crmTicket',
		sortable: true,
		headerName: 'Deal o Caso',
	},
	{
		field: 'isBitrixDeal',
		sortable: true,
		headerName: 'Es un Deal',
	},
	{
		field: 'coinCode',
		sortable: true,
		headerName: 'Moneda',
	},
	{
		field: 'amount',
		sortable: true,
		headerName: 'Monto',
	},
	{
		field: 'branchOfficeName',
		sortable: false,
		headerName: 'Sucursal',
	},
	{
		field: 'bankAccountName',
		sortable: false,
		headerName: 'Cuenta bancaria',
	},
	{
		field: 'amountInUSD',
		sortable: true,
		headerName: 'Monto en USD',
	},
	{
		field: 'arbitration',
		sortable: true,
		headerName: 'Arbitraje',
	},
	{
		field: 'observations',
		sortable: true,
		headerName: 'Observaciones',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de creación',
	},
	{
		field: 'createdBy',
		sortable: true,
		headerName: 'Creado por',
	},
];

interface Props {
	handleMakeMoveCash: () => void;
}

export const CashTransactionList = ({ handleMakeMoveCash }: Props): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'createdAt', order: 'desc' }]);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [cashTransactionList, setCashTransactionList] = useState<ICashTransactionList[] | null>(null);
	const [coinsList, setCoinsList] = useState<SelectFilterListData[]>([]);
	const [bankAccountsList, setBankAccountsList] = useState<IBankAccountsList[]>([]);
	const [branchOfficesList, setBranchOfficesList] = useState<IBranchOfficesList[]>([]);
	const [logsList, setLogsList] = useState<ICashTransactionLog[]>([]);
	const [openModalLogs, setOpenModalLogs] = useState<boolean>(false);
	const [openModalMoveCash, setOpenModalMoveCash] = useState<boolean>(false);
	const [cashTransactionIdsSelected, setCashTransactionIdsSelected] = useState<number[]>([]);

	const filters = [
		{ label: 'ID', type: FilterType.STRING, key: 'id' },
		{
			label: 'Deal o Caso',
			type: FilterType.NUMERIC,
			key: 'crmTicket',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Es un Deal',
			type: FilterType.BOOLEAN,
			key: 'isBitrixDeal',
			operator: OperatorFilterEnum.EQUALS,
		},
		new SelectFilterModelSimple('Moneda', 'coinId', coinsList),
		{ label: 'Monto', type: FilterType.NUMERIC, key: 'amount' },
		{ label: 'Monto desde', type: FilterType.NUMERIC, key: 'amountFrom' },
		{ label: 'Monto hasta', type: FilterType.NUMERIC, key: 'amountTo' },
		new SelectFilterModelSimple('Sucursal', 'branchOfficeId', branchOfficesList),
		new SelectFilterModelSimple('Cuenta bancaria', 'bankAccountId', bankAccountsList),
		{ label: 'Monto en USD', type: FilterType.NUMERIC, key: 'amountInUSD' },
		{ label: 'Monto en USD desde', type: FilterType.NUMERIC, key: 'amountInUSDFrom' },
		{ label: 'Monto en USD hasta', type: FilterType.NUMERIC, key: 'amountInUSDTo' },
		{ label: 'Arbitraje', type: FilterType.NUMERIC, key: 'arbitration' },
		{
			label: 'Observaciones ',
			type: FilterType.STRING,
			key: 'observations',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{ label: 'Fecha de Creación', type: FilterType.DATE, key: 'createdAt' },
		new DateToFromFilterModel('La Fecha de Creación', 'createdAtFrom', 'createdAtTo'),
		{
			label: 'Creado por',
			type: FilterType.STRING,
			key: 'createdBy',
			operator: OperatorFilterEnum.CONTAINS,
		},
	];
	//Obtengo el listado de las Monedas
	const getCoins = async () => {
		try {
			setLoading(true);
			const coinsCashList = (await getCoinsList()).data;
			setCoinsList(coinsCashList.map((x) => ({ id: x.id, name: x.code })));
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las moneda'),
			});
			setLoading(false);
		}
	};
	//Obtengo el listado de las cuentas bancarias
	const getBankList = async () => {
		try {
			setLoading(true);
			const banksAccounts = (await getBankAccountsList()).data;
			setBankAccountsList(banksAccounts);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las cuentas bancarias'),
			});
			setLoading(false);
		}
	};
	//Obtengo el listado de las Sucursales
	const getBranchOffices = async () => {
		try {
			setLoading(true);
			const branchOffice = (await getBranchOfficesList()).data;
			setBranchOfficesList(branchOffice);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las sucursales'),
			});
			setLoading(false);
		}
	};

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const sortableFields = getSortableFields(headerCells);

	const transformValue = (key: string, value: any, row: ICashTransactionList) => {
		switch (key) {
			case 'createdAt':
				return formatDateTimeToShowUser(row.createdAt);
			case 'observations':
				return row.observations ? row.observations : '-';
			case 'isBitrixDeal':
				return row.isBitrixDeal ? 'Si' : 'No';
			case 'branchOfficeName':
				return row.branchOfficeName ? row.branchOfficeName : '-';
			case 'bankAccountName':
				return row.bankAccountName ? row.bankAccountName : '-';
			default:
				return value;
		}
	};
	//obtengo la data de las transacciones cash
	const loadCashTransactionList = async () => {
		try {
			setCashTransactionIdsSelected([]);
			setLoading(true);
			const envelopedTransactionList = (
				await getCashTransactionList(currentPage - 1, currentSize, sortFields, convertFiltersToRecord(filterApplied))
			).data;
			setPageTotal(getEnvelopedListPageTotal(envelopedTransactionList));
			setCashTransactionList([...envelopedTransactionList.data]);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de transacciones'),
			});
			setLoading(false);
		}
	};
	useEffect(() => {
		loadCashTransactionList();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	useEffect(() => {
		getCoins();
	}, []);

	useEffect(() => {
		getBankList();
	}, []);

	useEffect(() => {
		getBranchOffices();
	}, []);

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			setCashTransactionIdsSelected([]);
			const envelopedCashTransactionList = (
				await getCashTransactionListToExport(sortFields, convertFiltersToRecord(filterApplied))
			).data;
			const cashTransactionList = envelopedCashTransactionList.data;
			const optionsToExport = {
				title: 'Listado de transacciones cash',
				headers: headerCells,
				widthColumns: [15, 20, 20, 20, 20, 30, 50, 20, 20, 50, 30, 50],
				filename: 'Listado de transferencias bancariasListado de transacciones cash',
			} as ExportExcelOptions;
			exportToExcel(cashTransactionList, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const handleClickViewLogs = async (id: number) => {
		try {
			setLoading(true);
			const logs = (await getCashTransactionLogs(id)).data;
			setLogsList(logs);
			setOpenModalLogs(logs.length > 0);
			if (logs.length == 0) {
				setAlert({
					show: true,
					severity: 'info',
					message: 'La transacción no tiene movimientos relacionados',
				});
			}
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al obtener el listado de los'),
			});
			setLoading(false);
		}
	};
	const handleClickSelectRow = (id: number) => {
		const indexOfId = cashTransactionIdsSelected.indexOf(id);
		if (indexOfId > -1) {
			const newCashTransactionIdsSelected = [...cashTransactionIdsSelected];
			newCashTransactionIdsSelected.splice(indexOfId, 1);
			setCashTransactionIdsSelected(newCashTransactionIdsSelected);
		} else {
			setCashTransactionIdsSelected([...cashTransactionIdsSelected, id]);
		}
	};

	const handleCloseMoveCashModal = (wasConfirmOperation: boolean) => {
		setOpenModalMoveCash(false);
		if (wasConfirmOperation) {
			handleMakeMoveCash();
			loadCashTransactionList();
			setAlert({
				show: true,
				severity: 'success',
				message: 'Movimiento realizado satisfactoriamente',
			});
		}
	};

	const handleClickMakeCashMovement = () => {
		if (cashTransactionIdsSelected.length > 0) {
			setOpenModalMoveCash(true);
		}
	};

	const totalByCoinCodeAndSummaryInUSDOfRowSelected: Record<string, number> | undefined = useMemo(() => {
		const rows = cashTransactionList?.filter((row) => cashTransactionIdsSelected.includes(row.id));
		return rows?.reduce((r, a) => {
			// @ts-ignore
			r['Total USD'] = Number(((r['Total USD'] || 0) + a.amountInUSD).toFixed(2));
			// @ts-ignore
			r[a.coinCode] = Number(((r[a.coinCode] || 0) + a.amount).toFixed(2));
			return r;
		}, {});
	}, [cashTransactionIdsSelected]);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid filters={filters} handleAppliedFilters={handleApplyFilters}></FilterGrid>
					</Grid>
					<Grid item xs={1}>
						<ButtonGroup className={classes.btnContainer}>
							<ExportToExcelButton
								disabled={cashTransactionList?.length == 0}
								handleExportToExcel={handleExportToExcel}
							/>
							<Button
								onClick={handleClickMakeCashMovement}
								disabled={loading || cashTransactionIdsSelected.length == 0}
								variant="contained"
								size="small"
								startIcon={<ArrowForwardIcon />}
								sx={{ height: 40, width: 30 }}
								title={'Mover cash'}
							/>
						</ButtonGroup>
					</Grid>
				</Grid>

				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHeader} align="center"></TableCell>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
									<TableCell className={classes.tableHeader} align="center">
										{' '}
										Logs
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && cashTransactionList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									cashTransactionList?.map((cashTransaction) => (
										<TableRow key={cashTransaction.id}>
											<TableCell padding="checkbox">
												{cashTransaction.branchOfficeName != null && (
													<Checkbox
														checked={cashTransactionIdsSelected.some((x) => x == cashTransaction.id)}
														onClick={() => handleClickSelectRow(cashTransaction.id)}
													/>
												)}
											</TableCell>
											<TableCell align="center" component="p">
												{cashTransaction.id}
											</TableCell>
											<TableCell align="center" component="p">
												{cashTransaction.crmTicket}
											</TableCell>
											<TableCell align="center" component="p">
												{transformValue('isBitrixDeal', cashTransaction.isBitrixDeal, cashTransaction)}
											</TableCell>
											<TableCell align="center" component="p">
												{cashTransaction.coinCode}
											</TableCell>
											<TableCell align="center" component="p">
												{cashTransaction.amount}
											</TableCell>
											<TableCell align="center" component="p">
												{transformValue('branchOfficeName', cashTransaction.branchOfficeName, cashTransaction)}
											</TableCell>
											<TableCell align="center" component="p">
												{transformValue('bankAccountName', cashTransaction.bankAccountName, cashTransaction)}{' '}
											</TableCell>
											<TableCell align="center" component="p">
												{cashTransaction.amountInUSD}
											</TableCell>
											<TableCell align="center" component="p">
												{cashTransaction.arbitration}
											</TableCell>
											<TableCell align="center" component="p">
												{' '}
												{transformValue('observations', cashTransaction.observations, cashTransaction)}
											</TableCell>
											<TableCell align="center" component="p">
												{transformValue('createdAt', cashTransaction.createdAt, cashTransaction)}
											</TableCell>
											<TableCell align="center" component="p">
												{cashTransaction.createdBy}
											</TableCell>
											<TableCell component="p">
												<Button
													title={'Ver Logs'}
													startIcon={<VisibilityIcon />}
													onClick={() => handleClickViewLogs(cashTransaction.id)}
													sx={{ height: 40, ml: 1 }}
												></Button>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={5} marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
						{totalByCoinCodeAndSummaryInUSDOfRowSelected &&
							Object.keys(totalByCoinCodeAndSummaryInUSDOfRowSelected).length > 0 && (
								<Grid item xs={12} md={4} marginTop={1}>
									<Table>
										<TableBody>
											<TableRow>
												{Object.keys(totalByCoinCodeAndSummaryInUSDOfRowSelected).map((key, indexItem) => {
													return (
														<TableCell
															padding={'none'}
															style={{ width: 50, border: 0 }}
															key={`${indexItem}-coin-summary`}
														>
															<Typography component="p" fontWeight={'bold'}>
																{key}
															</Typography>
															<Typography fontWeight={'bold'} variant="body2">
																{totalByCoinCodeAndSummaryInUSDOfRowSelected[key]}
															</Typography>
														</TableCell>
													);
												})}
											</TableRow>
										</TableBody>
									</Table>
								</Grid>
							)}
					</Grid>
				</Grid>
			</Grid>
			{openModalLogs && (
				<CashTransactionLogsModal open={openModalLogs} data={logsList} onCancel={() => setOpenModalLogs(false)} />
			)}
			{openModalMoveCash && (
				<MoveCashModal
					open={openModalMoveCash}
					cashTransactionIds={cashTransactionIdsSelected}
					onClose={handleCloseMoveCashModal}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={2000} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
