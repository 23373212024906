import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { OptionalService } from '../../types';
import { GetPAXTotalsResponse } from '../../helpers';
import { Box, Button, Link } from '@mui/material';
import { renderDescription } from '../../helpers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		media: {
			height: 0,
			paddingTop: '56.25%', // 16:9
		},
		title: {
			color: theme.palette.primary.main,
			fontWeight: 'normal',
			paddingBottom: '10px',
			fontSize: '1.25rem',
		},
		prices: {
			display: 'flex',
			alignItems: 'left',
			flexDirection: 'column',
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(1),
			placeContent: 'space-evenly',
		},
		pricesText: {
			color: '#F84932',
			fontWeight: 'bold',
		},
		flex: {
			display: 'flex',
		},
		priceRow: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	}),
);
interface Props {
	optionalService: OptionalService;
	paxTotals: GetPAXTotalsResponse;
}

export const OptionalServiceCardMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { optionalService, paxTotals } = props;
	const { description } = optionalService;
	const { isHtml, content } = renderDescription(description);
	return (
		<Card>
			<CardMedia className={classes.media} image={optionalService.imageUrl} title={optionalService.name} />
			<div>
				<CardContent>
					<Typography component="h1" variant="h6" color="primary" className={classes.title}>
						{optionalService.name}
					</Typography>
					<div className={classes.flex}>
						<div
							style={{
								fontFamily: 'Montserrat, sans-serif',
								color: '#272727',
								fontSize: '0.875rem',
								lineHeight: '1.43',
							}}
						>
							<style>
								{`
          							ul {
            						padding-left: 12px;
									color: '#272727',
          							}
          							ul li {
            						font-size: 0.875rem;
            						line-height: 1.43;
          							}
        						`}
							</style>
							{isHtml ? (
								<div dangerouslySetInnerHTML={{ __html: content }} />
							) : (
								<Typography style={{ whiteSpace: 'pre-line' }} component={'span'} variant={'body2'}>
									{content}
								</Typography>
							)}
						</div>
					</div>
				</CardContent>

				<div className={classes.prices}>
					{optionalService.adultPrice && (
						<div className={classes.priceRow}>
							<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
								Precio por adulto:
							</Typography>
							<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
								USD {optionalService.adultPrice}
							</Typography>
						</div>
					)}
					{paxTotals.child > 0 && optionalService.childPrice != undefined && (
						<div className={classes.priceRow}>
							<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
								Precio por niño:
							</Typography>
							<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
								USD {optionalService.childPrice}
							</Typography>
						</div>
					)}
					{paxTotals.infants > 0 && optionalService.infantPrice != undefined && (
						<div className={classes.priceRow}>
							<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
								Precio por bebé:
							</Typography>
							<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
								USD {optionalService.infantPrice}
							</Typography>
						</div>
					)}
				</div>

				{optionalService?.url !== undefined && (
					<Box display="flex" justifyContent="center" alignItems="center">
						<Link
							href={optionalService?.url}
							color="inherit"
							target="_blank"
							rel="noopener"
							variant="h5"
							className={classes.title}
						>
							<Button variant="outlined">Ver detalles</Button>
						</Link>
					</Box>
				)}
			</div>
		</Card>
	);
};
