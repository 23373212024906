import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LocationOn from '@mui/icons-material/LocationOn';
import Rating from '@mui/material/Rating';
import Chip from '@mui/material/Chip';
import PoolIcon from '@mui/icons-material/Pool';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WifiIcon from '@mui/icons-material/Wifi';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PoweredByGoogle from 'assets/poweredByGoogle.png';
import { Button } from '@mui/material';
import { openInNewTabHotelUrl } from '../../../quotation/helpers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		details: {
			display: 'flex',
			flexDirection: 'column',
			maxWidth: '67%',
		},
		content: {
			flex: '1 0 auto',
		},
		cover: {
			width: '33%',
		},
		facilities: {
			display: 'flex',
			alignItems: 'center',
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			placeContent: 'space-evenly',
		},
		flex: {
			display: 'flex',
		},
		map: {
			width: '30%',
		},
		ratingMolecule: {
			display: 'flex',
			alignItems: 'center',
			paddingTop: theme.spacing(1),
		},
		ratingText: {
			paddingLeft: theme.spacing(2),
		},
		powered: {
			paddingLeft: theme.spacing(2),
			width: '115px',
		},
		hotelStars: {
			display: 'flex',
			paddingTop: theme.spacing(1),
		},
		webSiteContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
	}),
);
interface Props {
	title: string;
	address: string;
	category: number;
	rating: number | undefined;
	ratingText: string | undefined;
	commentsCount: number | undefined;
	link: string | undefined;
	mainImage: string;
	pool: boolean;
	airConditioning: boolean;
	wifi: boolean;
	gym: boolean;
	parking: boolean;
}

export const HotelCardDesktop = (props: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<div>
			<Card className={classes.root}>
				<CardMedia className={classes.cover} image={props.mainImage} title={props.title} />
				<div className={classes.details}>
					<CardContent className={classes.content}>
						<Typography component="h5" variant="h5">
							{props.title}
						</Typography>
						<div className={classes.flex}>
							<LocationOn color="primary" />
							<Typography variant="subtitle1" color="textSecondary">
								{props.address}
							</Typography>
						</div>
						<div className={classes.hotelStars}>
							<Typography>Categoría: </Typography>
							<Rating name="read-only" value={props.category} readOnly />
						</div>
						{props?.rating && (
							<div className={classes.ratingMolecule}>
								<Chip label={parseFloat(props.rating.toFixed(2))} color="secondary" />
								<div className={classes.ratingText}>
									<Typography variant="h6">{props.ratingText}</Typography>
									<Typography variant="subtitle2">{props.commentsCount} opiniones</Typography>
								</div>
								<img className={classes.powered} src={PoweredByGoogle} />
							</div>
						)}
						{props?.link != null && props?.link != '' && (
							<div className={classes.webSiteContainer}>
								<Button
									variant="contained"
									color="primary"
									size="small"
									style={{ fontSize: '12px', fontStyle: 'normal', textTransform: 'none' }}
									onClick={() => openInNewTabHotelUrl(props?.link || '')}
								>
									Sitio web
								</Button>
							</div>
						)}
					</CardContent>
					<div className={classes.facilities}>
						{props.pool && <PoolIcon color="primary" />}
						{props.wifi && <WifiIcon color="primary" />}
						{props.airConditioning && <AcUnitIcon color="primary" />}
						{props.parking && <LocalParkingIcon color="primary" />}
						{props.gym && <FitnessCenterIcon color="primary" />}
					</div>
				</div>
			</Card>
		</div>
	);
};
