import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	CssBaseline,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Snackbar,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';

import { RootState } from 'store';
import { hotelSuggestion } from 'services';
import { addHotelOptionToDestination, deleteHotelOptionToDestination, setHotelOptionsToDestination } from '../../slice';
import { getPAXTotals } from '../../selectors';
import { getHotelDetail } from '../../../hotels/components/services';
import {
	calculateRoomCostByPax,
	createBoardsKey,
	createHotelFareRequestBase,
	customBoards,
	customRates,
	findCustomBoard,
	findCustomRate,
	getBoardName,
	getCityNameCleaned,
	minPriceInRooms,
} from '../../helpers';
import { AccommodationFaresRequest, HotelOptions, RoomFare } from '../../types';
import { FarePickerDialog, FareSelected } from '../FarePickerDialog';
import HelpTooltip from '../HelpContent/HelpTooltip';
import { getHotelProcessedFares, getHotelsWithBestFares } from '../../services';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	formControl: {
		margin: -3,
		width: '100%',
	},
	table: {
		minWidth: 650,
	},
	brokerField: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	buttonViewHotelPrice: {
		marginLeft: '50px',
		height: '40px',
		marginTop: '20px',
	},
	localPrice: {
		display: 'flex',
		width: '150px',
		justifyContent: 'center',
	},
	blockContainer: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[1],
	},
	subtitle: {
		marginBottom: theme.spacing(2),
	},
	formSection: {
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	submit: {},
}));

export interface HotelSuggestion {
	hotelId: number;
	hotelName: string;
	provider: string;
}

export interface HotelSuggestionWithCityCode {
	hotelSuggestion: HotelSuggestion;
	cityCode: string;
}

interface DeleteHotelOptionPayload {
	destinationIndex: number;
	hotelOptionIndex: number;
}

interface AutomaticPriceFarePayload {
	hotelId: number;
	provider: string;
	showSwitch: boolean;
	automaticPrice: boolean;
	showModalFare: boolean;
}

interface RoomInfoByDestinationPayload {
	price: number;
	board: string;
	rateType: string;
	description?: string;
}

interface Props {
	onError: (error: string) => void;
}

export default function HotelOptionsConfig({ onError }: Props): JSX.Element {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<{
		show: boolean;
		severity: 'success' | 'error' | 'warning';
		message: string;
	}>({
		show: false,
		severity: 'success',
		message: '',
	});

	// Estados para manejar múltiples destinos
	const [selectedHotelOptions, setSelectedHotelOptions] = useState<(HotelSuggestionWithCityCode | null)[]>([]);
	const [searchOptionsPerDestination, setSearchOptionsPerDestination] = useState<HotelSuggestionWithCityCode[][]>([]);
	const [inputHotelNames, setInputHotelNames] = useState<string[]>([]); // Estado para controlar inputValue

	const [chdChargedValue, setCHDChargedValue] = useState(0);
	const [infChargedValue, setINFChargedValue] = useState(0);
	const [brokerName, setBrokerName] = useState('');
	const regexCHDOrINFValue = /^\d+(.\d{0,2})?$/;
	const { destination, roomBaseConfig, localCurrency, exchangeRate, showLocalCurrency } = useSelector(
		(state: RootState) => state.quotation,
	);
	const paxTotals = useSelector(getPAXTotals);
	const [openDeleteHotelOptionConfirmDialog, setOpenDeleteHotelOptionConfirmDialog] = useState(false);
	const initDeleteHotelOptionPayload = { destinationIndex: -1, hotelOptionIndex: -1 };
	const [deleteHotelOptionPayload, setDeleteHotelOptionPayload] =
		useState<DeleteHotelOptionPayload>(initDeleteHotelOptionPayload);

	const [automaticPriceFarePayloads, setAutomaticPricePayload] = useState<AutomaticPriceFarePayload[]>([]);

	const [openAutoHotelsModal, setOpenAutoHotelsModal] = useState(false);
	const [selectedBoardCode, setSelectedBoardCode] = useState<string>('');
	const [selectedCancellationPolicy, setSelectedCancellationPolicy] = useState<string>('');
	const [currentDestinationIndex, setCurrentDestinationIndex] = useState<number>(-1);
	const [chdChargedModalValue, setChdChargedModalValue] = useState(0);
	const [infChargedModalValue, setInfChargedModalValue] = useState(0);

	// Nuevo estado para manejar el resumen de la recotización
	const [, setRecotizationSummary] = useState<{
		successfullyUpdated: { destinationIndex: number; hotelName: string; note?: string }[];
		failedToUpdate: { destinationIndex: number; hotelName: string; reason: string }[];
	}>({
		successfullyUpdated: [],
		failedToUpdate: [],
	});

	const getRoomInfoInitialState = () => {
		const result: RoomInfoByDestinationPayload[][] = [];
		destination.forEach((_dest, indexDest) => {
			const roomByDestination: RoomInfoByDestinationPayload[] = [];
			roomBaseConfig.forEach((_roomConf, indexRoom) => {
				roomByDestination[indexRoom] = { price: 0, board: '', rateType: '', description: 'Estandar' };
			});
			result[indexDest] = roomByDestination;
		});
		return result;
	};

	const [roomInfoByDestinationPayload, setRoomInfoByDestinationPayload] = useState<RoomInfoByDestinationPayload[][]>(
		getRoomInfoInitialState(),
	);

	// Inicializar estados por destino cuando cambie la lista de destinos
	useEffect(() => {
		setRoomInfoByDestinationPayload(getRoomInfoInitialState());

		// Inicializar selectedHotelOptions y searchOptionsPerDestination
		setSelectedHotelOptions(destination.map(() => null));
		setSearchOptionsPerDestination(destination.map(() => []));
		setInputHotelNames(destination.map(() => '')); // Inicializar inputHotelNames
		setAutomaticPricePayload(
			destination.map(() => ({
				hotelId: 0,
				provider: '',
				showSwitch: false,
				automaticPrice: false,
				showModalFare: false,
			})),
		);
	}, [destination, roomBaseConfig]);

	const handleChangeBoard = (destinationIndex: number, roomIndex: number, value: string) => {
		setRoomInfoByDestinationPayload((prev) => {
			const newState = [...prev];
			newState[destinationIndex][roomIndex].board = value;
			return newState;
		});
	};

	const handleChangeRate = (destinationIndex: number, roomIndex: number, value: string) => {
		setRoomInfoByDestinationPayload((prev) => {
			const newState = [...prev];
			newState[destinationIndex][roomIndex].rateType = value;
			return newState;
		});
	};

	const handleChangePrice = (destinationIndex: number, roomIndex: number, value: string) => {
		setRoomInfoByDestinationPayload((prev) => {
			const newState = [...prev];
			newState[destinationIndex][roomIndex].price = Number(value);
			return newState;
		});
	};

	const handleChangeDescription = (destinationIndex: number, roomIndex: number, value: string) => {
		if (value !== '') {
			setRoomInfoByDestinationPayload((prev) => {
				const newState = [...prev];
				newState[destinationIndex][roomIndex].description = value;
				return newState;
			});
		}
	};

	const handleClickOpenConfirmDeleteHotelOption = (destinationIndex: number, hotelOptionIndex: number) => {
		setOpenDeleteHotelOptionConfirmDialog(true);
		setDeleteHotelOptionPayload({ destinationIndex, hotelOptionIndex });
	};

	const handleCloseConfirmDeleteHotelOption = () => {
		setOpenDeleteHotelOptionConfirmDialog(false);
	};

	const handleDeleteHotelOption = () => {
		setOpenDeleteHotelOptionConfirmDialog(false);
		dispatch(deleteHotelOptionToDestination(deleteHotelOptionPayload));
	};

	// Manejar búsqueda por destino en onInputChange
	const handleChangeSearchHotelName = (cityCode: string, index: number) => async (newInputValue: string) => {
		if (newInputValue.length > 2) {
			try {
				const data = await hotelSuggestion(cityCode, newInputValue);
				setSearchOptionsPerDestination((prev) => {
					const newOptions = [...prev];
					newOptions[index] = data.data.map((x) => ({
						hotelSuggestion: x,
						cityCode: cityCode,
					}));
					return newOptions;
				});
			} catch (error) {
				console.error('Error al buscar sugerencias de hoteles:', error);
				onError('Ocurrió un error al buscar sugerencias de hoteles.');
			}
		} else {
			// Si el valor es menor o igual a 2, limpiar las opciones de búsqueda
			setSearchOptionsPerDestination((prev) => {
				const newOptions = [...prev];
				newOptions[index] = [];
				return newOptions;
			});
		}
	};

	const addHotelOption = async (e: React.FormEvent<HTMLFormElement>, rooms: RoomFare[]) => {
		try {
			e.preventDefault();
			const form = e.currentTarget;
			const hotelId = Number(form.hotelId.value);
			const hotelName = form.hotelName.value;
			const provider = form.hotelProvider.value;
			const destinationIndex = Number(form.destinationIndex.value);

			if (!hotelId || !provider) {
				throw new Error('Hotel ID o proveedor no proporcionado.');
			}

			const result = await getHotelDetail(hotelId, provider);

			console.log('Resultado de getHotelDetail:', result);

			if (!result || !result.data) {
				throw new Error('No se pudieron obtener los detalles del hotel.');
			}

			const details = result.data;

			const hotelOptionsObj: HotelOptions = {
				hotelName: hotelName,
				hotelId: hotelId,
				provider: provider,
				chdCharged: chdChargedValue,
				infCharged: infChargedValue,
				...(brokerName && { brokerName }),
				rooms,
				details,
			};

			dispatch(
				addHotelOptionToDestination({
					destinationIndex,
					data: hotelOptionsObj,
				}),
			);

			clearBrokerName();
		} catch (error: any) {
			console.error('Error al agregar la opción de hotel:', error);
			onError(error.message || 'Ocurrió un error al agregar la opción de hotel.');
		}
	};

	const handleAddAutoHotels = async () => {
		setOpenAutoHotelsModal(false);
		if (currentDestinationIndex === -1) {
			onError('No hay destino seleccionado.');
			return;
		}
		setLoading(true);
		try {
			const destinationItem = destination[currentDestinationIndex];

			const checkIn = destinationItem.checking;
			const checkOutDate = new Date(new Date(checkIn).getTime() + destinationItem.nights * 24 * 60 * 60 * 1000);
			const checkOut = checkOutDate.toISOString().split('T')[0];

			const hotels = await getHotelsWithBestFares(
				destinationItem.cityCode,
				checkIn,
				checkOut,
				roomBaseConfig,
				selectedBoardCode,
				selectedCancellationPolicy,
			);
			const hotelOptions = hotels.map((hotel) => ({
				hotelId: hotel.hotelId,
				provider: hotel.provider,
				hotelName: hotel.hotelName,
				chdCharged: chdChargedModalValue,
				infCharged: infChargedModalValue,
				rooms: hotel.rooms || [],
				details: hotel.details || {},
				brokerName: '',
			}));

			if (hotelOptions.length > 0) {
				const hotelsOptionsSorted = hotelOptions
					.slice(0, hotelOptions.length)
					// @ts-ignore
					.sort((a, b) => minPriceInRooms(a.rooms) - minPriceInRooms(b.rooms));

				dispatch(
					setHotelOptionsToDestination({
						destinationIndex: currentDestinationIndex,
						data: hotelsOptionsSorted,
					}),
				);
				setAlert({ show: true, severity: 'success', message: 'Hoteles agregados automáticamente correctamente.' });
			} else {
				setAlert({ show: true, severity: 'error', message: 'No se encontraron hoteles automáticos para agregar.' });
			}

			setLoading(false);
		} catch (error) {
			console.error('Error al agregar los hoteles automáticamente:', error);
			setAlert({ show: true, severity: 'error', message: 'Hubo un problema al agregar los hoteles automáticamente.' });
			setLoading(false);
		}
	};

	// Función mejorada para recotizar hoteles
	const handleRequoteHotels = async () => {
		setLoading(true);
		try {
			const successfullyUpdated: { destinationIndex: number; hotelName: string; note?: string }[] = [];
			const failedToUpdate: { destinationIndex: number; hotelName: string; reason: string }[] = [];

			// Iterar sobre cada destino
			for (let destIndex = 0; destIndex < destination.length; destIndex++) {
				const dest = destination[destIndex];
				const { checking: checkIn, nights, hotelOptions } = dest;

				const checkOutDate = new Date(new Date(checkIn).getTime() + nights * 24 * 60 * 60 * 1000);
				const checkOut = checkOutDate.toISOString().split('T')[0];

				// Preparar las distribuciones para la solicitud
				const distributions = roomBaseConfig.map((room) => ({
					...room,
					childrenAges: (room.childs || []).concat(room.infants || []),
				}));

				// Procesar cada hotel dentro del destino
				const hotelPromises = hotelOptions.map(async (hotelOption, hotelIndex) => {
					if (hotelOption.rooms.length === 0) return;

					const { hotelId, provider, hotelName } = hotelOption;

					const request: AccommodationFaresRequest = {
						hotelId,
						provider,
						distributions,
						checkIn,
						checkOut,
					};

					try {
						const updatedFares = await getHotelProcessedFares(
							roomBaseConfig,
							request,
							hotelOption.rooms[0].rateType,
							hotelOption.rooms[0].board,
						);

						if (!updatedFares) {
							// No se encontraron nuevas tarifas; no hay cambios
							successfullyUpdated.push({ destinationIndex: destIndex, hotelName, note: 'Sin cambios' });
							return;
						}

						// Verificar si las tarifas son diferentes a las existentes
						const tarifasIguales = hotelOption.rooms.every((room, idx) => {
							const nuevaTarifa = updatedFares[idx];
							return (
								nuevaTarifa.price === room.price &&
								nuevaTarifa.board === room.board &&
								nuevaTarifa.rateType === room.rateType
							);
						});

						if (tarifasIguales) {
							successfullyUpdated.push({ destinationIndex: destIndex, hotelName, note: 'Sin cambios' });
							return;
						}

						const updatedHotelOption: HotelOptions = {
							...hotelOption,
							rooms: updatedFares,
							// Mantener los valores de chdCharged e infCharged
						};

						// Actualizar el store de Redux
						dispatch(
							setHotelOptionsToDestination({
								destinationIndex: destIndex,
								data: [
									// Reemplazar el hotel existente con el actualizado
									...dest.hotelOptions.slice(0, hotelIndex),
									updatedHotelOption,
									...dest.hotelOptions.slice(hotelIndex + 1),
								],
							}),
						);

						// Registrar éxito
						successfullyUpdated.push({ destinationIndex: destIndex, hotelName });
					} catch (error: any) {
						console.error(`Error al recotizar el hotel "${hotelName}":`, error);
						failedToUpdate.push({
							destinationIndex: destIndex,
							hotelName,
							reason: error.message || 'Error desconocido.',
						});
					}
				});

				// Esperar a que todas las promesas de hoteles dentro del destino se completen
				await Promise.all(hotelPromises);
			}

			// Actualizar el resumen de recotización
			setRecotizationSummary({ successfullyUpdated, failedToUpdate });

			// Preparar el mensaje del alert
			let message = '';
			if (successfullyUpdated.length > 0) {
				message += `✅ Hoteles actualizados correctamente:\n${successfullyUpdated
					.map((hotel) => `- ${hotel.hotelName}${hotel.note ? ` (${hotel.note})` : ''}`)
					.join('\n')}\n`;
			}
			if (failedToUpdate.length > 0) {
				message += `❌ Hoteles que fallaron al actualizar:\n${failedToUpdate
					.map((hotel) => `- ${hotel.hotelName}: ${hotel.reason}`)
					.join('\n')}`;
			}

			// Determinar la severidad del alert
			const severity: 'success' | 'error' | 'warning' = failedToUpdate.length > 0 ? 'warning' : 'success';

			// Mostrar el alert con el resumen
			setAlert({
				show: true,
				severity,
				message,
			});
		} catch (error: any) {
			console.error('Error en handleRequoteHotels:', error);
			setAlert({
				show: true,
				severity: 'error',
				message: error.message || 'Hubo un problema al recotizar los hoteles.',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleAddHotelOption = async (e: React.FormEvent<HTMLFormElement>) => {
		try {
			e.preventDefault();
			const form = e.currentTarget;
			const hotelId = Number(form.hotelId.value);
			const provider = form.hotelProvider.value;
			const destinationIndex = Number(form.destinationIndex.value);

			if (destinationIndex >= 0 && destinationIndex < destination.length) {
				const maybeSameHotel = destination[destinationIndex].hotelOptions.find(
					(x) => x.hotelId === hotelId && x.provider === provider,
				);

				const roomsFares = roomBaseConfig.map((_, indexRoom) => ({
					price: Number(form[`hotelPrice-${indexRoom}`].value),
					board: form[`hotelBoard-${indexRoom}`].value,
					rateType: form[`rateType-${indexRoom}`].value,
					description: form[`room-${indexRoom}-description`].value,
				}));

				if (maybeSameHotel) {
					const boardStringsInHotelFound = createBoardsKey(maybeSameHotel.rooms);
					const boardStringInNewHotel = createBoardsKey(roomsFares);
					if (boardStringsInHotelFound === boardStringInNewHotel) {
						onError(
							`El hotel ${maybeSameHotel.hotelName} con una configuración de regímenes igual al actual ya fue adicionado en el destino`,
						);
					} else {
						await addHotelOption(e, roomsFares);
					}
				} else {
					await addHotelOption(e, roomsFares);
				}

				// Resetear estados después de agregar la opción
				setSelectedHotelOptions((prev) => {
					const newSelected = [...prev];
					newSelected[destinationIndex] = null;
					return newSelected;
				});
				setInputHotelNames((prev) => {
					const newInputs = [...prev];
					newInputs[destinationIndex] = ''; // Limpiar el campo de entrada
					return newInputs;
				});

				// Opcionalmente, resetea los cargos CHD/INF a 0:
				setCHDChargedValue(0);
				setINFChargedValue(0);

				// Resetear el nombre del broker
				setBrokerName('');

				// **Agregar logs para verificar estados**
				console.log('Selected Hotel Options después de agregar:', selectedHotelOptions);
				console.log('Input Hotel Names después de agregar:', inputHotelNames);
			}
		} catch (error: any) {
			console.error('Error en handleAddHotelOption:', error);
			onError(error.message || 'Ocurrió un error al agregar la opción de hotel.');
		}
	};

	const handleSelectHotel = (destinationIndex: number, hotelId: number, provider: string) => {
		const newValue = [...automaticPriceFarePayloads];
		newValue[destinationIndex] = {
			hotelId,
			provider,
			showSwitch: provider === 'Hotelbeds',
			automaticPrice: false,
			showModalFare: false,
		};
		setAutomaticPricePayload(newValue);
	};

	const clearBrokerName = () => setBrokerName('');

	const handleClearSelectedHotel = (destinationIndex: number) => {
		setSelectedHotelOptions((prev) => {
			const newSelected = [...prev];
			newSelected[destinationIndex] = null;
			return newSelected;
		});
		setInputHotelNames((prev) => {
			const newInputs = [...prev];
			newInputs[destinationIndex] = ''; // Limpiar el campo de entrada
			return newInputs;
		});
		setAutomaticPricePayload((prev) => {
			const newPayload = [...prev];
			if (newPayload[destinationIndex]) {
				newPayload[destinationIndex] = {
					...newPayload[destinationIndex],
					hotelId: 0,
					provider: '',
				};
			}
			return newPayload;
		});
	};

	const canShowAutomaticPriceSwitch = (destinationIndex: number): boolean => {
		const indexValue = automaticPriceFarePayloads[destinationIndex];
		return indexValue ? indexValue.showSwitch : false;
	};

	const canShowModalFareAutomaticPrice = (destinationIndex: number): boolean => {
		const indexValue = automaticPriceFarePayloads[destinationIndex];
		return indexValue ? indexValue.showModalFare : false;
	};

	const hideOrShowModalFareAutomaticPrice = (destinationIndex: number, show: boolean) => {
		if (automaticPriceFarePayloads[destinationIndex]) {
			const newValue = [...automaticPriceFarePayloads];
			newValue[destinationIndex].showModalFare = show;
			setAutomaticPricePayload(newValue);
		}
	};

	const handleFaresSelected = (destinationIndex: number, faresSelected: FareSelected[]) => {
		if (
			roomInfoByDestinationPayload[destinationIndex] &&
			roomInfoByDestinationPayload[destinationIndex].length === faresSelected.length
		) {
			const infoForRooms: RoomInfoByDestinationPayload[] = faresSelected.map((fareSelected) => {
				const customBoard = findCustomBoard(fareSelected.fare.board);
				const customRate = findCustomRate(fareSelected.fare.cancellationPolicy);
				return {
					price: fareSelected.fare.fare,
					board: customBoard ? customBoard.code : '',
					rateType: customRate ? customRate.code : '',
				};
			});

			setRoomInfoByDestinationPayload((prev) => {
				const newState = [...prev];
				newState[destinationIndex] = infoForRooms;
				return newState;
			});
		}
	};

	const setAutomaticPrice = (destinationIndex: number, automaticPrice: boolean) => {
		if (automaticPriceFarePayloads[destinationIndex]) {
			const newValue = [...automaticPriceFarePayloads];
			newValue[destinationIndex].automaticPrice = automaticPrice;
			setAutomaticPricePayload(newValue);
			if (automaticPrice) {
				clearBrokerName();
			}
		}
	};

	const isAutomaticPrice = (destinationIndex: number): boolean => {
		const indexValue = automaticPriceFarePayloads[destinationIndex];
		return indexValue ? indexValue.automaticPrice : false;
	};

	const hasSomeBrokerName = (): boolean =>
		destination.some((x) => x.hotelOptions.some((h) => h?.brokerName && h?.brokerName !== ''));

	const createHotelFareRequest = (indexDestination: number): AccommodationFaresRequest => {
		const destinationInIndex = destination[indexDestination];
		return createHotelFareRequestBase(
			roomBaseConfig,
			automaticPriceFarePayloads[indexDestination].hotelId,
			destinationInIndex,
		);
	};

	return (
		<Container component="main" maxWidth="md">
			{/* Modal para Agregar Hoteles Automáticos */}
			<Dialog
				open={openAutoHotelsModal}
				onClose={() => setOpenAutoHotelsModal(false)}
				aria-labelledby="auto-hotels-dialog-title"
			>
				<DialogTitle id="auto-hotels-dialog-title">Seleccionar opciones</DialogTitle>
				<DialogContent sx={{ minWidth: '400px' }}>
					<FormControl fullWidth margin="normal">
						<InputLabel>Board Code</InputLabel>
						<Select value={selectedBoardCode} onChange={(e) => setSelectedBoardCode(e.target.value)} required>
							{customBoards.map((board) => (
								<MenuItem key={board.code} value={board.code}>
									{board.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth margin="normal">
						<InputLabel>Cancellation Policy</InputLabel>
						<Select
							value={selectedCancellationPolicy}
							onChange={(e) => setSelectedCancellationPolicy(e.target.value)}
							required
						>
							<MenuItem value="all">
								<em>Todas</em>
							</MenuItem>
							{customRates.map((rate) => (
								<MenuItem key={rate.code} value={rate.code}>
									{rate.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						fullWidth
						type="number"
						label="CHD Charged"
						value={chdChargedModalValue}
						onChange={(e) => {
							const numeric = Number(e.target.value);
							if (!isNaN(numeric) && numeric >= 0 && numeric <= 1) {
								setChdChargedModalValue(numeric);
							}
						}}
						margin="normal"
					/>
					<TextField
						fullWidth
						type="number"
						label="INF Charged"
						value={infChargedModalValue}
						onChange={(e) => {
							const numeric = Number(e.target.value);
							if (!isNaN(numeric) && numeric >= 0 && numeric <= 1) {
								setInfChargedModalValue(numeric);
							}
						}}
						margin="normal"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenAutoHotelsModal(false)} color="secondary">
						Cancelar
					</Button>
					<Button
						onClick={handleAddAutoHotels}
						color="primary"
						disabled={!selectedBoardCode || !selectedCancellationPolicy}
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>

			{/* Modal de Confirmación para Eliminar Opciones de Hotel */}
			<Dialog
				open={openDeleteHotelOptionConfirmDialog}
				onClose={handleCloseConfirmDeleteHotelOption}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Eliminar tarifa de habitación de hotel'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						¿Confirma la eliminación de la tarifa de la habitación?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleCloseConfirmDeleteHotelOption}>
						Cancelar
					</Button>
					<Button variant="contained" color="primary" onClick={handleDeleteHotelOption} autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>

			<CssBaseline />
			<div className={classes.paper}>
				{/* Título Principal */}
				<Typography
					marginTop={2}
					component="h1"
					variant="h6"
					gutterBottom
					sx={{
						backgroundColor: 'primary.light',
						px: 2,
						py: 1,
						borderRadius: 2,
						color: 'white',
					}}
				>
					Configuración de alojamiento
					<HelpTooltip
						helpKey="hotelConfig"
						placement="bottom-end"
						iconButtonProps={{ sx: { float: 'inline-end', background: 'white', padding: '0px' } }}
					/>
				</Typography>

				{/* Botón para Recotizar Hoteles */}
				<Grid container justifyContent="flex-end" mt={2}>
					<Button
						variant="contained"
						color="secondary"
						onClick={handleRequoteHotels}
						disabled={loading || !destination.some((dest) => dest.hotelOptions.length > 0)}
					>
						Recotizar Hoteles
					</Button>
				</Grid>

				{/* Mensaje cuando no hay destinos configurados */}
				{destination.length === 0 && (
					<Typography color="InfoText" pl={2} variant="subtitle1" gutterBottom>
						🫵 Debes configurar al menos un destino y una habitación para avanzar con la selección de alojamiento
					</Typography>
				)}

				{/* Bloques por Destino */}
				{destination.map((destinationItem, index) => (
					<div key={index}>
						{/* Subtítulo del Destino */}
						<Typography
							variant="subtitle2"
							fontWeight="500"
							gutterBottom
							width="100%"
							padding={1}
							borderRadius={2}
							mt={1}
							sx={{ background: '#ED6500', color: 'white' }}
						>
							Destino: {getCityNameCleaned(destinationItem.cityName)}
						</Typography>
						<Box className={classes.blockContainer}>
							{/* Opciones Configuradas */}
							<Box>
								{destinationItem.hotelOptions.length !== 0 && (
									<div>
										<Typography variant="subtitle1" fontWeight="500" gutterBottom>
											Alojamientos agregados:
										</Typography>

										<TableContainer component={Paper}>
											<Table className={classes.table} aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>Room #</TableCell>
														<TableCell>Hotel Name</TableCell>
														<TableCell align="right">Precio x ADT</TableCell>
														<TableCell align="right">{paxTotals.child > 0 ? 'Precio x CHD' : ''}</TableCell>
														<TableCell align="right">{paxTotals.infants > 0 ? 'Precio x INF' : ''}</TableCell>
														<TableCell align="right">Precio Total</TableCell>
														<TableCell align="right">Régimen</TableCell>
														<TableCell align="right">Tipo de Tarifa</TableCell>
														<TableCell align="right">{hasSomeBrokerName() ? 'Broker' : ''}</TableCell>
														<TableCell align="right" />
													</TableRow>
												</TableHead>
												<TableBody>
													{destinationItem.hotelOptions.map((option, indexOption) => {
														return option.rooms.map((optionItem, optionItemIndex) => {
															const totalAdult = paxTotals.totalPaxPerRoom[optionItemIndex].totalAdults;
															const totalChild = paxTotals.totalPaxPerRoom[optionItemIndex].totalChild;
															const totalInfant = paxTotals.totalPaxPerRoom[optionItemIndex].totalInfant;
															const roomCostByPax = calculateRoomCostByPax(
																optionItem.price,
																totalAdult,
																totalChild,
																totalInfant,
																option.chdCharged,
																option.infCharged,
															);
															const price = Math.round(roomCostByPax.adtCost * exchangeRate);
															const priceLocal = price.toLocaleString().replace(/,/g, '.');
															const priceTotal = Math.round(optionItem.price * exchangeRate);
															const priceLocalTotal = priceTotal.toLocaleString().replace(/,/g, '.');

															return (
																<TableRow
																	key={`${index}-${
																		destinationItem.cityCode + '-' + indexOption + '-' + optionItemIndex
																	}`}
																>
																	<TableCell component="th" scope="row">
																		{optionItemIndex + 1}
																	</TableCell>
																	<TableCell>{option.hotelName}</TableCell>
																	<TableCell align="center">
																		USD {roomCostByPax.adtCost} <br />
																		{showLocalCurrency && (
																			<span className={classes.localPrice}>
																				{localCurrency} {priceLocal}
																			</span>
																		)}
																	</TableCell>
																	<TableCell align="right">
																		{paxTotals.totalPaxPerRoom[optionItemIndex].hasChild && roomCostByPax.chdCost}
																	</TableCell>
																	<TableCell align="right">
																		{paxTotals.totalPaxPerRoom[optionItemIndex].hasInfant && roomCostByPax.infCost}
																	</TableCell>
																	<TableCell align="center">
																		USD {optionItem.price}
																		<br />
																		{showLocalCurrency && (
																			<span className={classes.localPrice}>
																				{localCurrency} {priceLocalTotal}
																			</span>
																		)}
																	</TableCell>
																	<TableCell align="right">{getBoardName(optionItem.board)}</TableCell>
																	<TableCell align="right">{optionItem.rateType}</TableCell>
																	<TableCell align="right">{option?.brokerName ? option?.brokerName : ''}</TableCell>
																	<TableCell align="right">
																		<IconButton
																			color="secondary"
																			component="span"
																			onClick={() => handleClickOpenConfirmDeleteHotelOption(index, indexOption)}
																			size="large"
																		>
																			<DeleteIcon />
																		</IconButton>
																	</TableCell>
																</TableRow>
															);
														});
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</div>
								)}
							</Box>
							{/* Separador */}
							<Box my={3}>
								{destinationItem.hotelOptions.length === 0 ? (
									<Typography variant="subtitle1" fontWeight="500" gutterBottom>
										Agrega tu primera opción de alojamiento en {getCityNameCleaned(destinationItem.cityName)}:
									</Typography>
								) : (
									<Typography variant="subtitle1" fontWeight="500" gutterBottom>
										🚀 Agrega nuevas opciones en {getCityNameCleaned(destinationItem.cityName)}:
									</Typography>
								)}
							</Box>
							{/* Formulario para Agregar Opciones de Alojamiento */}
							<form key={`form-${index}`} onSubmit={handleAddHotelOption}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<input type="hidden" id={`hotelIdAdd-${index}`} name="hotelId" />
										<input type="hidden" id={`hotelProviderAdd-${index}`} name="hotelProvider" />
										<input type="hidden" name="destinationIndex" value={index} />

										<Autocomplete
											id={`optionHotelNameAutocomplete-${index}`}
											options={searchOptionsPerDestination[index] || []}
											value={selectedHotelOptions[index]}
											inputValue={inputHotelNames[index]} // Controlar inputValue
											onChange={(_event, value) => {
												setSelectedHotelOptions((prev) => {
													const newSelected = [...prev];
													newSelected[index] = value;
													return newSelected;
												});
												if (value && value.hotelSuggestion.hotelName && value.hotelSuggestion.hotelId) {
													const hiddenElement = document.getElementById(`hotelIdAdd-${index}`) as HTMLInputElement;
													const hiddenProviderElement = document.getElementById(
														`hotelProviderAdd-${index}`,
													) as HTMLInputElement;
													if (hiddenElement) {
														hiddenElement.value = value.hotelSuggestion.hotelId.toString();
													}
													if (hiddenProviderElement) {
														hiddenProviderElement.value = value.hotelSuggestion.provider;
													}
													handleSelectHotel(index, value.hotelSuggestion.hotelId, value.hotelSuggestion.provider);
												} else {
													handleClearSelectedHotel(index);
												}
											}}
											onInputChange={(_, newInputValue) => {
												setInputHotelNames((prev) => {
													const newInputs = [...prev];
													newInputs[index] = newInputValue;
													return newInputs;
												});
												handleChangeSearchHotelName(destinationItem.cityCode, index)(newInputValue);
											}}
											getOptionLabel={(option: HotelSuggestionWithCityCode) => option.hotelSuggestion.hotelName}
											renderInput={(params: any) => (
												<TextField
													name="hotelName"
													variant="standard"
													required
													label="Nombre del hotel"
													size="small"
													fullWidth
													style={{ marginTop: -3 }}
													{...params}
												/>
											)}
										/>
									</Grid>
								</Grid>
								{selectedHotelOptions[index] && (
									<Grid container spacing={2} pt={4}>
										<Grid item xs={12} sm={2}>
											<TextField
												variant="outlined"
												required
												fullWidth
												label="CHD Charged?"
												name="chdCharged"
												type="number"
												onChange={({ target: { value } }) => {
													if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
														setCHDChargedValue(Number(value));
													}
												}}
												value={chdChargedValue}
											/>
										</Grid>

										<Grid item xs={12} sm={2}>
											<TextField
												variant="outlined"
												required
												fullWidth
												label="INF Charged?"
												name="infCharged"
												type="number"
												value={infChargedValue}
												onChange={({ target: { value } }) => {
													if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
														setINFChargedValue(Number(value));
													}
												}}
											/>
										</Grid>

										{canShowAutomaticPriceSwitch(index) && (
											<Grid item xs={12} sm={2}>
												<FormControlLabel
													control={
														<Switch
															checked={isAutomaticPrice(index)}
															onChange={(_, checked) => setAutomaticPrice(index, checked)}
															name="autoFares"
															color="primary"
														/>
													}
													label="Tarifas Automáticas"
												/>

												{canShowModalFareAutomaticPrice(index) && (
													<FarePickerDialog
														open={canShowModalFareAutomaticPrice(index)}
														onSubmit={(fares) => {
															hideOrShowModalFareAutomaticPrice(index, false);
															handleFaresSelected(index, fares);
														}}
														fareRequest={createHotelFareRequest(index)}
														onClose={() => hideOrShowModalFareAutomaticPrice(index, false)}
													/>
												)}
											</Grid>
										)}

										{canShowAutomaticPriceSwitch(index) && isAutomaticPrice(index) && (
											<Button
												className={classes.buttonViewHotelPrice}
												variant="contained"
												size="small"
												color="primary"
												onClick={() => hideOrShowModalFareAutomaticPrice(index, true)}
											>
												Ver Precios
											</Button>
										)}

										{!isAutomaticPrice(index) && (
											<Grid item xs={12} sm={12}>
												<TextField
													className={classes.brokerField}
													variant="outlined"
													name="brokerName"
													label="Nombre del Broker"
													onChange={({ target: { value } }) => setBrokerName(value)}
													value={brokerName}
													inputProps={{
														maxLength: 250,
													}}
													fullWidth
												/>
											</Grid>
										)}

										{roomBaseConfig.map((_roomConfig, indexRoom) => (
											<Grid
												key={`${index}-${destinationItem.cityCode + '-roomconfig-' + indexRoom}`}
												container
												spacing={2}
												className={classes.formSection}
											>
												<Grid item xs={12}>
													<Typography component="span" variant="subtitle1">
														Habitación {indexRoom + 1}
													</Typography>
												</Grid>
												{roomInfoByDestinationPayload[index] && roomInfoByDestinationPayload[index][indexRoom] && (
													<>
														<Grid item xs={12} sm={3}>
															<TextField
																variant="outlined"
																required
																fullWidth
																label="Precio"
																name={`hotelPrice-${indexRoom}`}
																type="number"
																onChange={({ target: { value } }) => {
																	if (regexCHDOrINFValue.test(value) && Number(value) > 0) {
																		handleChangePrice(index, indexRoom, value);
																	} else if (value === '') {
																		handleChangePrice(index, indexRoom, '0');
																	}
																}}
																value={roomInfoByDestinationPayload[index][indexRoom].price}
															/>
														</Grid>

														<Grid item xs={12} sm={3}>
															<FormControl className={classes.formControl}>
																<InputLabel required>Régimen</InputLabel>
																<Select
																	required
																	label="Regimen"
																	name={`hotelBoard-${indexRoom}`}
																	value={roomInfoByDestinationPayload[index][indexRoom].board}
																	onChange={(event: SelectChangeEvent) => {
																		handleChangeBoard(index, indexRoom, event.target.value as string);
																	}}
																>
																	{customBoards.map((board) => (
																		<MenuItem key={`${index}-boards-${indexRoom}-${board.code}`} value={board.code}>
																			{board.name}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</Grid>
														<Grid item xs={12} sm={3}>
															<FormControl className={classes.formControl}>
																<InputLabel required>Tipo de Tarifa</InputLabel>
																<Select
																	label="Tipo de Tarifa"
																	required
																	value={roomInfoByDestinationPayload[index][indexRoom].rateType}
																	name={`rateType-${indexRoom}`}
																	onChange={(event: SelectChangeEvent) => {
																		handleChangeRate(index, indexRoom, event.target.value as string);
																	}}
																>
																	{customRates.map((rate) => (
																		<MenuItem key={`${index}-rates-${indexRoom}-${rate.code}`} value={rate.code}>
																			{rate.name}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</Grid>
														<Grid item xs={12} sm={3}>
															<TextField
																variant="outlined"
																multiline
																required
																fullWidth
																label="Tipo de habitación"
																name={`room-${indexRoom}-description`}
																inputProps={{
																	maxLength: 100,
																}}
																InputLabelProps={{ shrink: true }}
																onChange={({ target: { value } }) => {
																	handleChangeDescription(index, indexRoom, value);
																}}
																value={roomInfoByDestinationPayload[index][indexRoom].description}
															/>
														</Grid>
													</>
												)}
											</Grid>
										))}
									</Grid>
								)}
								<Grid container justifyContent="flex-end">
									<Button
										variant="contained"
										color="primary"
										className={classes.submit}
										type="submit"
										style={{ marginTop: '16px' }}
										disabled={!selectedHotelOptions[index]}
									>
										Agregar opción de alojamiento
									</Button>
								</Grid>
								<Grid container justifyContent="flex-end" mt={1}>
									<Button
										variant="outlined"
										color="primary"
										disabled={!!selectedHotelOptions[index]}
										onClick={() => {
											setCurrentDestinationIndex(index);
											setOpenAutoHotelsModal(true);
										}}
									>
										Agregar los hoteles más cotizados automáticamente
									</Button>
								</Grid>
							</form>
						</Box>
					</div>
				))}
			</div>

			{/* Backdrop para el Loading */}
			<Backdrop open={loading} style={{ zIndex: 1300 }}>
				<CircularProgress color="inherit" />
			</Backdrop>

			{/* Snackbar para Alertas */}
			<Snackbar
				open={alert.show}
				onClose={() => setAlert({ show: false, severity: 'success', message: '' })}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert
					onClose={() => setAlert({ show: false, severity: 'success', message: '' })}
					severity={alert.severity}
					sx={{ whiteSpace: 'pre-line', width: '100%' }}
					variant="filled"
				>
					{alert.message}
				</Alert>
			</Snackbar>
		</Container>
	);
}
