import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { checkShowErrorMessage } from 'features/common/helpers';
import { Service, ServiceOption } from 'features/quotation/types';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
}));

export interface ServicesFormProps {
	servicesOptions: ServiceOption[];
	destinationIndex: number;
	handleAddService: (service: Service, destinationIndex: number) => void;
}

interface FareOptions {
	fareSgl?: number;
	fareDbl?: number;
	fareTpl?: number;
	fareCpl?: number;
}

export const ServicesForm = ({
	servicesOptions,
	destinationIndex,
	handleAddService,
}: ServicesFormProps): JSX.Element => {
	const classes = useStyles();

	const servicesFormDefaultValues = {
		fareCost: -1,
		serviceType: 'perPassenger',
		service: null,
		chdCharged: 1,
		infCharged: 1,
		description: '',
	};

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues,
		reset,
	} = useForm({ mode: 'all', defaultValues: servicesFormDefaultValues });
	const [fareOptions, setFareOptions] = useState<FareOptions>({});
	const [selectedServiceOption, setSelectedServiceOption] = useState<ServiceOption | null>();
	const [keySelect, setKeySelect] = useState<string>('key-select-service');
	const regexCHDOrINFValue = /^\d+(.\d{0,2})?$/;

	const onSubmit = () => {
		const { serviceType, service, chdCharged, infCharged, description, fareCost } = getValues();
		const serviceToAd = {
			// @ts-ignore
			serviceType: serviceType as serviceType,
			chdCharged,
			infCharged,
			fareCost,
			// @ts-ignore
			name: service?.name || '',
			description,
		};
		handleAddService(serviceToAd, destinationIndex);
		reset(servicesFormDefaultValues);
		setSelectedServiceOption(null);
		setKeySelect(keySelect + '-key');
	};

	useEffect(() => {
		if (selectedServiceOption) {
			setFareOptions({
				fareSgl: selectedServiceOption.fares[0]?.fareSgl,
				fareDbl: selectedServiceOption.fares[0]?.fareDbl,
				fareTpl: selectedServiceOption.fares[0]?.fareTpl,
				fareCpl: selectedServiceOption.fares[0]?.fareCpl,
			});
		} else {
			setFareOptions({});
		}
	}, [selectedServiceOption]);

	const handleSelectedService = (newValue: ServiceOption | null, onChange: (...event: any[]) => void) => {
		setSelectedServiceOption(newValue);
		onChange(newValue);
	};

	return (
		<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2} key={`service-form-${destinationIndex}`}>
				<Grid item xs={12}>
					<Controller
						name="service"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Este campo es requerido',
							},
						}}
						render={(props) => (
							<FormControl sx={{ width: '100%' }} className={classes.formControl}>
								<Autocomplete
									{...props}
									key={keySelect}
									options={servicesOptions}
									getOptionLabel={(option: ServiceOption) => option.name}
									renderInput={(params) => (
										<TextField {...params} label="Ingrese nombre del servicio" variant="outlined" />
									)}
									onChange={(_, data) => handleSelectedService(data, props.field.onChange)}
								/>
								{props.fieldState?.error && (
									<FormHelperText sx={{ color: 'red' }}>{props.fieldState?.error.message}</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Controller
						name="fareCost"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Este campo es requerido',
							},
							min: {
								value: 0,
								message: 'Debe de seleccionar una tarifa',
							},
						}}
						render={({ field, fieldState: { error } }) => (
							<FormControl sx={{ width: '100%' }} className={classes.formControl}>
								<InputLabel>Tarifa</InputLabel>
								<Select label="Tarifa" {...field}>
									{Object.keys(fareOptions).length == 0 && (
										<MenuItem key={'empty'} value={-1}>
											Seleccione una tarifa
										</MenuItem>
									)}
									{fareOptions?.fareSgl && (
										<MenuItem key={'sgl'} value={fareOptions.fareSgl}>
											Sgl {fareOptions.fareSgl}
										</MenuItem>
									)}
									{fareOptions?.fareDbl && (
										<MenuItem key={'dbl'} value={fareOptions.fareDbl}>
											Dbl {fareOptions.fareDbl}
										</MenuItem>
									)}
									{fareOptions?.fareTpl && (
										<MenuItem key={'tpl'} value={fareOptions.fareTpl}>
											Tpl {fareOptions.fareTpl}
										</MenuItem>
									)}
									{fareOptions?.fareCpl && (
										<MenuItem key={'cpl'} value={fareOptions.fareCpl}>
											Cpl {fareOptions.fareCpl}
										</MenuItem>
									)}
								</Select>
								{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
							</FormControl>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Controller
						name="serviceType"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Este campo es requerido',
							},
						}}
						render={({ field, fieldState: { error } }) => (
							<FormControl className={classes.formControl}>
								<InputLabel required htmlFor="serviceType">
									Tipo de servicio
								</InputLabel>
								<Select label="Tipo de servicio" native required {...field}>
									<option aria-label="por pasajero" value="perPassenger">
										por pasajero{' '}
									</option>
									<option aria-label="por grupo" value="perGroup">
										por grupo{' '}
									</option>
								</Select>
								{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
							</FormControl>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Controller
						name="chdCharged"
						control={control}
						rules={{
							required: { value: true, message: 'Este campo es requerido' },
							min: {
								value: 0,
								message: 'El valor debe ser mayor o igual que 0',
							},
							max: {
								value: 1,
								message: 'El valor debe ser menor o igual que 1',
							},
							pattern: {
								value: regexCHDOrINFValue,
								message: 'El valor debe ser numérico y con hasta 2 lugares después del punto ej 0.55',
							},
						}}
						render={({ field }) => (
							<TextField
								variant="outlined"
								required
								fullWidth
								label="CHD Charged?"
								type="number"
								helperText={checkShowErrorMessage(Boolean(errors.chdCharged), errors.chdCharged?.message)}
								error={Boolean(errors.chdCharged)}
								{...field}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Controller
						name="infCharged"
						control={control}
						rules={{
							required: { value: true, message: 'Este campo es requerido' },
							min: {
								value: 0,
								message: 'El valor debe ser mayor o igual que 0',
							},
							max: {
								value: 1,
								message: 'El valor debe ser menor o igual que 1',
							},
							pattern: {
								value: regexCHDOrINFValue,
								message: 'El valor debe ser numérico y con hasta 2 lugares después del punto ej 0.55',
							},
						}}
						render={({ field }) => (
							<TextField
								variant="outlined"
								required
								fullWidth
								label="INF Charged?"
								type="number"
								helperText={checkShowErrorMessage(Boolean(errors.infCharged), errors.infCharged?.message)}
								error={Boolean(errors.infCharged)}
								{...field}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Controller
						name="description"
						control={control}
						render={({ field }) => (
							<TextField
								label="Descripcion"
								multiline
								rows={8}
								variant="outlined"
								fullWidth
								helperText={checkShowErrorMessage(Boolean(errors.description), errors.description?.message)}
								error={Boolean(errors.description)}
								{...field}
							/>
						)}
					/>
				</Grid>
			</Grid>

			<Grid container direction="row-reverse">
				<Button disabled={!isValid} variant="contained" color="primary" style={{ margin: 8 }} type="submit">
					Agregar Servicio
				</Button>
			</Grid>
		</form>
	);
};
