/// NUEVO

import React from 'react';
import { Card, CardContent, Grid, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getBoardName, GetPAXTotalsResponse, separadorDeMiles, truncateText } from '../../helpers';
import { HotelOptionDetailDestinationMultiple, QuotationDestinationMultipleRoomWithPrice } from '../../types';
import { Utility } from '../Utility';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { CountryCodeEnum } from 'features/common/types';

const useStyles = makeStyles((theme) => ({
	cardRoom: { marginTop: theme.spacing(2) },
	roomTitle: {
		fontWeight: 700,
		textAlign: 'center',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
	textInfo: {
		fontSize: '12px',
		fontWeight: 500,
		lineHeight: '24px',
	},
	priceTitle: {
		fontSize: '12px',
		fontWeight: 500,
		lineHeight: '24px',
	},
	priceValue: {
		fontSize: '16px',
		fontWeight: 700,
		color: theme.palette.secondary.main,
		textAlign: 'right',
	},
	signTitle: {
		fontSize: '12px',
		fontStyle: 'italic',
		fontWeight: 700,
		color: '#71BCED',
	},
	signValue: {
		fontSize: '12px',
		fontStyle: 'italic',
		fontWeight: 700,
		color: '#71BCED',
		textAlign: 'right',
	},
	rowPriceContainer: {
		marginTop: theme.spacing(1),
	},
}));

interface Props {
	roomsSummaryPrice: QuotationDestinationMultipleRoomWithPrice;
	indexRoom: number;
	showUtility: boolean;
	markup: number | null;
	localCurrency: string;
	exchangeRate: number;
	showLocalCurrency?: boolean;
	paxTotals: GetPAXTotalsResponse;
	hotels: HotelOptionDetailDestinationMultiple[];
	oneRoomOnQuotation: boolean;
}

const PriceRow = ({ title, priceUSD, localPrice, showLocalCurrency, localCurrency, classes }: any) => (
	<Grid container>
		<Grid item xs={6}>
			<Typography className={classes.priceTitle}>{title}</Typography>
		</Grid>
		<Grid item xs={6} sx={{ textAlign: 'right' }}>
			<Typography className={classes.priceValue}>
				USD {separadorDeMiles(priceUSD)}
				{showLocalCurrency && localPrice !== null && (
					<Typography fontSize="12px" sx={{ color: 'primary.main' }}>
						{localCurrency} {separadorDeMiles(localPrice)}
					</Typography>
				)}
			</Typography>
		</Grid>
	</Grid>
);

const SignRow = ({ title, signUSD, localSign, showLocalCurrency, localCurrency, classes }: any) => (
	<Grid container>
		<Grid item xs={6}>
			<Typography className={classes.signTitle}>{title}</Typography>
		</Grid>
		<Grid item xs={6} sx={{ textAlign: 'right' }}>
			<Typography className={classes.signValue}>
				USD {separadorDeMiles(signUSD)}
				{showLocalCurrency && localSign !== null && (
					<Typography fontSize="12px" sx={{ color: 'primary.main' }}>
						{localCurrency} {separadorDeMiles(localSign)}
					</Typography>
				)}
			</Typography>
		</Grid>
	</Grid>
);

export const NewRoomPricesMultiDestinationPreviewCard = (props: Props): JSX.Element => {
	const classes = useStyles();
	const {
		roomsSummaryPrice,
		indexRoom,
		localCurrency,
		exchangeRate,
		showLocalCurrency,
		paxTotals,
		hotels,
		oneRoomOnQuotation,
	} = props;
	const { auth } = useSelector((state: RootState) => state);

	const priceLocalTotal = Math.round(roomsSummaryPrice.totalPrice * exchangeRate);

	const paxCountInRoom = paxTotals.totalPaxPerRoom[indexRoom];
	const totalAdults = paxCountInRoom.totalAdults;
	const CHDExists = paxCountInRoom.hasChild;
	const INFExists = paxCountInRoom.hasInfant;

	return (
		<Card className={classes.cardRoom} sx={{ minWidth: oneRoomOnQuotation ? '100%' : '325px' }}>
			<CardContent sx={{ width: oneRoomOnQuotation ? '-webkit-fill-available' : '325px' }}>
				<Typography className={classes.roomTitle}>Habitación {indexRoom + 1}</Typography>
				<Typography className={classes.textInfo}>
					En esta habitación: {totalAdults} {totalAdults > 1 ? 'adultos' : 'adulto'} {CHDExists && '+ '}
					{CHDExists && paxCountInRoom.totalChild}{' '}
					{CHDExists ? (CHDExists && paxCountInRoom.totalChild > 1 ? 'niños' : 'niño') : ''} {INFExists && '+'}{' '}
					{INFExists && paxCountInRoom.totalInfant}{' '}
					{INFExists ? (INFExists && paxCountInRoom.totalInfant > 1 ? 'bebés' : 'bebé') : ''}
				</Typography>
				{hotels.length > 0 &&
					hotels.map((hotel) => {
						{
							return (
								<>
									<Typography className={classes.textInfo} component="span" fontSize={11} variant={'body2'}>
										{truncateText(hotel.hotelName, 35)}
									</Typography>
									<br />
									{hotel.rooms[indexRoom].description && (
										<>
											<Typography component={'span'} fontSize={11} variant={'body2'}>
												Tipo de habitación: {hotel.rooms[indexRoom].description}
											</Typography>{' '}
											<br />
										</>
									)}
									<Typography component={'span'} fontSize={11} variant={'body2'}>
										Régimen: {getBoardName(hotel.rooms[indexRoom].board)}
									</Typography>
									<br />
								</>
							);
						}
					})}

				<PriceRow
					title="Precio por adulto"
					priceUSD={roomsSummaryPrice.adtPrice}
					localPrice={Math.round(roomsSummaryPrice.adtPrice * exchangeRate)}
					showLocalCurrency={showLocalCurrency}
					localCurrency={localCurrency}
					classes={classes}
				/>
				{props.showUtility && props.markup != null && (
					<Utility price={roomsSummaryPrice.totalPrice} markup={props.markup} show={props.showUtility} />
				)}

				{roomsSummaryPrice.sign && (
					<SignRow
						title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
						signUSD={Math.round(roomsSummaryPrice.sign.adtSign)}
						localSign={Math.round(roomsSummaryPrice.sign.adtSign * exchangeRate)}
						showLocalCurrency={showLocalCurrency}
						localCurrency={localCurrency}
						classes={classes}
					/>
				)}

				{CHDExists && (
					<div>
						<PriceRow
							title="Precio por niño"
							priceUSD={roomsSummaryPrice.chdPrice}
							localPrice={Math.round(roomsSummaryPrice.chdPrice * exchangeRate)}
							showLocalCurrency={showLocalCurrency}
							localCurrency={localCurrency}
							classes={classes}
						/>

						{roomsSummaryPrice.sign && (
							<SignRow
								title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
								signUSD={Math.round(roomsSummaryPrice.sign.chdSign)}
								localSign={Math.round(roomsSummaryPrice.sign.chdSign * exchangeRate)}
								showLocalCurrency={showLocalCurrency}
								localCurrency={localCurrency}
								classes={classes}
							/>
						)}
					</div>
				)}
				{INFExists && (
					<div>
						<PriceRow
							title="Precio por bebé"
							priceUSD={roomsSummaryPrice.infPrice}
							localPrice={Math.round(roomsSummaryPrice.infPrice * exchangeRate)}
							showLocalCurrency={showLocalCurrency}
							localCurrency={localCurrency}
							classes={classes}
						/>

						{roomsSummaryPrice.sign && (
							<SignRow
								title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
								signUSD={Math.round(roomsSummaryPrice.sign.infSign)}
								localSign={Math.round(roomsSummaryPrice.sign.infSign * exchangeRate)}
								showLocalCurrency={showLocalCurrency}
								localCurrency={localCurrency}
								classes={classes}
							/>
						)}
					</div>
				)}

				<Divider sx={{ marginY: 2 }} />
				<div>
					<PriceRow
						title="Precio total del paquete x la habitación"
						priceUSD={roomsSummaryPrice.totalPrice}
						localPrice={priceLocalTotal}
						showLocalCurrency={showLocalCurrency}
						localCurrency={localCurrency}
						classes={classes}
					/>
					{roomsSummaryPrice.sign && (
						<SignRow
							title={auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
							signUSD={Math.round(roomsSummaryPrice.sign.totalSign)}
							localSign={Math.round(roomsSummaryPrice.sign.totalSign * exchangeRate)}
							showLocalCurrency={showLocalCurrency}
							localCurrency={localCurrency}
							classes={classes}
						/>
					)}
				</div>
			</CardContent>
		</Card>
	);
};
