import {
	FilterModel,
	FilterType,
	IHeadCell,
	OperatorFilterEnum,
	SelectFilterListData,
	SelectFilterModelSimple,
} from 'features/common/types';
import { segmentTypes, serviceTypes } from 'features/salesOrder/types';

export const headerCells: IHeadCell[] = [
	{
		field: 'created',
		sortable: true,
		headerName: 'Fecha',
	},
	{
		field: 'createdBy',
		sortable: true,
		headerName: 'Creada por',
	},
	{
		field: 'dealId',
		sortable: false,
		headerName: 'Deal ID o Caso',
	},
	{
		field: 'isBitrixDeal',
		sortable: false,
		headerName: 'Es un Deal',
	},
	{
		field: 'customersCompleteName',
		sortable: true,
		headerName: 'Cliente',
	},
	{
		field: 'chargedPercent',
		sortable: false,
		headerName: '% Cobrado',
	},
	// {
	// 	field: 'quotationId',
	// 	sortable: false,
	// 	headerName: 'Quotation ID',
	// },
	{
		field: 'currency',
		sortable: false,
		headerName: 'Moneda',
	},
	{
		field: 'salesAmount',
		sortable: false,
		headerName: 'Venta',
	},
	{
		field: 'totalCost',
		sortable: false,
		headerName: 'Costo',
	},
	{
		field: 'grossProfit',
		sortable: false,
		headerName: 'Margen Bruto',
	},
	{
		field: 'chargedAmount',
		sortable: false,
		headerName: 'Cobro',
	},
	{
		field: 'pendingPayAmount',
		sortable: false,
		headerName: 'Saldo',
	},
	{
		field: 'mainPassengerCompleteName',
		sortable: false,
		headerName: 'Pasajero Principal',
	},
	{
		field: 'destinations',
		sortable: false,
		headerName: 'Destinos',
	},
	{
		field: 'inicioDeViaje',
		sortable: true,
		headerName: 'Inicio de Viaje',
	},
	{
		field: 'daysToTravel',
		sortable: false,
		headerName: 'Días para viaje',
	},
	{
		field: 'automaticSyncWithNetsuite',
		sortable: false,
		headerName: 'AutoSinc',
	},
	{
		field: 'lastSuccessSyncAt',
		sortable: false,
		headerName: 'Ult. sincronización',
	},
	{
		field: 'segmentType',
		sortable: false,
		headerName: 'Segmento',
	},
	{
		field: 'daysToTravel',
		sortable: false,
		headerName: 'Canal',
	},
	{
		field: 'clientReference',
		sortable: false,
		headerName: 'Referencia del cliente',
	},
	{
		field: 'serviceTypes',
		sortable: false,
		headerName: 'Servicios en la orden',
	},
];

export const getSaleOrderFilters = (chanelsToFilter: SelectFilterListData[]): FilterModel[] => [
	{ label: 'Fecha', type: FilterType.DATE, key: 'created' },
	{
		label: 'Número de Orden',
		type: FilterType.NUMERIC,
		key: 'orderNumber',
		operator: OperatorFilterEnum.EQUALS,
	},
	{
		label: 'Deal ID o Caso',
		type: FilterType.NUMERIC,
		key: 'dealId',
		operator: OperatorFilterEnum.EQUALS,
	},
	{
		key: 'isBitrixDeal',
		type: FilterType.BOOLEAN,
		label: 'Es un Deal',
	},

	{
		label: 'Quotations ID',
		type: FilterType.NUMERIC,
		key: 'quotationId',
		operator: OperatorFilterEnum.EQUALS,
	},
	{ label: 'Venta', type: FilterType.NUMERIC, key: 'salesAmount' },
	{ label: 'Costo', type: FilterType.NUMERIC, key: 'totalCost' },
	{ label: 'Utilidad', type: FilterType.NUMERIC, key: 'grossProfit' },
	{ label: 'Pago', type: FilterType.NUMERIC, key: 'chargedAmount' },
	{ label: 'Saldo', type: FilterType.NUMERIC, key: 'pendingPayAmount' },
	{
		label: 'Destinos',
		type: FilterType.STRING,
		key: 'destinations',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{ label: 'Fecha de ida', type: FilterType.DATE, key: 'travelDepartureDate' },
	{ label: 'Fecha de vuelta', type: FilterType.DATE, key: 'travelReturnDate' },
	new SelectFilterModelSimple('Servicios contratados', 'servicesTypes', serviceTypes),
	new SelectFilterModelSimple('Segmento', 'segmentType', segmentTypes),
	new SelectFilterModelSimple('Canal', 'channelId', chanelsToFilter),
	{ label: 'Tiene saldo pendiente', type: FilterType.BOOLEAN, key: 'hasPendingPayAmount' },
	{
		label: 'Pasajero principal',
		type: FilterType.STRING,
		key: 'mainPassengerCompleteName',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{ label: 'Última edicón', type: FilterType.DATE, key: 'updated' },
	{
		label: 'Vendedor',
		type: FilterType.STRING,
		key: 'createdBy',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Cliente',
		type: FilterType.STRING,
		key: 'customerCompleteName',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		key: 'automaticSyncWithNetsuite',
		type: FilterType.BOOLEAN,
		label: 'Sincronizar automáticamente con Netsuite',
	},
];
